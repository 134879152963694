import {Skeleton} from "monday-ui-react-core";

export default function AppSkeleton({isBoardView = false} : {isBoardView: boolean}) {

	if (isBoardView) return (
		<div className="w-full h-full overflow-clip">
			<div className="flex flex-row">
				<Skeleton className="m-2 ml-2.5" height={1000} fullWidth type={Skeleton.types.RECTANGLE}/>
			</div>
		</div>
	);

	return (
		<div className="w-full h-full overflow-clip">
			<div className="flex flex-row">
				<Skeleton className="m-2 ml-2.5 min-w-[21rem] max-w-[22rem]" height={1000} type={Skeleton.types.RECTANGLE}/>
				<Skeleton className="m-2 ml-2.5" height={1000} fullWidth type={Skeleton.types.RECTANGLE}/>
			</div>
		</div>
	);
}

AppSkeleton.defaultProps = {
	isBoardView: false
}