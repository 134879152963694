import MessagesTopMenu from "./MessagesTopMenu.tsx";
import Messages from "./Messages.tsx";
import MessagesInputContainer from "./MessagesInputContainer.tsx";
import {ChannelMessageType, ChannelType, ChannelTypeEnum, UserType} from "../types/FirestoreCollections.type.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {useFirestore, useFirestoreCollectionData} from "reactfire";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext.ts";
import {sendMondayNotificationsToUsers} from "../monday/MondayNotifications.ts";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import ChannelTypingIndicator from "./ChannelTypingIndicator.tsx";
import {getIdsFromMessageMentions} from "../managers/mentionManager.ts";
import EmptyChannelPlaceholder from "./EmptyChannelPlaceholder.tsx";
import {mondayValueCreatedEvent} from "../monday/MondayApi.ts";

export default function MessagesContainer({currentUser, currentChannel,  setMessageForThread, messageQueryLimit, onScrollToTop} : {
    currentUser: UserType,
    currentChannel: ChannelType,
    setMessageForThread: (message: ChannelMessageType | null) => void,
    messageQueryLimit: number
    onScrollToTop: () => void
}) {
    const firestore = useFirestore();
    const authContext = useContext(AuthContext);
    const mondayUsersContext = useContext(MondayUsersContext);
    const messagesSnapshot = firestoreRepo(firestore, authContext).getMessages(useFirestoreCollectionData, currentUser.currentlyViewingChannel, messageQueryLimit);
    const usersToNotifySnapshot = firestoreRepo(firestore, authContext).getUsersToNotify(useFirestoreCollectionData, currentChannel.id);
    const usersToNotifyViewingBoardViewsSnapshot = firestoreRepo(firestore, authContext).getUsersToNotifyUsingBoardView(useFirestoreCollectionData, (currentChannel.type === ChannelTypeEnum.Board ? currentChannel.mondayRecordId : null), currentChannel.id);
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
    const [lastLoadedMessage, setLastLoadedMessage] = useState<ChannelMessageType | null>(null);
    const [messageToNotifyUsersWith, setMessageToNotifyUsersWith] = useState<string>('');
    useEffect(() => {
        if (messageToNotifyUsersWith && usersToNotifySnapshot.status === 'success' && usersToNotifyViewingBoardViewsSnapshot.status === 'success') {
            const combinedUsersToNotify = usersToNotifySnapshot.data?.concat(usersToNotifyViewingBoardViewsSnapshot.data);
            if (combinedUsersToNotify.length) {
                sendMondayNotificationsToUsers(firestore, authContext, mondayUsersContext, currentChannel, combinedUsersToNotify, messageToNotifyUsersWith).catch((e) => console.log(e));
            }
            setMessageToNotifyUsersWith('');
        }
    }, [firestore, authContext, mondayUsersContext, currentChannel, usersToNotifySnapshot, messageToNotifyUsersWith]);

    const onCreateMessage = (message: string) => {
        const runOnCreateMessage = async () => {
            if (!message) return;

            // Check to see if we're sending a message to a person channel and if the other user is a member of the channel
            // If they're not, add them to the channel.
            if (currentChannel.type === ChannelTypeEnum.Person) {
                if (currentChannel.memberIds.length === 1) {
                    const otherUserId = currentChannel.personChannelUserIds.find((id) => id !== authContext.firebaseUserId);
                    if (otherUserId) {
                        await firestoreRepo(firestore, authContext).addMemberToChannel(currentChannel.id, otherUserId).catch((e) => console.log(e));
                    }
                }
            } else {
                // If the message contains mentions of users not in the channel, add them to the channel
                // Wait for this to complete before notifying any users
                await createMentionedUsersNotInChannel(message).catch((e) => console.log(e));
            }

            // Get messages and sort them by the most recent (most recent on top). Then add them to an array where the value is the message ID.
            const lastMessageIds = messagesSnapshot.data?.sort((a, b) => (b?.createdAt?.toMillis() ?? b.localTimeStamp) - (a?.createdAt?.toMillis() ?? a.localTimeStamp)).map((message) => message.id);

            // Only use the last 20 messages
            lastMessageIds.splice(20);
            await firestoreRepo(firestore, authContext).createMessage(currentChannel.id, message, lastMessageIds).catch((e) => console.log(e));

            setMessageToNotifyUsersWith(message);

            mondayValueCreatedEvent();
        }
        runOnCreateMessage().catch((e) => console.log(e));
    }

    const createMentionedUsersNotInChannel = async (message: string) => {
        const mentionedUserIds = getIdsFromMessageMentions(message);
        if (!mentionedUserIds.length) return;

        const usersNotInChannel = mentionedUserIds
            .filter((userId) => !currentChannel.memberIds.includes(userId) && userId !== 'channel');
        if (usersNotInChannel.length) {
            for (const userId of usersNotInChannel) {
                await firestoreRepo(firestore, authContext).addMemberToChannel(currentChannel.id, userId).catch((e) => console.log(e));
            }
        }
    }

    const lastCurrentMessage = messagesSnapshot.data?.[0];

    const onScrollToBottom = () => {
        setShouldScrollToBottom(false);
    }

    // If the last message in the channel changes, scroll to the bottom
    useEffect(() => {
        if (lastLoadedMessage !== null && lastLoadedMessage?.createdAt?.toMillis() !== lastCurrentMessage?.createdAt?.toMillis()) {
            setShouldScrollToBottom(true);
        }
        setLastLoadedMessage(lastCurrentMessage ?? null);

        // Set the last seen message for the user. Only set it if the user hasn't seen the message yet.
        if (lastCurrentMessage && currentUser?.lastSeenMessageIds?.[currentChannel.id] !== lastCurrentMessage.id) {
            // Make sure the last seen message is in the current channel, since sometimes there can be lag and the channels are different
            if (lastCurrentMessage.channelId === currentChannel.id) {
                firestoreRepo(firestore, authContext).setLastSeenMessageId(currentUser, currentChannel.id, lastCurrentMessage.id).catch((e) => console.log(e));
            }
        }
    }, [lastCurrentMessage, messagesSnapshot, lastLoadedMessage]);

    const numberOfLoadedMessages = messagesSnapshot.data?.length ?? null;
    return (
        <div className="flex flex-col w-full bg-secondary-background-color h-full">
            {/*Messages Top Menu*/}
            <MessagesTopMenu currentChannel={currentChannel} currentUser={currentUser} />
            {/*Empty Channel Placeholder*/}
            {currentUser.currentOnboardingStep === null && (
                <EmptyChannelPlaceholder numberOfLoadedMessages={numberOfLoadedMessages} channel={currentChannel} />
            )}
            {/*Messages*/}
            <Messages setMessageForThread={setMessageForThread} messagesSnapshot={messagesSnapshot} shouldScrollToBottom={shouldScrollToBottom} onScrollToBottom={onScrollToBottom} onScrollToTop={onScrollToTop}/>
            {/*Typing Indicator*/}
            <ChannelTypingIndicator currentChannel={currentChannel} />
            {/*Message Input Container*/}
            <MessagesInputContainer currentUser={currentUser} currentChannel={currentChannel} onCreateMessage={onCreateMessage}/>
        </div>
    )
}

MessagesContainer.defaultProps = {
    shouldScrollToBottom: false
}