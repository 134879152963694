import {Icon} from "monday-ui-react-core";
import {AddSmall, Emoji, Update} from "monday-ui-react-core/icons";
import {EmojiClickData} from "emoji-picker-react";
import {useContext, useState} from "react";
import {useFirestore} from "reactfire";
import {AuthContext} from "../context/AuthContext.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {ChannelMessageType} from "../types/FirestoreCollections.type.ts";
import EmojiPicker from "./EmojiPicker.tsx";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import moment from "moment";
import {useFloating, autoUpdate, flip} from "@floating-ui/react";
import MessageThreadReactions from "./MessageThreadReactions.tsx";

export default function MessageActions({message, setMessageForThread, parentMessageId, isThreadContext} : {message:ChannelMessageType, setMessageForThread: ((message: ChannelMessageType | null) => void) | null, parentMessageId: string | null, isThreadContext: boolean}) {
    const firestore = useFirestore();
    const authContext = useContext(AuthContext);
    const isThreadMessage = !!parentMessageId;
    const mondayUsersContext = useContext(MondayUsersContext);
    const {refs, floatingStyles} = useFloating({
        placement: 'top',
        whileElementsMounted: autoUpdate,
        middleware: [flip()]
    });

    const [replyHover, setReplyHover] = useState(false);

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [showThreadEmojiPicker, setShowThreadEmojiPicker] = useState(false);

    const toggleEmoji = (emoji: string) => {
        // Add reaction to firestore
        if (isThreadMessage) {
            firestoreRepo(firestore, authContext).reactions().toggleChannelThreadMessageReaction(message.channelId, parentMessageId, message.id, authContext.firebaseUserId, emoji).catch((e) => console.log(e));
        } else {
            firestoreRepo(firestore, authContext).reactions().toggleChannelMessageReaction(message.channelId, message.id, authContext.firebaseUserId, emoji).catch((e) => console.log(e));
        }
        setShowEmojiPicker(false);
    }

    const onEmojiClick = (emoji: EmojiClickData) => {
        toggleEmoji(emoji.emoji);
    }

    // Get the last reply timestamp
    let lastReply: string | null = null;
    for (const userId of Object.keys(message.threadReplies ?? {})) {
        const timestamps = message.threadReplies[userId];
        for (const timestamp of timestamps) {
            if (lastReply === null) {
                lastReply = timestamp;
            }
            if (timestamp > lastReply) {
                lastReply = timestamp;
            }
        }
    }
    
    const totalReplies = Object.keys(message.threadReplies ?? {}).reduce((total, userId) => {
        return total + message.threadReplies[userId].length;
    }, 0);
    const isReactionsEmpty = message.reactions && Object.keys(message.reactions).length === 0;

    return (
        <div className="mr-1 flex flex-col">
            <div className="mr-1 flex flex-row max-h-6">
                {/*Existing reactions*/}
                {message.reactions && Object.keys(message.reactions).map((reaction) => (
                    <div
                        key={reaction}
                        onClick={() => toggleEmoji(reaction)}
                        data-testid={`reaction-${reaction}`}
                        className={`${message.reactions[reaction].includes(authContext.firebaseUserId) ? 'bg-primary-selected-color border border-primary-color' : 'bg-primary-background-hover-color'} text-primary-text-color flex items-center rounded-xl leading-3 p-0.5 pt-1 pl-1 pr-2 mr-1 hover:bg-primary-color hover:text-text-color-on-primary cursor-pointer`}>
                        <div className="text-s">{reaction}</div>
                        <div className="text-xs font-bold ml-1">{message.reactions[reaction].length ?? '1'}</div>
                    </div>
                ))}
                <div className="mr-1 flex flex-row group-hover:hidden">
                    {/*Add Reaction Placeholder*/}
                    {/*This doesn't actually do anything, since the actual action is done on hover. */}
                    {/*It's used to show the add reaction button when the message is not hovered.*/}
                    {(!message.threadReplies && !isReactionsEmpty) && (
                        <>
                            <div
                                className="flex items-center rounded-xl border border-ui-border-color leading-3 p-0.5 pl-1 pr-2 mr-1 hover:bg-primary-color hover:text-text-color-on-primary hover:border-opacity-0 cursor-pointer"
                            >
                                <Icon className="" icon={Emoji} iconSize={20}/>
                                <Icon className="-m-1" icon={AddSmall} iconSize={20}/>
                            </div>
                        </>
                    )}
                </div>
                <div className="mr-1 flex invisible flex-row group-hover:visible">
                    {/*Add Reaction*/}
                    {(!message.threadReplies || !isReactionsEmpty) && (
                        <>
                            <div
                                className="flex items-center rounded-xl border border-ui-border-color leading-3 p-0.5 pl-1 pr-2 mr-1 hover:bg-primary-color hover:text-text-color-on-primary hover:border-opacity-0 cursor-pointer"
                                onClick={() => {
                                    setShowEmojiPicker(!showEmojiPicker);
                                }}
                                ref={refs.setReference}
                                data-testid="add-reaction-button"
                            >
                                <Icon className="" icon={Emoji} iconSize={20}/>
                                <Icon className="-m-1" icon={AddSmall} iconSize={20}/>
                            </div>
                        </>
                    )}

                    {!isThreadContext && !message.threadReplies && (
                        <div onClick={() => setMessageForThread && setMessageForThread(message)}
                             className="flex items-center rounded-xl  border-ui-border-color leading-3 p-0.5 pl-1 pr-2 mr-1 hover:bg-primary-color hover:text-text-color-on-primary hover:border-opacity-0 cursor-pointer">
                            <Icon className="" icon={Update} iconSize={20}/>
                            <div className="text-xs font-bold ml-1">Reply</div>
                        </div>
                    )}
                </div>
            </div>
            <div ref={refs.setFloating} style={floatingStyles} className="z-20">
                <EmojiPicker onEmojiClick={onEmojiClick} showEmojiPicker={showEmojiPicker}
                             onClose={() => setShowEmojiPicker(false)}/>
            </div>
            <div className="mr-1 flex flex-row">
                {/*Add the number of thread replies, with a picture of each person who replied*/}
                {!isThreadContext && message.threadReplies && Object.keys(message.threadReplies).length > 0 && lastReply && (
                    <div
                        className={`text-secondary-text-color flex items-center cursor-pointer p-1 -ml-1 leading-3 pr-10 mr-1 mt-1 border border-secondary-background-color
                        hover:bg-secondary-background-color hover:text-text-color-on-secondary hover:border hover:rounded hover:border-ui-border-color
                        
                        ${replyHover ? '' : 'group-hover:border-primary-background-hover-color'}`}
                        onClick={() => setMessageForThread && setMessageForThread(message)}
                        onMouseEnter={() => setReplyHover(true)} onMouseLeave={() => setReplyHover(false)}
                    >
                        {Object.keys(message.threadReplies).map((userId) => {
                            if (!mondayUsersContext[userId]) return null;

                            return (
                                <img
                                    key={userId}
                                    className="w-6 h-6 rounded-full ml-1"
                                    src={mondayUsersContext[userId]?.photo_small}
                                    alt={mondayUsersContext[userId]?.name}
                                />
                            )
                        })}
                        <div className="text-xs font-bold ml-1">{totalReplies} {totalReplies && totalReplies > 1 ? 'replies' : 'reply'}</div>
                        <div className="text-xs ml-1">Last reply {moment(lastReply).fromNow()}</div>
                    </div>
                )}
            </div>
            <div className={'mr-1 flex flex-row'}>
                {!isThreadMessage && message.threadReplies && isReactionsEmpty && (
                    <MessageThreadReactions showEmojiPicker={showThreadEmojiPicker} setShowEmojiPicker={setShowThreadEmojiPicker} onEmojiClick={onEmojiClick}/>
                )}
            </div>
        </div>
    )
}

MessageActions.defaultProps = {
    setMessageForThread: null,
    parentMessageId: null,
    isThreadContext: false
}