import {Icon} from "monday-ui-react-core";
import {AddSmall, Emoji} from "monday-ui-react-core/icons";
import EmojiPicker from "./EmojiPicker.tsx";
import {autoUpdate, flip, useFloating} from "@floating-ui/react";
import {EmojiClickData} from "emoji-picker-react";

export default function MessageThreadReactions({ setShowEmojiPicker, showEmojiPicker, onEmojiClick }: { setShowEmojiPicker: (show: boolean) => void, showEmojiPicker: boolean, onEmojiClick: (emoji: EmojiClickData) => void }) {
	const {refs, floatingStyles} = useFloating({
		placement: 'top',
		whileElementsMounted: autoUpdate,
		middleware: [flip()]
	});

	return (
		<div className="mr-1 flex h-6 invisible flex-row group-hover:visible">
			{/*Add Reaction*/}
			<div
				className="flex items-center rounded-xl border border-ui-border-color leading-3 p-0.5 pl-1 pr-2 mr-1 hover:bg-primary-color hover:text-text-color-on-primary hover:border-opacity-0 cursor-pointer"
				onClick={() => {
					setShowEmojiPicker(!showEmojiPicker);
				}}
				ref={refs.setReference}
			>
				<Icon className="" icon={Emoji} iconSize={20}/>
				<Icon className="-m-1" icon={AddSmall} iconSize={20}/>
			</div>
			<div ref={refs.setFloating} style={floatingStyles} className="z-20">
				<EmojiPicker onEmojiClick={onEmojiClick} showEmojiPicker={showEmojiPicker} onClose={() => setShowEmojiPicker(false)}/>
			</div>
		</div>
	)
}