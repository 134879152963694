import {ChannelType} from "../types/FirestoreCollections.type.ts";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext.ts";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import {getUsersCurrentlyTyping} from "../firebase/realtimeDatabaseRepo.ts";

export default function ChannelTypingIndicator({currentChannel} : {
	currentChannel: ChannelType
}) {

	const authContext = useContext(AuthContext);
	const mondayUsersContext = useContext(MondayUsersContext);
	const [userIdsCurrentlyTyping, setUserIdsCurrentlyTyping] = useState<Record<string, boolean>>({});

	useEffect(() => {
		getUsersCurrentlyTyping(currentChannel.accountId, currentChannel.id, (snapshot) => {
			const data : Record<string, boolean> = snapshot.val() as Record<string, boolean> ?? {};
			setUserIdsCurrentlyTyping(data);
		});
	}, []);

	const typingIndicatorText = () => {
		// Filter out the current user
		const otherUserIdsTyping = Object.keys(userIdsCurrentlyTyping)?.filter((userId) => userId !== authContext.firebaseUserId);

		if (!otherUserIdsTyping || otherUserIdsTyping.length === 0) {
			return '';
		}
		if (otherUserIdsTyping.length === 1) {
			return `${mondayUsersContext[otherUserIdsTyping[0]]?.name} is typing...`;
		}
		if (otherUserIdsTyping.length === 2) {
			return `${mondayUsersContext[otherUserIdsTyping[0]]?.name} and ${mondayUsersContext[otherUserIdsTyping[1]]?.name} are typing...`;
		}
		return `${mondayUsersContext[otherUserIdsTyping[0]]?.name} and ${otherUserIdsTyping.length - 1} others are typing...`;
	}

	return (
		<div data-testid={'typing-indicator'} className="text-center text-secondary-text-on-secondary-color text-sm font-light min-h-5">{typingIndicatorText()}</div>
	);
}