import mondaySdk from "monday-sdk-js";
import {
	getMockBoards,
	getMockContext,
	getMockMeData,
	getMockMondayAccountSlug,
	getMockUsers
} from "../../tests/mondayApiMockData.ts";
import {MondayApiBoard} from "./MondayBoards.ts";
const monday = mondaySdk();
monday.setApiVersion('2024-01');
export const getContext = async () => {
	// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a test
	if (window.isRunningAutomationTest) {
		return getMockContext();
	}
	return await monday.get('context') as { data: { boardId: string } };
}

export const openMondayItemCard = (itemId: string) => {
	// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a test
	if (window.isRunningAutomationTest) {
		return;
	}
	monday.execute("openItemCard", {itemId: itemId}).catch((e) => console.log(e));
}
export const getSessionToken = async () => {
	// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a test
	if (window.isRunningAutomationTest) {
		// @ts-expect-error testUserId only exists on the window object if running from a test
		return { data: (window.testUserId ?? 'test') as string };
	}
	return await monday.get('sessionToken') as { data: string };
}

export const getMeData = async () => {
	// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a test
	if (window.isRunningAutomationTest) {
		return getMockMeData();
	}
	const meData : {data: { me: {name?: string, teams?: {id: string, name?: string, picture_url?: string, users?: {id: string, name?: string, photo_small?: string}[] }[] }}} = await monday.api(`query { me { name, teams {id name picture_url users {id name photo_small}} } }`);
	return meData;
}

export const getBoards = async (currentPage: number) => {
	// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a test
	if (window.isRunningAutomationTest) {
		return getMockBoards();
	}
	return await monday.api(`query { boards (limit: 100, page: ${currentPage}) { name, id, type, workspace { name }, subscribers { id } } }`);
}

export const getBoard = async (boardId: string) : Promise<MondayApiBoard | null> => {
	// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a test
	if (window.isRunningAutomationTest) {
		const boardsData = getMockBoards();
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
		return boardsData.data.boards.find((board: {id: string}) => board.id === boardId) as MondayApiBoard ?? null;
	}
	const boardResponse = await monday.api(`query { boards (ids: [${boardId}]) { name, id, type, workspace { name }, subscribers { id } } }`) as {data: {boards: {name: string, id: string, type: string, workspace: {name: string}, subscribers: {id: string}[]}[]} } | null;
	return boardResponse?.data?.boards?.[0] as MondayApiBoard ?? null;
}

export const createNotification = async (userId: string, boardId: string, text: string) => {
	// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a test
	if (window.isRunningAutomationTest) {
		// Add the notification to the window so we can verify it was sent
		// @ts-expect-error notifications only exists on the window object if running from a test
		if (typeof window.notifications === 'undefined') {
			// @ts-expect-error notifications only exists on the window object if running from a test
			window.notifications = [];
		}
		// @ts-expect-error notifications only exists on the window object if running from a test
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
		window.notifications.push({userId, boardId, text});

		console.log('Adding notification to window', {userId, boardId, text});
		return;
	}
	return await monday.api(`mutation {
		create_notification(user_id: ${userId}, target_id: ${boardId}, text: "${text}", target_type: Project) {
			id
		}
	}`);
}

export const getMondayAccountSlug = async () => {
	// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a test
	if (window.isRunningAutomationTest) {
		return getMockMondayAccountSlug();
	}
	return await monday.api(`query { me { account { slug } } }`) as {data: {me: {account: {slug: string}}}} | null;
}

export const getUsers = async (currentPage: number) => {
	// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a test
	if (window.isRunningAutomationTest) {
		return getMockUsers();
	}
	return await monday.api(`query { users (limit: 100, page: ${currentPage}) { name, id, photo_small, account {id} } }`);
}

export const mondayValueCreatedEvent = () => {
	monday.execute('valueCreatedForUser').catch((e) => console.log(e));
}