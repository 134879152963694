
export const getMockContext = async () => {
	return {
		"method": "get",
		"type": "context",
		"data": {
			"boardId": 5713225931,
			"boardIds": [
				5713225931
			],
			"workspaceId": 4149615,
			"appFeatureId": 10353971,
			"instanceId": 5713225931,
			"instanceType": "object_view",
			"isFullScreen": false,
			"isPresentingMode": false,
			"objectPermissions": "edit",
			"isFirstLevelControlPinned": true,
			"isSlidePanelOpen": false,
			"boardLoadingState": 10,
			"pulses": {
				"5713226075": {
					"id": 5713226075,
					"is_archived": false,
					"is_deleted": false,
					"last_updated_data": {
						"last_updated_at": 1703002871,
						"last_updated_by": 53398026
					},
					"board_id": 5713225931,
					"name": "Item 1",
					"group_id": "topics",
					"pos": 65536,
					"column_values": {
						"person": {
							"personsAndTeams": [
								{
									"id": 53398026,
									"kind": "person"
								}
							]
						},
						"status": {
							"index": 0,
							"post_id": null,
							"changed_at": "2019-03-01T17:24:57.321Z"
						},
						"date4": {
							"date": "2023-12-20",
							"icon": null,
							"changed_at": "2023-12-19T16:21:11.154Z"
						},
						"name": "Item 1"
					},
					"created_by": 53398026,
					"created_at": "2023-12-19T16:21:07Z",
					"is_permitted": null,
					"parent_item_link": null,
					"allSubitemsPassedFilter": true
				},
				"5713226095": {
					"id": 5713226095,
					"is_archived": false,
					"is_deleted": false,
					"last_updated_data": {
						"last_updated_at": 1703002869,
						"last_updated_by": 53398026
					},
					"board_id": 5713225931,
					"name": "Item 2",
					"group_id": "topics",
					"pos": 131072,
					"column_values": {
						"status": {
							"index": 1,
							"post_id": null,
							"changed_at": "2019-03-01T17:28:23.178Z"
						},
						"date4": {
							"date": "2023-12-17",
							"icon": null,
							"changed_at": "2023-12-19T16:21:09.415Z"
						},
						"name": "Item 2"
					},
					"created_by": 53398026,
					"created_at": "2023-12-19T16:21:07Z",
					"is_permitted": null,
					"parent_item_link": null,
					"allSubitemsPassedFilter": true
				},
				"5713226113": {
					"id": 5713226113,
					"is_archived": false,
					"is_deleted": false,
					"last_updated_data": {
						"last_updated_at": 1703002870,
						"last_updated_by": 53398026
					},
					"board_id": 5713225931,
					"name": "Item 3",
					"group_id": "topics",
					"pos": 196608,
					"column_values": {
						"status": {
							"index": 5,
							"post_id": null,
							"changed_at": "2019-03-01T17:25:02.248Z"
						},
						"date4": {
							"date": "2023-12-22",
							"icon": null,
							"changed_at": "2023-12-19T16:21:10.067Z"
						},
						"name": "Item 3"
					},
					"created_by": 53398026,
					"created_at": "2023-12-19T16:21:07Z",
					"is_permitted": null,
					"parent_item_link": null,
					"allSubitemsPassedFilter": true
				},
				"5713226146": {
					"id": 5713226146,
					"is_archived": false,
					"is_deleted": false,
					"last_updated_data": {
						"last_updated_at": 1703002868,
						"last_updated_by": 53398026
					},
					"board_id": 5713225931,
					"name": "Item 4",
					"group_id": "group_title",
					"pos": 131072,
					"column_values": {
						"date4": {
							"date": "2023-12-16",
							"icon": null,
							"changed_at": "2023-12-19T16:21:08.541Z"
						},
						"name": "Item 4"
					},
					"created_by": 53398026,
					"created_at": "2023-12-19T16:21:08Z",
					"is_permitted": null,
					"parent_item_link": null,
					"allSubitemsPassedFilter": true
				},
				"5713226132": {
					"id": 5713226132,
					"is_archived": false,
					"is_deleted": false,
					"last_updated_data": {
						"last_updated_at": 1703002868,
						"last_updated_by": 53398026
					},
					"board_id": 5713225931,
					"name": "Item 5",
					"group_id": "group_title",
					"pos": 196608,
					"column_values": {
						"date4": {
							"date": "2023-12-16",
							"icon": null,
							"changed_at": "2023-12-19T16:21:08.541Z"
						},
						"name": "Item 5"
					},
					"created_by": 53398026,
					"created_at": "2023-12-19T16:21:07Z",
					"is_permitted": null,
					"parent_item_link": null,
					"allSubitemsPassedFilter": true
				}
			},
			"theme": "dark",
			"account": {
				"id": "20364464"
			},
			"user": {
				"id": "53398026",
				"isAdmin": true,
				"isGuest": false,
				"isViewOnly": false,
				"countryCode": "US",
				"currentLanguage": "en",
				"timeFormat": "12H",
				"timeZoneOffset": -5
			},
			"region": "use1",
			"app": {
				"id": 10121938,
				"clientId": "a917aa81a029e803353071a7289c31c6"
			},
			"appVersion": {
				"id": 10211284,
				"name": "Bonfire Chat",
				"status": "live",
				"type": "minor",
				"versionData": {
					"major": 1,
					"minor": 4,
					"patch": 0,
					"type": "minor",
					"number": "v5"
				}
			},
			"appFeature": {
				"type": "AppFeatureObject",
				"name": "Bonfire Chat"
			},
			"permissions": {
				"approvedScopes": [
					"me:read",
					"boards:read",
					"workspaces:read",
					"users:read",
					"notifications:write",
					"teams:read",
					"assets:read"
				],
				"requiredScopes": [
					"me:read",
					"boards:read",
					"workspaces:read",
					"users:read",
					"notifications:write",
					"teams:read",
					"assets:read"
				]
			}
		},
		"requestId": "2vow9ta"
	};
}

export const getMockMeData = async () => {
	return {
		"data": {
			"me": {
				"name": "Oliver Albrecht",
				"teams": [
					{
						"id": "984778",
						"name": "Oliver's Team",
						"picture_url": "https://cdn.monday.com/images/dapulse_team_default.png",
						"users": [
							{
								"id": "53398026",
								"name": "Oliver Albrecht",
								"photo_small": "https://files.monday.com/use1/photos/53398026/small/53398026-user_photo_initials_2023_12_19_15_57_15.png?1703001435"
							},
							{
								"id": "53976918",
								"name": "seconduser@bonfireapps.com",
								"photo_small": "https://cdn1.monday.com/dapulse_default_photo.png"
							},
							{
								"id": "54857804",
								"name": "Second Oliver",
								"photo_small": "https://files.monday.com/use1/photos/54857804/small/54857804-user_photo_2024_01_23_16_04_16.png?1706025857"
							}
						]
					}
				]
			}
		},
		"account_id": 20364464
	};
}

export const getMockBoards = () => {
	return {
		"data": {
			"boards": [
				{
					"name": "New Board",
					"id": "6215834000",
					"type": "board",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						}
					]
				},
				{
					"name": "Subitems of All Column Types",
					"id": "6159145223",
					"type": "sub_items_board",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						}
					]
				},
				{
					"name": "All Column Types",
					"id": "6149348826",
					"type": "board",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						}
					]
				},
				{
					"name": "Zipper",
					"id": "6021418406",
					"type": "board",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "54857804"
						}
					]
				},
				{
					"name": "Other boardyy",
					"id": "6013616995",
					"type": "board",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						},
						{
							"id": "54857804"
						}
					]
				},
				{
					"name": "Dos",
					"id": "5908273481",
					"type": "board",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						},
						{
							"id": "53976918"
						},
						{
							"id": "54138225"
						},
						{
							"id": "54857804"
						}
					]
				},
				{
					"name": "Other workspace board",
					"id": "5876801457",
					"type": "board",
					"workspace": {
						"name": "Another Workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						}
					]
				},
				{
					"name": "New Board",
					"id": "5735486282",
					"type": "board",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						}
					]
				},
				{
					"name": "Bonfire Chat Prod",
					"id": "5716989231",
					"type": "custom_object",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						}
					]
				},
				{
					"name": "Bonfire Chat",
					"id": "5713225931",
					"type": "custom_object",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						},
						{
							"id": "54138225"
						}
					]
				},
				{
					"name": "Welcome to your developer account",
					"id": "5713015550",
					"type": "document",
					"workspace": {
						"name": "Main workspace"
					},
					"subscribers": [
						{
							"id": "53398026"
						}
					]
				}
			]
		},
		"account_id": 20364464
	};
}

export const getMockMondayAccountSlug = async () => {
	return {
		"data": {
			"me": {
				"account": {
					"slug": "bonfire-apps"
				}
			}
		},
		"account_id": 20364464
	};
}

export const getMockUsers = async () => {
	return {
		"data": {
			"users": [
				{
					"name": "Oliver Albrecht",
					"id": "53398026",
					"photo_small": "https://files.monday.com/use1/photos/53398026/small/53398026-user_photo_initials_2023_12_19_15_57_15.png?1703001435",
					"account": {
						"id": "20364464"
					}
				},
				{
					"name": "seconduser@bonfireapps.com",
					"id": "53976918",
					"photo_small": "https://cdn1.monday.com/dapulse_default_photo.png",
					"account": {
						"id": "20364464"
					}
				},
				{
					"name": "oalbrecht+seconduser@bonfireapps.com",
					"id": "54138225",
					"photo_small": "https://cdn1.monday.com/dapulse_default_photo.png",
					"account": {
						"id": "20364464"
					}
				},
				{
					"name": "Second Oliver",
					"id": "54857804",
					"photo_small": "https://files.monday.com/use1/photos/54857804/small/54857804-user_photo_2024_01_23_16_04_16.png?1706025857",
					"account": {
						"id": "20364464"
					}
				}
			]
		},
		"account_id": 20364464
	};
}