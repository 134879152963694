import {
    addDoc,
    collection,
    doc,
    DocumentData,
    DocumentReference,
    Firestore,
    getDoc,
    getDocs,
    limit,
    orderBy,
    Query as FirestoreQuery,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
    Query,
    increment,
    arrayUnion,
    arrayRemove
} from 'firebase/firestore';
import {AuthContextProps} from "../context/AuthContext.ts";
import {ObservableStatus, ReactFireOptions} from 'reactfire';
import {
    AccountType,
    ChannelMessageType, ChannelThreadMessageType,
    ChannelType,
    ChannelTypeEnum, NONE_LAST_MESSAGE_ID,
    NotificationPreferenceEnum,
    UserType
} from '../types/FirestoreCollections.type.ts';
import {generateUserId, parseFirestoreUserId} from './userIdGenerator.ts';
import {deleteField} from "firebase/firestore";
import {getMeData} from "../monday/MondayApi.ts";

interface FirestoreRepoType {
    getUserRecord: (firestoreUserId: string) => Promise<UserType | null>;
    createUserIfNotExists: (firebaseUserId: string) => Promise<UserType>;
    setHasCompletedInitialUserCreation: (hasCompletedInitialUserCreation: boolean) => Promise<void>;
    getUserById: (useFirestoreDocData: <T = unknown>(ref: DocumentReference<T>, options?: ReactFireOptions<T>) => ObservableStatus<T>, userId: string) => ObservableStatus<UserType>;
    getUser: (useFirestoreDocData: <T = unknown>(ref: DocumentReference<T>, options?: ReactFireOptions<T>) => ObservableStatus<T>) => ObservableStatus<UserType>;
    getMessage(useFirestoreDocData: <T = DocumentData>(ref: DocumentReference<T>, options?: ReactFireOptions<T>) => ObservableStatus<T>, channelId: string, messageId: string): ObservableStatus<ChannelMessageType>;
    getThreadMessages(useFirestoreCollectionData: <T = DocumentData>(query: Query<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>, channelId: string, parentMessageId: string): ObservableStatus<ChannelMessageType[]>;
    getChannelMembers: (channelId: string) => Promise<string[]>;
    getNotificationPreference: (channelId: string) => Promise<NotificationPreferenceEnum | undefined>;
    getUserChannelIds: (userId: string) => Promise<string[]>;
    addChannelToUser: (userId: string, channelId: string) => Promise<void>;
    getUsersToNotifyUsingBoardView: (useFirestoreCollectionData: <T = DocumentData>(query: Query<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>, boardId: string | null, channelId: string) => ObservableStatus<UserType[]>;
    getUsersToNotify: (useFirestoreCollectionData: <T = DocumentData>(query: Query<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>, channelId: string) => ObservableStatus<UserType[]>;
    getAllChannels: (useFirestoreCollectionData: <T = DocumentData>(query: Query<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>) => ObservableStatus<ChannelType[]>;
    getMessages(useFirestoreCollectionData: <T = DocumentData>(query: Query<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>, channelId: string, queryLimit: number): ObservableStatus<ChannelMessageType[]>;
    removeChannelFromUser: (userId: string, channelId: string) => Promise<void>;
    setChannelNotificationPreference: (channelId: string, notificationPreference: NotificationPreferenceEnum) => Promise<void>;
    setLastSeenMessageId: (currentUser: UserType, channelId: string, lastSeenMessageId: string) => Promise<void>;
    initializeNewUserIfNeeded: () => Promise<void>;
    createThreadMessage: (parentMessage: ChannelMessageType, messageText: string) => Promise<ChannelMessageType | null>;
    toggleThreadNotificationPreference: (parentMessage: ChannelMessageType, userId: string) => Promise<void>;
    removeMemberFromChannel: (channelId: string, userId: string) => Promise<void>;
    createChannel: (name: string, type?: ChannelTypeEnum, mondayRecordId?: (string | null)) => Promise<DocumentReference<DocumentData>>;
    getPersonChannel: (otherUserId: string) => Promise<ChannelType | null>;
    addSuggestedChannel: (type: ChannelTypeEnum, mondayRecordId: string, name: string) => Promise<void>;
    setCurrentOnboardingStep: (currentOnboardingStep: number | null) => Promise<void>;
    getCurrentOnboardingStep: () => Promise<number | null | undefined>;
    setCustomObjectBoardId: (customObjectBoardId: string | null) => Promise<void>;
    setUserDefaultNotificationPreference: (defaultNotificationPreference: NotificationPreferenceEnum) => Promise<void>;
    setChannelToGoToAfterAppOpen: (userId: string, channelId: string | null) => Promise<void>;
    updateOnlineStatus: (isOnline: boolean) => Promise<void>;
    updateCurrentlyViewingBoardViewBoardId: (currentlyViewingCustomObjectId: string | null) => Promise<void>;
    updatePressEnterToSendMessage: (pressEnterToSendMessage: boolean) => Promise<void>;
    incrementTotalMissedThreadMessages: (channelId: string, userIds: string[]) => Promise<void>;
    clearTotalMissedThreadMessages: (channelId: string, userId: string) => Promise<void>;
    setHasNotificationBeenSent: (user: UserType, channelId: string, hasNotificationBeenSent: boolean) => Promise<void>;
    setShouldShowNotificationPreferencesTooltip: (userId: string, shouldShowNotificationPreferencesTooltip: boolean) => Promise<void>;
    setDisableShowNotificationPreferencesTooltip: (userId: string, disableShowNotificationPreferencesTooltip: boolean) => Promise<void>;
    setHideSuggestedChannels: (hideSuggestedChannels: boolean) => Promise<void>;
    getBoardChannel: (boardId: string) => Promise<ChannelType | null>;
    createGeneralChannelIfNotExists: () => Promise<string|null>;
    getChannels: (useFirestoreCollectionData: <T = DocumentData>(query: Query<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>) => ObservableStatus<ChannelType[]>;
    setCurrentlyViewingChannel: (channelId: string, userId?: string) => Promise<void>;
    createMessage: (channelId: string, messageText: string, lastMessageIds: string[]) => Promise<void>;
    reactions: () => {
        toggleChannelMessageReaction: (channelId: string, messageId: string, userId: string, reaction: string) => Promise<void>;
        toggleChannelThreadMessageReaction: (channelId: string, parentMessageId: string, messageId: string, userId: string, reaction: string) => Promise<void>
    };
    addMemberToChannel: (channelId: string, userId: string) => Promise<void>;
    adminGetAccounts: () => Promise<AccountType[]>;
    adminGetChannels: (accountId: string) => Promise<ChannelType[]>;
    adminGetChannelMessages: (channelId: string) => Promise<ChannelMessageType[]>;
    adminGetChannelThreadMessages: (channelId: string, parentMessageId: string) => Promise<ChannelThreadMessageType[]>;
}

export default function firestoreRepo(firestore: Firestore, {accountId, firebaseUserId, userId} : AuthContextProps) : FirestoreRepoType {
    return {
        getUserRecord: async function(firestoreUserId: string) {
            const userSnapshot = await getDoc(doc(firestore, "users", firestoreUserId));
            if (!userSnapshot.exists()) {
                return null;
            }
            return userSnapshot.data() as UserType;
        },
        /**
         * Creates a new user in Firestore if the user does not already exist and returns the new user.
         * If the user already exists, this function will return the existing user.
         * @param firebaseUserId
         */
        createUserIfNotExists: async function(this: FirestoreRepoType, firebaseUserId: string) : Promise<UserType> {
            const {accountId, mondayUserId} = parseFirestoreUserId(firebaseUserId);
            try {
                // First, check to see if a user already exists in Firestore.
                const user = await this.getUserRecord(firebaseUserId);
                // If the user exists, return.
                if (user) {
                    return user;
                }
            } catch (e) {
                // If the user does not exist, we need to create a new user in Firestore.
            }

            // If the user does not exist, create a new user in Firestore.
            await setDoc(doc(collection(firestore, "users"), firebaseUserId), {
                accountId,
                firebaseUserId,
                userId: mondayUserId,
                currentlyViewingChannel: null,
                sentMissedMessageNotification: false,
                channelIds : [],
                notificationPreferences: {},
                channelsWhereNotificationWasSent: {},
                pressEnterToSendMessage: true,
                hasCompletedInitialUserCreation: false,
                isOnline: false,
                createdAt: serverTimestamp()
            });

            return (await this.getUserRecord(firebaseUserId))!;
        },

        setHasCompletedInitialUserCreation: async function(this: FirestoreRepoType, hasCompletedInitialUserCreation: boolean) {
            return updateDoc(doc(firestore, "users", firebaseUserId), {
                hasCompletedInitialUserCreation
            });
        },

        initializeNewUserIfNeeded: async function(this: FirestoreRepoType) {
            if (!firebaseUserId) {
                return;
            }

            // First, create the user if they don't exist. If they do exist, this will return the existing user.
            const user = await this.createUserIfNotExists(firebaseUserId);
            if (user.hasCompletedInitialUserCreation) {
                return;
            }

            // If we haven't completed the initial user creation, do so now.

            // Step 1: First, set the user's hasCompletedInitialUserCreation to true, so we don't create duplicate records.
            // Step 2: If a channel does not exist for the user's team, create one.
            // Step 3: Add all the team members to the new team channel if a new channel was created.
            // Step 4: Add the team channel (if it's new or already exists) to the user's channels.
            // Step 5: Add all the individual team members to the user's channels.
            // Step 6: Add the team channel as the currently viewing channel for the user.

            // First, set the user's hasCompletedInitialUserCreation to true, so we don't create duplicate records.
            await this.setHasCompletedInitialUserCreation(true);

            // Don't add any boards to the user's channels by default. Those can be added later using suggested channels.
            const meData : {data: { me: {name?: string, teams?: {id: string, name?: string, picture_url?: string, users?: {id: string, name?: string, photo_small?: string}[] }[] }}} = await getMeData();

            const generalChannelId = await this.createGeneralChannelIfNotExists();

            // Get all the channels the user is a part of.
            // These would be channels that other users added the current user to (e.g. person channels, team channels).
            const channelsSnapshot = await getDocs(query(
                collection(firestore, "channels"),
                where("personChannelUserIds", "array-contains-any", [firebaseUserId]),
                where("accountId", "==", accountId)
            ));

            // Add the id to the channel object.
            channelsSnapshot.docs.forEach(doc => {
                const channel = doc.data() as ChannelType;
                channel.id = doc.id;
            });

            const allChannels = channelsSnapshot.docs.map(doc => {
                const channel = doc.data() as ChannelType;
                channel.id = doc.id;
                return channel;
            });
            const personChannels = allChannels.filter(channel => channel.type === ChannelTypeEnum.Person);

            // For each existing person channel where the user isn't a member yet, add them as a member.
            personChannels.forEach(channel => {
                if (!channel?.memberIds?.includes(firebaseUserId) && channel.id) {
                    this.addMemberToChannel(channel.id, firebaseUserId).catch(e => console.error(e));
                }
            });

            // Keep track of which users were added to person channels, so we don't duplicate person channels in case they're a part of multiple teams.
            const usersAddedToPersonChannels : string[] = [];

            // For each team user is a part of...
            for (const team of meData?.data?.me?.teams ?? []) {
                /**
                 * Create person channels for each team member, if a person channel for them does not already exist.
                 */
                // Get the user ids for the team in monday.com
                const userIdsForTeam = team.users?.map(user =>  generateUserId(accountId, user.id.toString()));

                // Determine which users are not already in a person channel with the current user.
                const userIdsNotInChannel = userIdsForTeam?.filter(userId => !personChannels.some(channel => channel.personChannelUserIds.includes(userId)))
                    ?.filter(userId => !usersAddedToPersonChannels.includes(userId));

                // For each user that is not already in a channel with the current user...
                for (const userIdNotInChannel of userIdsNotInChannel ?? []) {
                    // Don't add a person channel for yourself.
                    if (userIdNotInChannel === firebaseUserId) {
                        continue;
                    }

                    // Create the user if they don't exist.
                    await this.createUserIfNotExists(userIdNotInChannel);

                    // Then create the person channel.
                    await this.createChannel('', ChannelTypeEnum.Person, parseFirestoreUserId(userIdNotInChannel).mondayUserId);

                    usersAddedToPersonChannels.push(userIdNotInChannel);
                }

                /**
                 * Create a team channel for the team, if one does not already exist.
                 */
                // Check to see if a channel exists for the team.
                const teamChannelSnap = await getDocs(query(
                    collection(firestore, "channels"),
                        where("mondayRecordId", "==", team.id),
                        where('type', '==', ChannelTypeEnum.Team),
                        where('accountId', '==', accountId),
                        limit(1)
                    )
                );

                // If a team channel already exists, add the user to it.
                if (teamChannelSnap.docs.length !== 0) {
                    const teamChannel = {...teamChannelSnap.docs[0].data(), id: teamChannelSnap.docs[0].id} as ChannelType;

                    // Check to see if the user is already a member of the team channel.
                    if (teamChannel.memberIds.includes(firebaseUserId)) {
                        // If the currently viewing channel is the general channel, set it to the team channel.
                        // Fetch the user again, because the user may have changed since the last time we fetched it.
                        const userSnapshot = await getDoc(doc(firestore, "users", firebaseUserId));
                        if (userSnapshot?.data()?.currentlyViewingChannel === generalChannelId) {
                            await updateDoc(doc(firestore, "users", firebaseUserId), {
                                currentlyViewingChannel: teamChannel.id
                            });
                        }
                        continue;
                    }

                    await updateDoc(doc(firestore, "channels", teamChannelSnap.docs[0].id), {
                        memberIds: arrayUnion(firebaseUserId)
                    });

                    // Set the currently viewing channel to the team channel.
                    await updateDoc(doc(firestore, "users", firebaseUserId), {
                        currentlyViewingChannel: teamChannel.id
                    });

                    await this.addChannelToUser(firebaseUserId, teamChannel.id);

                    continue;
                }

                // If a channel does not exist for the team, create one and add all the team members to it.
                const pictureUrl = team.picture_url;
                if (userIdsForTeam?.length) {
                    const newTeamChannel = await addDoc(collection(firestore, "channels"), {
                        name: team.name,
                        mondayRecordId: team.id,
                        accountId,
                        memberIds:userIdsForTeam, // Add the current user and all the team members to the channel.
                        personChannelUserIds: null,
                        createdBy: firebaseUserId,
                        createdByMondayUserId: userId,
                        userIdsCurrentlyTyping: {},
                        lastMessageIds: [],
                        pictureUrl,
                        type: ChannelTypeEnum.Team,
                        createdAt: serverTimestamp()
                    });

                    // Set the currently viewing channel to the team channel.
                    await updateDoc(doc(firestore, "users", firebaseUserId), {
                        currentlyViewingChannel: newTeamChannel.id
                    });

                    // Add the team channel to the user's channels for all users in the team.
                    userIdsForTeam.forEach(userId => {
                        this.addChannelToUser(userId, newTeamChannel.id).catch(e => console.error(e));

                        // Set the currently viewing channel to the team channel for these users.
                        // Note: this can get overridden if they receive a message after this for another channel.
                        this.setCurrentlyViewingChannel(newTeamChannel.id, userId).catch(e => console.error(e));
                    });
                }
            }
        },
        createGeneralChannelIfNotExists: async function(this: FirestoreRepoType) : Promise<string|null> {
            // Check to see if a general channel exists for the account. If it does, add the current user to the channel as a member.
            // If it doesn't, add the channel with the "General" name and add the current user as a member.
            const generalChannelSnap = await getDocs(query(collection(firestore, "channels"), where("accountId", "==", accountId), where('type', '==', ChannelTypeEnum.General), limit(1)));

            let generalChannelId : string | null = null;
            // If the general channel does not exist, create it
            if (generalChannelSnap.docs.length === 0) {
                const generalChannel = await addDoc(collection(firestore, "channels"), {
                    accountId,
                    name: "General",
                    type: ChannelTypeEnum.General,
                    lastMessageIds: [],
                    memberIds: [firebaseUserId],
                    personChannelUserIds: null,
                    createdBy: firebaseUserId,
                    userIdsCurrentlyTyping: {},
                    createdByMondayUserId: userId,
                    pictureUrl: null,
                    createdAt: serverTimestamp(),
                    mondayRecordId: null
                });

                generalChannelId = generalChannel.id;
            } else {
                const generalChannel = generalChannelSnap.docs[0]?.data() as ChannelType;
                const existingGeneralChannelId = generalChannelSnap.docs[0]?.id;
                if (existingGeneralChannelId) {
                    // If a general channel already exists and the user is a part of it, return.
                    if (generalChannel.memberIds.includes(firebaseUserId)) {
                        return existingGeneralChannelId;
                    }

                    await updateDoc(doc(firestore, "channels", existingGeneralChannelId), {
                        memberIds: arrayUnion(firebaseUserId)
                    });
                    generalChannelId = existingGeneralChannelId;
                }
            }

            // Set the currently viewing channel to the general channel.
            await updateDoc(doc(firestore, "users", firebaseUserId), {
                currentlyViewingChannel: generalChannelId
            });

            if (generalChannelId) {
                await this.addChannelToUser(firebaseUserId, generalChannelId);
            }

            return generalChannelId;
        },
        createChannel: async function(this: FirestoreRepoType, name: string, type: ChannelTypeEnum = ChannelTypeEnum.Custom, mondayRecordId: string | null = null) {
            // If it's a person type, we need to add the other person to personChannelUserIds.
            // We should not add that person to memberIds, since they are not a member of the channel yet since they haven't added the channel.
            const personChannelUserIds = type === ChannelTypeEnum.Person ? [firebaseUserId, generateUserId(accountId, mondayRecordId)] : null;

            // If it's a person channel, create the user if they don't exist.
            if (type === ChannelTypeEnum.Person) {
                await this.createUserIfNotExists(generateUserId(accountId, mondayRecordId));
            }

            const newChannel = await addDoc(collection(firestore, "channels"), {
                accountId,
                createdBy: firebaseUserId,
                createdByMondayUserId: userId,
                lastMessageIds: [],
                memberIds: [firebaseUserId],
                personChannelUserIds: personChannelUserIds,
                mondayRecordId: mondayRecordId,
                userIdsCurrentlyTyping: {},
                name,
                pictureUrl: null,
                type: type,
                createdAt: serverTimestamp()
            });

            await this.addChannelToUser(firebaseUserId, newChannel.id);

            return newChannel;
        },
        getPersonChannel: async function(this: FirestoreRepoType, otherUserId: string) {
            const personChannelsSnap = await getDocs(query(
                collection(firestore, "channels"),
                where("personChannelUserIds", "array-contains", otherUserId),
                where('type', '==', ChannelTypeEnum.Person),
                where('accountId', '==', accountId),
            ));

            if (personChannelsSnap.empty) {
                return null;
            }

            // Find the person channel where the current user is the other person.
            const personChannelSnapshot = personChannelsSnap.docs.find(doc => (doc.data() as ChannelType).personChannelUserIds?.includes(firebaseUserId));
            const personChannel = personChannelSnapshot?.data() as ChannelType;
            if (!personChannelSnapshot?.id) {
                return null;
            }
            personChannel.id = personChannelSnapshot?.id;

            return personChannel;
        },
        addSuggestedChannel: async function(this: FirestoreRepoType, type: ChannelTypeEnum, mondayRecordId: string, name: string) {
            let channel: ChannelType | null;
            if (type === ChannelTypeEnum.Person) {
                // Create the user if they don't exist.
                await this.createUserIfNotExists(mondayRecordId);

                // The mondayRecordId should contain the ID of the other person.
                channel = await this.getPersonChannel(mondayRecordId);
            } else {
                const suggestedChannelSnap = await getDocs(query(
                    collection(firestore, "channels"),
                    where("mondayRecordId", "==", mondayRecordId),
                    where('type', '==', type),
                    where('accountId', '==', accountId),
                    limit(1)
                ));
                channel = suggestedChannelSnap?.docs[0]?.data() as ChannelType;
                if (channel) {
                    channel.id = suggestedChannelSnap?.docs?.[0]?.id;
                }
            }

            let suggestedChannelId : string | null = null;
            // If the suggested channel does not exist, create it
            if (!channel) {
                // If it's a person type, we need to add the other person to personChannelUserIds.
                // We should not add that person to memberIds, since they are not a member of the channel yet since they haven't added the channel.
                const personChannelUserIds = type === ChannelTypeEnum.Person ? [firebaseUserId, mondayRecordId] : null;

                const suggestedChannel = await addDoc(collection(firestore, "channels"), {
                    accountId,
                    createdBy: firebaseUserId,
                    createdByMondayUserId: userId,
                    lastMessageIds: [],
                    memberIds: [firebaseUserId],
                    personChannelUserIds,
                    name,
                    pictureUrl: null,
                    mondayRecordId: type === ChannelTypeEnum.Person ? null : mondayRecordId,
                    userIdsCurrentlyTyping: {},
                    type,
                    createdAt: serverTimestamp()
                });

                suggestedChannelId = suggestedChannel.id;
            } else {
                // If a suggested channel already exists, add the user to it.
                const existingSuggestedChannelId = channel.id;
                if (existingSuggestedChannelId) {
                    await updateDoc(doc(firestore, "channels", existingSuggestedChannelId), {
                        memberIds: arrayUnion(firebaseUserId)
                    });
                    suggestedChannelId = existingSuggestedChannelId;
                }
            }

            if (!suggestedChannelId) {
                // This should never happen
                console.log('Error 1239414');
                return;
            }

            // Get the user's default notification preference.
            const userSnapshot = await getDoc(doc(firestore, "users", firebaseUserId));
            const user = userSnapshot.data() as UserType;
            const defaultNotificationPreference = user?.defaultNotificationPreference ?? NotificationPreferenceEnum.All;

            // Set the currently viewing channel to the suggested channel.
            await updateDoc(doc(firestore, "users", firebaseUserId), {
                currentlyViewingChannel: suggestedChannelId,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
                channelIds: arrayUnion(suggestedChannelId),
                notificationPreferences: {
                    ...user.notificationPreferences,
                    [suggestedChannelId]: defaultNotificationPreference
                }
            });
        },
        setCurrentOnboardingStep: async function(this: FirestoreRepoType, currentOnboardingStep: number | null) {
            return await updateDoc(doc(firestore, "users", firebaseUserId), {
                currentOnboardingStep
            });
        },
        getCurrentOnboardingStep: async function(this: FirestoreRepoType) {
            const userSnapshot = await getDoc(doc(firestore, "users", firebaseUserId));
            const user = userSnapshot.data() as UserType;
            return user?.currentOnboardingStep;
        },
        setCustomObjectBoardId: async function(this: FirestoreRepoType, customObjectBoardId: string | null) {
            return await updateDoc(doc(firestore, "users", firebaseUserId), {
                customObjectBoardId
            });
        },
        setUserDefaultNotificationPreference: async function(defaultNotificationPreference?: NotificationPreferenceEnum) {
            if (!defaultNotificationPreference) {
                defaultNotificationPreference = NotificationPreferenceEnum.All;
            }
            return await updateDoc(doc(firestore, "users", firebaseUserId), {
                defaultNotificationPreference
            });
        },
        setChannelToGoToAfterAppOpen: async function(this: FirestoreRepoType, userId: string, channelId: string | null) {
            return await updateDoc(doc(firestore, "users", userId), {
                channelToGoToAfterAppOpen: channelId
            });
        },
        getChannelMembers: async (channelId: string) : Promise<string[]> => {
            const channelSnapshot = await getDoc(doc(firestore, "channels", channelId));
            const channel = channelSnapshot.data() as ChannelType;
            return channel?.memberIds;
        },
        addMemberToChannel: async function(this: FirestoreRepoType, channelId: string, userId: string){
            const existingChannelMembers = await this.getChannelMembers(channelId);
            const isUserInExistingChannelMembers = existingChannelMembers.includes(userId);

            // If the user is already in the channel, return.
            if (isUserInExistingChannelMembers) {
                return;
            }

            await updateDoc(doc(firestore, "channels", channelId), {
                memberIds: arrayUnion(userId)
            });

            // Create the user if the user does not already exist.
            await this.createUserIfNotExists(userId);

            // Add the channel to the user's channels.
            await this.addChannelToUser(userId, channelId);

            // Set the last seen message id to the last message id in the channel for the user.
            const channelSnapshot = await getDoc(doc(firestore, "channels", channelId));
            const channel = channelSnapshot.data() as ChannelType;
            const lastMessageId = channel.lastMessageIds[0];
            const userRecord = await this.getUserRecord(userId)
            if (userRecord) {
                const lastSeenMessageId = lastMessageId ?? NONE_LAST_MESSAGE_ID;
                await this.setLastSeenMessageId(userRecord, channelId, lastSeenMessageId);
            }
        },
        removeMemberFromChannel: async function(this: FirestoreRepoType, channelId: string, userId: string){
            // Technically, we would only be resetting the currentlyViewingChannel if the channel being removed
            // is the currently active one. The issue is that with the way click handlers work (they call the parent),
            // and the limitations of monday's MenuItem component, we can't disable clicking the parent as well.
            // Ideally, we would do this to disable the parent click handler: https://stackoverflow.com/questions/40304213/react-nested-onclick-also-triggers-parent
            // But we can't do that with the MenuItem component since it doesn't have an onMouseDown input.
            // So we have to reset the currentlyViewingChannel every time a channel is removed to prevent putting the
            // currently viewing channel into a state where it's trying to show a channel that no longer exists for the user.
            await updateDoc(doc(firestore, "users", userId), {
                currentlyViewingChannel: null
            });

            await updateDoc(doc(firestore, "channels", channelId), {
                memberIds: arrayRemove(userId)
            });

            await this.removeChannelFromUser(userId, channelId);
        },
        getNotificationPreference: async function(channelId: string) {
            const userSnapshot = await getDoc(doc(firestore, "users", firebaseUserId));
            const user = userSnapshot.data() as UserType;
            return user?.notificationPreferences?.[channelId];
        },
        getUserChannelIds: async function(userId: string) : Promise<string[]> {
            const userSnapshot = await getDoc(doc(firestore, "users", userId));
            const user = userSnapshot.data() as UserType;
            return user?.channelIds;
        },
        addChannelToUser: async function(this: FirestoreRepoType, userId: string, channelId: string){
            // Get the user
            const userSnapshot = await getDoc(doc(firestore, "users", userId));

            // If the user does not exist, create the user.
            if (!userSnapshot.exists()) {
                await this.createUserIfNotExists(userId);
            }

            const user = userSnapshot.data() as UserType;
            return await updateDoc(doc(firestore, "users", userId), {
                channelIds: arrayUnion(channelId),
                notificationPreferences: {
                    ...user.notificationPreferences,
                    [channelId]: user?.defaultNotificationPreference ?? NotificationPreferenceEnum.All
                }
            });
        },
        removeChannelFromUser: async function(this: FirestoreRepoType, userId: string, channelId: string){
            return await updateDoc(doc(firestore, "users", userId), {
                channelIds: arrayRemove(channelId),
            });
        },
        setChannelNotificationPreference: async function(this: FirestoreRepoType, channelId: string, notificationPreference: NotificationPreferenceEnum){
            const userDoc = await getDoc(doc(firestore, "users", firebaseUserId));
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const notificationPreferences = userDoc.data()?.notificationPreferences ?? {} as Record<string, NotificationPreferenceEnum>;

            return await updateDoc(doc(firestore, "users", firebaseUserId), {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                notificationPreferences: {
                    ...notificationPreferences,
                    [channelId]: notificationPreference
                }
            });
        },
        setLastSeenMessageId: async function(this: FirestoreRepoType, currentUser: UserType, channelId: string, lastSeenMessageId: string){
            return await updateDoc(doc(firestore, "users", currentUser.firebaseUserId), {
                [`lastSeenMessageIds.${channelId}`]: lastSeenMessageId
            });
        },
        getUser: (useFirestoreDocData: <T = unknown>(ref: DocumentReference<T>, options?: ReactFireOptions<T>) => ObservableStatus<T>): ObservableStatus<UserType> => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useFirestoreDocData(doc(firestore, "users", firebaseUserId)) as ObservableStatus<UserType>;
        },
        getUserById: (useFirestoreDocData: <T = unknown>(ref: DocumentReference<T>, options?: ReactFireOptions<T>) => ObservableStatus<T>, userId: string): ObservableStatus<UserType> => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useFirestoreDocData(doc(firestore, "users", userId)) as ObservableStatus<UserType>;
        },
        // Gets a collection of users who are viewing other board views than the boardId.
        getUsersToNotifyUsingBoardView: function(
            useFirestoreCollectionData: <T = DocumentData>(query: FirestoreQuery<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>,
            boardId: string | null,
            channelId: string
        ): ObservableStatus<UserType[]> {
            const usersQuery = query(
                collection(firestore, "users"),
                where("currentlyViewingBoardViewBoardId", "!=", boardId),
                where("accountId", "==", accountId),
                where('isOnline', '==', true),
                where('channelIds', 'array-contains', channelId),
                orderBy('currentlyViewingBoardViewBoardId', 'asc'),
            );

            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useFirestoreCollectionData(usersQuery, {
                idField: 'id'
            }) as ObservableStatus<UserType[]>;
        },
        getUsersToNotify: function(
            useFirestoreCollectionData: <T = DocumentData>(query: FirestoreQuery<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>,
            channelId: string,
        ): ObservableStatus<UserType[]> {
            // Get the user records for users who have a channelId key in their channelIds object, and the value is "all" or "mentions".
            // These users should also have currentlyViewingBoardViewBoardId set to false. This is because
            // we don't want to notify users who are currently viewing a custom object.
            const usersQuery = query(
                collection(firestore, "users"),
                where("accountId", "==", accountId),
                where('isOnline', '==', false),
                where('channelIds', 'array-contains', channelId)
            );

            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useFirestoreCollectionData(usersQuery, {
                idField: 'id'
            }) as ObservableStatus<UserType[]>;
        },
        updateOnlineStatus: async (isOnline: boolean) => {
            return updateDoc(doc(firestore, "users", firebaseUserId), {
                isOnline
            });
        },
        updateCurrentlyViewingBoardViewBoardId: async (currentlyViewingBoardViewBoardId: string | null) => {
            return updateDoc(doc(firestore, "users", firebaseUserId), {
                currentlyViewingBoardViewBoardId: currentlyViewingBoardViewBoardId ?? deleteField()
            });
        },
        updatePressEnterToSendMessage: async (pressEnterToSendMessage: boolean) => {
            return updateDoc(doc(firestore, "users", firebaseUserId), {
                pressEnterToSendMessage
            });
        },
        incrementTotalMissedThreadMessages: async (channelId: string, userIds: string[]) => {
            for (const userId of userIds) {
                const userRef = doc(firestore, "users", userId);
                const userSnapshot = await getDoc(userRef);
                if (userSnapshot.exists()) {
                    const user = userSnapshot.data() as UserType;

                    // Don't increment the totalMissedThreadMessages if the user is currently viewing the channel.
                    if (user.currentlyViewingChannel === channelId) {
                        continue;
                    }
                }

                await updateDoc(userRef, {
                    [`totalMissedThreadMessages.${channelId}`]: increment(1)
                });
            }
        },
        clearTotalMissedThreadMessages: async (channelId: string, userId: string) => {
            const userRef = doc(firestore, "users", userId);
            await updateDoc(userRef, {
                [`totalMissedThreadMessages.${channelId}`]: 0
            });
        },
        setHasNotificationBeenSent: async (user: UserType, channelId: string, hasNotificationBeenSent: boolean) => {
            const hasNotificationBeenSentForChannel = user.channelsWhereNotificationWasSent ?? {};
            hasNotificationBeenSentForChannel[channelId] = hasNotificationBeenSent;

            return updateDoc(doc(firestore, "users", user.firebaseUserId), {
                channelsWhereNotificationWasSent: hasNotificationBeenSentForChannel
            });
        },
        setShouldShowNotificationPreferencesTooltip: async (userId: string, shouldShowNotificationPreferencesTooltip: boolean) => {
            return updateDoc(doc(firestore, "users", userId), {
                shouldShowNotificationPreferencesTooltip
            });
        },
        setDisableShowNotificationPreferencesTooltip: async (userId: string, disableShowNotificationPreferencesTooltip: boolean) => {
            return updateDoc(doc(firestore, "users", userId), {
                disableShowNotificationPreferencesTooltip
            });
        },
        setHideSuggestedChannels: async (hideSuggestedChannels: boolean) => {
            return updateDoc(doc(firestore, "users", firebaseUserId), {
                hideSuggestedChannels
            });
        },
        getBoardChannel: async function(this: FirestoreRepoType, boardId: string) {
            const boardChannelsSnap = await getDocs(query(
                collection(firestore, "channels"),
                where("mondayRecordId", "==", boardId),
                where('type', '==', ChannelTypeEnum.Board),
                where('accountId', '==', accountId),
            ));

            if (boardChannelsSnap.empty) {
                return null;
            }

            const boardChannel = boardChannelsSnap.docs[0].data() as ChannelType;
            boardChannel.id = boardChannelsSnap.docs[0].id;

            return boardChannel;
        },
        getChannels: (useFirestoreCollectionData: <T = DocumentData>(query: FirestoreQuery<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>): ObservableStatus<ChannelType[]> => {
            const channelsQuery = query(collection(firestore, 'channels'), where('accountId', '==', accountId), where('memberIds', 'array-contains', firebaseUserId));

            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useFirestoreCollectionData(channelsQuery, {
                idField: 'id'
            }) as ObservableStatus<ChannelType[]>;
        },
        getAllChannels: (useFirestoreCollectionData: <T = DocumentData>(query: FirestoreQuery<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>): ObservableStatus<ChannelType[]> => {
            const channelsQuery = query(collection(firestore, 'channels'), where('accountId', '==', accountId));

            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useFirestoreCollectionData(channelsQuery, {
                idField: 'id'
            }) as ObservableStatus<ChannelType[]>;
        },
        async setCurrentlyViewingChannel(channelId: string, userId: string = firebaseUserId) {
            return updateDoc(doc(firestore, "users", userId), {
                currentlyViewingChannel: channelId
            });
        },
        getMessages(useFirestoreCollectionData: <T = DocumentData>(query: FirestoreQuery<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>, channelId: string, queryLimit: number): ObservableStatus<ChannelMessageType[]> {
            const messagesQuery = query(
                collection(firestore, 'channels', channelId, 'channel_messages'),
                where('channelId', '==', channelId),
                where('accountId', '==', accountId),
                orderBy('createdAt', 'desc'),
                limit(queryLimit)
            );

            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useFirestoreCollectionData(messagesQuery, {
                idField: 'id'
            }) as ObservableStatus<ChannelMessageType[]>;
        },
        getMessage(useFirestoreDocData: <T = DocumentData>(ref: DocumentReference<T>, options?: ReactFireOptions<T>) => ObservableStatus<T>, channelId: string, messageId: string): ObservableStatus<ChannelMessageType> {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useFirestoreDocData(doc(firestore, 'channels', channelId, 'channel_messages', messageId), {
                idField: 'id'
            }) as ObservableStatus<ChannelMessageType>;
        },
        getThreadMessages(useFirestoreCollectionData: <T = DocumentData>(query: FirestoreQuery<T>, options?: ReactFireOptions<T[]>) => ObservableStatus<T[]>, channelId: string, parentMessageId: string): ObservableStatus<ChannelMessageType[]> {
            // Don't limit thread messages, since we want to query them all. Only channel messages are limited.
            const messagesQuery = query(
                collection(firestore, 'channels', channelId, 'channel_messages', parentMessageId, "channel_thread_messages"),
                where('accountId', '==', accountId),
                orderBy('createdAt', 'desc')
            );

            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useFirestoreCollectionData(messagesQuery, {
                idField: 'id'
            }) as ObservableStatus<ChannelMessageType[]>;
        },
        createMessage: async (channelId: string, messageText: string, lastMessageIds: string[]) => {
            const newMessage = await addDoc(collection(firestore, "channels", channelId, "channel_messages"), {
                accountId,
                channelId,
                messageText,
                reactions: {},
                createdAt: serverTimestamp(),
                createdBy: firebaseUserId,
                createdByMondayUserId: userId,
                localTimeStamp: new Date().getTime()
            });

            // Update the lastMessageIds in the channel
            await updateDoc(doc(firestore, "channels", channelId), {
                lastMessageIds: [newMessage.id, ...lastMessageIds]
            });
        },
        createThreadMessage: async (parentMessage: ChannelMessageType, messageText: string) => {
            // Create a new thread message
            await addDoc(collection(firestore, "channels", parentMessage.channelId, "channel_messages", parentMessage.id, "channel_thread_messages"), {
                accountId,
                channelId: parentMessage.channelId,
                messageText,
                reactions: {},
                createdAt: serverTimestamp(),
                createdBy: firebaseUserId,
                createdByMondayUserId: userId,
                parentMessageId: parentMessage.id,
                localTimeStamp: new Date().getTime()
            });

            // Check to see if the user already has a notification preference for the thread message
            const notificationPreferences = parentMessage.notificationPreferences ?? {};
            if (!notificationPreferences[firebaseUserId]) {
                notificationPreferences[firebaseUserId] = NotificationPreferenceEnum.All;
            }

            await updateDoc(doc(firestore, "channels", parentMessage.channelId, "channel_messages", parentMessage.id), {
                // Add the timestamp of the message to the parent message's threadReplies object
                // The threadReplies object looks like this:
                // { userID1: [message1Timestamp, message2Timestamp], userID2: [message1Timestamp, message2Timestamp] }
                [`threadReplies.${firebaseUserId}`]: arrayUnion(new Date().toISOString()),
                // Add the notification preference for the thread message
                [`notificationPreferences.${firebaseUserId}`]: notificationPreferences[firebaseUserId]
            });

            // Get the updated parent message
            const docSnapshot = await getDoc(doc(firestore, "channels", parentMessage.channelId, "channel_messages", parentMessage.id));

            if (docSnapshot.exists()) {
                return docSnapshot.data() as ChannelMessageType;
            }

            return null
        },
        toggleThreadNotificationPreference: async (parentMessage: ChannelMessageType, userId: string) => {
            let newNotificationPreference = NotificationPreferenceEnum.All;

            if (parentMessage.notificationPreferences?.[userId] === NotificationPreferenceEnum.All) {
                newNotificationPreference = NotificationPreferenceEnum.Mentions;
            }

            // Update the parent message with the new threadNotificationPreferences object
            return updateDoc(doc(firestore, "channels", parentMessage.channelId, "channel_messages", parentMessage.id), {
                [`notificationPreferences.${userId}`]: newNotificationPreference
            });

        },
        /**
         * Get account records that were created two weeks ago or later.
         */
        adminGetAccounts: async function() : Promise<AccountType[]> {
            const twoWeeksAgo = new Date();
            twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

            const accountsSnapshot = await getDocs(query(
                collection(firestore, "accounts"),
                where("createdAt", ">", twoWeeksAgo),
                orderBy("createdAt", "desc")
            ));

            const accounts = accountsSnapshot.docs.map(doc => {
                const account = doc.data() as AccountType;
                account.id = doc.id;
                return account;
            });

            return accounts;
        },
        /**
         * Get channel records that were created two weeks ago or later.
         * @param accountId
         */
        adminGetChannels: async function(accountId: string) : Promise<ChannelType[]> {
            const twoWeeksAgo = new Date();
            twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

            const channelsSnapshot = await getDocs(query(
                collection(firestore, "channels"),
                where("accountId", "==", accountId),
                where("createdAt", ">", twoWeeksAgo),
                orderBy("createdAt", "desc")
            ));

            return channelsSnapshot.docs.map(doc => {
                const channel = doc.data() as ChannelType;
                channel.id = doc.id;
                return channel;
            });
        },
        /**
         * Get messages that were created two weeks ago or later.
         * @param channelId
         */
        adminGetChannelMessages: async function(channelId: string) : Promise<ChannelMessageType[]> {
            const twoWeeksAgo = new Date();
            twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

            const messagesSnapshot = await getDocs(query(
                collection(firestore, "channels", channelId, "channel_messages"),
                where("createdAt", ">", twoWeeksAgo),
                orderBy("createdAt", "desc"),
                limit(300)
            ));

            return messagesSnapshot.docs.map(doc => {
                const message = doc.data() as ChannelMessageType;
                message.id = doc.id;
                return message;
            });

        },
        /**
         * Get thread messages that were created two weeks ago or later.
         * @param channelId
         * @param parentMessageId
         */
        adminGetChannelThreadMessages: async function(channelId: string, parentMessageId: string) : Promise<ChannelThreadMessageType[]> {
            const twoWeeksAgo = new Date();
            twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

            const messagesSnapshot = await getDocs(query(
                collection(firestore, "channels", channelId, "channel_messages", parentMessageId, "channel_thread_messages"),
                where("accountId", "==", accountId),
                where("createdAt", ">", twoWeeksAgo),
                orderBy("createdAt", "desc"),
                limit(300)
            ));

            return messagesSnapshot.docs.map(doc => {
                const message = doc.data() as ChannelThreadMessageType;
                message.id = doc.id;
                return message;
            });

        },
        reactions: () => {
            function getUpdatedReactions(userId: string, existingReactions:  Record<string, string[]>, newReaction: string) {
                // Example existingReactions object:
                // {
                //     👍: [
                //         "20364464&&&&&53398026",
                //         "20364464&&&&&53398652"
                //     ]
                // }

                const newReactions = {...existingReactions};

                // Get the array of user ids that have reacted with the reaction
                const reactionUserIds = newReactions[newReaction] ?? [];

                // If the user has already reacted with this reaction, remove the reaction
                if (reactionUserIds.includes(userId)) {
                    newReactions[newReaction] = reactionUserIds.filter(reactionUserId => reactionUserId !== userId);

                    // If the user has removed their last reaction, remove the reaction key from the reactions object
                    if (newReactions[newReaction].length === 0) {
                        delete newReactions[newReaction];
                    }
                } else {
                    // Otherwise, add the reaction
                    newReactions[newReaction] = [...reactionUserIds, userId];
                }

                return newReactions;
            }

            return {
                toggleChannelMessageReaction: async (channelId: string, messageId: string, userId: string, reaction: string) => {
                    // Get the channel message
                    const messageSnapshot = await getDoc(doc(firestore, "channels", channelId, "channel_messages", messageId));
                    const message = messageSnapshot.data() as ChannelMessageType;

                    // Get the reactions object from the channel message
                    const reactions = message.reactions ?? {};


                    // Update the channel message with the new reactions object
                    return updateDoc(doc(firestore, "channels", channelId, "channel_messages", messageId), {
                        reactions: getUpdatedReactions(userId, reactions, reaction)
                    });
                },
                toggleChannelThreadMessageReaction: async (channelId: string, parentMessageId: string, messageId: string, userId: string, reaction: string) => {
                    // Get the channel message
                    const messageSnapshot = await getDoc(doc(firestore, "channels", channelId, "channel_messages", parentMessageId, "channel_thread_messages", messageId));
                    const message = messageSnapshot.data() as ChannelMessageType;

                    // Get the reactions object from the channel message
                    const reactions = message.reactions ?? {};

                    // Update the channel message with the new reactions object
                    return updateDoc(doc(firestore, "channels", channelId, "channel_messages", parentMessageId, "channel_thread_messages", messageId), {
                        reactions: getUpdatedReactions(userId, reactions, reaction)
                    });

                }
            }
        },
    };
}