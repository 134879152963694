import MondayChannelAvatar from "./MondayChannelAvatar.tsx";
import {Dialog, DialogContentContainer, Icon, Menu, MenuItem} from "monday-ui-react-core";
import {NotificationsMuted, Notifications} from "monday-ui-react-core/icons";
import React, {useContext, useState} from "react";
import {AuthContext} from "../context/AuthContext.ts";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import {
    ChannelType,
    NONE_LAST_MESSAGE_ID,
    NotificationPreferenceEnum,
    UserType
} from "../types/FirestoreCollections.type.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {useFirestore} from "reactfire";
import ChannelNameSkeleton from "./skeletons/ChannelNameSkeleton.tsx";

export default function Channel({channel, currentUser, currentlyActiveChannel, notificationPreference, isPersonChannelActive, setCurrentlyViewingChannel}: {
    channel: ChannelType,
    currentUser: UserType,
    currentlyActiveChannel: string | null,
    notificationPreference: NotificationPreferenceEnum | null,
    isPersonChannelActive: boolean | null,
    setCurrentlyViewingChannel: (channelId: string) => void,
}) {
    const [showContextMenu, setShowContextMenu] = useState(false);
    const authContext = useContext(AuthContext);
    const mondayUsersContext = useContext(MondayUsersContext);
    const firestore = useFirestore();

    const removeUserFromChannel = () => {
        firestoreRepo(firestore, authContext).removeMemberFromChannel(channel.id, authContext.firebaseUserId).catch((e) => console.log(e));
    }

    const onRightClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setShowContextMenu(true);
    }

    const notificationPreferenceIcon = notificationPreference === NotificationPreferenceEnum.None ? NotificationsMuted : Notifications;

    let totalMissedMessages = currentUser?.totalMissedThreadMessages?.[channel.id] ?? 0;

    // Based on the user's lastSeenMessageId and the lastMessageIds in the channel, calculate the number of missed messages.
    // If the lastSeenMessageId is not in the lastMessageIds, then set the missedThreadMessageCount to the length of the lastMessageIds.
    const lastSeenMessageId = currentUser?.lastSeenMessageIds?.[channel.id];
    if (lastSeenMessageId) {
        if (lastSeenMessageId === NONE_LAST_MESSAGE_ID) {
            totalMissedMessages = channel.lastMessageIds.length;
        } else {
            const lastSeenMessageIndex = channel.lastMessageIds.indexOf(currentUser.lastSeenMessageIds[channel.id]);
            totalMissedMessages += lastSeenMessageIndex === -1 ? channel.lastMessageIds.length : lastSeenMessageIndex;
        }
    }

    // If the user is currently viewing the channel, reset the missed messages count to 0
    if (currentlyActiveChannel === channel.id) {
        totalMissedMessages = 0;
    }

    if (totalMissedMessages > 20) {
        totalMissedMessages = 20;
    }

    const showBadgeCount = totalMissedMessages !== undefined && totalMissedMessages !== 0;

    return (
        <div key={channel.id}
             data-testid="channel"
             onContextMenu={onRightClick}
             className={`flex flex-row p-1 content-center justify-normal cursor-pointer rounded-md hover:bg-primary-background-hover-color ${currentlyActiveChannel === channel.id && 'bg-primary-selected-color active'}  group`}
             onClick={() => setCurrentlyViewingChannel(channel.id)}>

            <Dialog
                position={Dialog.positions.BOTTOM}
                showTrigger={[]}
                hideTrigger={[Dialog.hideShowTriggers.CLICK_OUTSIDE]}
                open={showContextMenu}
                onClickOutside={() => setShowContextMenu(false)}
                onDialogDidHide={() => setShowContextMenu(false)}
                content={
                <DialogContentContainer>
                    <Menu>
                        <MenuItem data-testid={"channel-leave-button"} title="Leave channel" className='text-color-stuck-red' onClick={removeUserFromChannel} />
                        <MenuItem title="Close" className="text-secondary-text-color" onClick={() => setShowContextMenu(false)} />
                    </Menu>
                </DialogContentContainer>}>

                <MondayChannelAvatar channel={channel} showPersonChannelActiveStatus={true} authContext={authContext} isPersonChannelActive={isPersonChannelActive} mondayUsersContext={mondayUsersContext} className="flex-none w-7 h-7"/>
                <div className="self-center ml-2 text-primary-text-color truncate"><ChannelNameSkeleton channelName={channel.name}/></div>
                <div className="self-center">
                    <Icon className="invisible group-hover:visible ml-2 hover:visible text-secondary-text-color" iconSize={18} icon={notificationPreferenceIcon}></Icon>
                </div>
                <div className="self-center ml-auto mr-2">
                    {showBadgeCount && (
                        <div data-testid={'badge-count'} className="rounded-full leading-none px-2 text-xs bg-negative-color p-1 text-center text-text-color-on-primary">{totalMissedMessages}{totalMissedMessages >= 20 ? '+' : ''}</div>
                    )}
                </div>
            </Dialog>
        </div>
    )
}