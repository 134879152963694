import {Tipseen, TipseenWizard} from "monday-ui-react-core";
import React, {useContext} from "react";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {AuthContext} from "../context/AuthContext.ts";
import {useFirestore, useFirestoreDocData} from "reactfire";

export default function Onboarding({children, step, className = '', skipStep = false} : {children: React.ReactNode, step: number, className?: string, skipStep?: boolean}) {
	const myRef = React.useRef<HTMLDivElement>(null);
	const authContext = useContext(AuthContext);
	const firestore = useFirestore();
	const userSnapshot = firestoreRepo(firestore, authContext).getUser(useFirestoreDocData);
	if (userSnapshot.status === 'loading') {
		return children;
	}

	const setCurrentOnboardingStep = (e: React.MouseEvent<Element, MouseEvent> | null, step: number) => {
		e?.preventDefault();
		firestoreRepo(firestore, authContext).setCurrentOnboardingStep(step).catch((e) => console.log(e));
	}

	const completedOnboarding = () => {
		// A null value means the user has completed the onboarding
		firestoreRepo(firestore, authContext).setCurrentOnboardingStep(null).catch((e) => console.log(e));
	}

	// A null value means the user has completed the onboarding
	if (userSnapshot?.data?.currentOnboardingStep === null) {
		return children;
	}

	const currentOnboardingStep = userSnapshot?.data?.currentOnboardingStep ?? 0;

	if (step !== currentOnboardingStep) {
		return children;
	}

	if (skipStep) {
		setCurrentOnboardingStep(null, currentOnboardingStep);
		return children;
	}

	const onboardingSteps = [
		{
			title: "Welcome!",
			content:
				<div>
					<div className={"mb-4 border-b border-dashed pb-3"}>
						Take a quick tour of the app to get started.
					</div>
					<div className="mt-2">
						<div className="font-bold text-[16px]">Channels</div>
						<div>Channels are a great way to organize your conversations. You can create channels for
							different
							teams, projects, or topics.
						</div>
					</div>
					<div className="mt-2">
						<div className="font-bold text-[16px]">Direct Messages</div>
						<div>Direct Messages are conversations between you and another person.</div>
					</div>
				</div>,
			position: Tipseen.positions.RIGHT,
		},
		{
			title: "Search",
			content:
				<div>
					<div>Search for channels, boards, teams, and people to add as a channel.</div>
					<div>Create your own using the <strong className="text-lg">+</strong> button.</div>
				</div>,
			position: Tipseen.positions.LEFT,
		},
		{
			title: "Notifications",
			content: <div>Customize your notification settings for each channel and direct message.</div>,
			position: Tipseen.positions.BOTTOM,
		},
		{
			title: "Invite Co-Workers to a Channel",
			content: (<div>You can add members to a channel by clicking on the &quot;Add Members&quot; button.</div>),
			position: Tipseen.positions.TOP,
		}
	];

	const content = onboardingSteps.map((step) => step.content);

	// Handle if the currentOnboardingStep is out of bounds, which may happen if we remove an onboarding step in the future
	if (currentOnboardingStep >= onboardingSteps.length) {
		return children;
	}

	return (
		<div className={`z-50 ${className}`}>
			<Tipseen
				className={"z-50"}
				ref={myRef}
				position={onboardingSteps[currentOnboardingStep].position}
				onClose={completedOnboarding}
				content={
					<div>
						<TipseenWizard
							className={"z-50"}
							title={onboardingSteps[currentOnboardingStep].title}
							steps={content}
							activeStepIndex={currentOnboardingStep}
							onChangeActiveStep={setCurrentOnboardingStep}
							onFinish={completedOnboarding}
						/>
					</div>
				}>
				<div>
					{children}
				</div>
			</Tipseen>
		</div>
	);
}