import {Button} from "monday-ui-react-core";
import {openMondayItemCard} from "../monday/MondayApi.ts";


export default function AutomationMondayItemCardOpen({itemId} : {itemId: string}) {
	const openItemCard = () => {
		openMondayItemCard(itemId);
	}
	return (
		<div className="ml-1 mb-1">
			<Button size={Button.sizes.XS} onClick={openItemCard} kind={Button.kinds.SECONDARY}>View Item</Button>
		</div>
	);
}