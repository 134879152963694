import {Timestamp} from 'firebase/firestore';

export const NONE_LAST_MESSAGE_ID = 'NONE';

export enum ChannelTypeEnum {
    Person = 'person',
    Team = 'team',
    Board = 'board',
    Custom = 'custom',
    General = 'general'
}

export enum NotificationPreferenceEnum {
    All = 'all',
    Single = 'single',
    Mentions = 'mentions',
    None = 'none'
}

export enum BroadcastMentionEnum {
    Channel = 'channel'
}

export interface UserChannelInformationEnum {
    notificationPreference: NotificationPreferenceEnum;
}

export interface UserType {
    id: string;
    accountId: string;
    currentlyViewingChannel: string;
    firebaseUserId: string;
    sentMissedMessageNotification: boolean;
    // The channels a person is a member of along with the notification preference.
    // The key is the channel id and the value is the notification preference.
    channelIds: string[];
    notificationPreferences: Record<string, NotificationPreferenceEnum>;
    lastSeenMessageIds: Record<string, string> // {channelId1: lastSeenMessageId1, channelId2: lastSeenMessageId2}
    channelsWhereNotificationWasSent: Record<string, boolean>; // { channelId: boolean }
    isOnline: boolean;
    currentlyViewingBoardViewBoardId?: string; // Should not exist on the object if the user is not currently viewing a board view. If viewing a board view, this will be the boardId.
    userId: string;
    shouldShowNotificationPreferencesTooltip: boolean;
    disableShowNotificationPreferencesTooltip: boolean;
    defaultNotificationPreference?: NotificationPreferenceEnum;
    pressEnterToSendMessage: boolean;
    totalMissedThreadMessages: Record<string, number>; // { channelId: number }
    channelToGoToAfterAppOpen: string | null; // The channelId to go to after the app is opened.
    hideSuggestedChannels: boolean;
    currentOnboardingStep?: number | null; // Don't add this to new users. This field will only be added once the user has completed or is in the process of initial onboarding.
    hasCompletedInitialUserCreation: boolean;
    customObjectBoardId?: string; // The boardId of the custom object board. (Used for knowing where automated messages should be sent.)
    createdAt: Timestamp;
}

export interface ChannelType {
    id: string;
    accountId: string;
    createdBy: string;
    createdByMondayUserId: string;
    lastMessageIds: string[]; // The last 20 message ids in the channel. The most recent message is the first element in the array.
    memberIds: string[]; // The user ids of the people who have added the channel to their channel list.
    personChannelUserIds: string[]; // The userIds of the two people who are represented by a person channel. Even if only one person is a member of the channel, both userIds will be in this array.
    name: string;
    pictureUrl: string;
    mondayRecordId: string; // E.g. boardId, teamId, personId from monday.com
    userIdsCurrentlyTyping: Record<string, boolean>; // { userId, boolean }, If the user is typing, the value will be true. If the user is not typing, the user ID will be removed from the object.
    type: ChannelTypeEnum;
    createdAt: Timestamp;
}

export interface ChannelMessageGeneralType {
    id: string;
    accountId: string;
    channelId: string;
    messageText: string;
    reactions: Record<string, string[]>;
    createdAt: Timestamp;
    createdBy: string;
    createdByMondayUserId: string;
    // Doesn't come from the database. Just used for rendering purposes (date divider in messages).
    dateDivider: boolean;
    // Used to determine message ordering if the message has not yet been written to firestore.
    // Use createdAt for the actual timestamp of the message.
    localTimeStamp: number;
}

export interface ChannelMessageType extends ChannelMessageGeneralType {
    // { userID1: [message1Timestamp, message2Timestamp], userID2: [message1Timestamp, message2Timestamp] }
    threadReplies: Record<string, string[]>;
    notificationPreferences: Record<string, NotificationPreferenceEnum>
    isAutomationMessage: boolean;
    automationMessageBoardName: string;
    automationMessageBoardId: string;
    automationMessageItemId: string;
}

export interface ChannelThreadMessageType extends ChannelMessageGeneralType {
    parentMessageId: string;
}

export interface AppWebhookType {
    app_id: string;
    user_id: string;
    user_name: string;
    user_cluster: string;
    account_tier: string;
    account_name: string;
    account_slug: string;
    account_max_users: string;
    account_id: string;
    version_data: string;
    timestamp: string;
    subscription: string
    user_country: string;
    createdAt: Timestamp;
}

export interface AccountType {
    id?: string; // This is the document id in Firestore and isn't always set
    accountId: string;
    slug: string;
    name: string;
    isDeleting: boolean;
    lastAppEventCreatedAt: Timestamp;
    lastAppEventType: string;
    createdAt: Timestamp;
    updatedAt: Timestamp;
}
