import {getFirestore, connectFirestoreEmulator, initializeFirestore} from 'firebase/firestore'
import {getAuth, connectAuthEmulator, Auth} from "firebase/auth";
import {getFunctions, connectFunctionsEmulator} from "firebase/functions";
import {setConsent} from "firebase/analytics";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import type { FirebaseApp } from 'firebase/app';
import type { Firestore } from 'firebase/firestore';

function initializeServices(firebaseApp: FirebaseApp) {
	// const isConfigured = getApps().length > 0;
	let firestore : Firestore;
	if (import.meta.env.MODE === 'test') {
		firestore = initializeFirestore(firebaseApp, { experimentalForceLongPolling: true });
	} else {
		firestore = getFirestore(firebaseApp);
	}

	// @ts-expect-error ignore
	const isConfigured: boolean = firestore._settingsFrozen as boolean;
	const auth = getAuth(firebaseApp);
	const database = getDatabase(firebaseApp);
	setConsent({
		analytics_storage: "denied",
		ad_storage: "denied",
		ad_personalization: "denied",
		ad_user_data: "denied",
	})
	return {firebaseApp, firestore, auth, database, isConfigured};
}

declare global {
	interface Window {
		emulatorsEvaluated: boolean;
		emulatorsEvaluatedListeners: (() => void)[];
		onEmulatorsEvaluated: (listener: () => void) => void;
	}
}

function connectToEmulators({firebaseApp, auth, firestore, database}) {
	if (["plugingenie.ngrok.io", "localhost"].includes(location.hostname)) {
		console.log('%%%%%%%%%%%%%%%%%%% CONNECTING TO EMULATOR %%%%%%%%%%%%%%%%%%%');

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		connectFirestoreEmulator(firestore, 'localhost', 8080);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		connectDatabaseEmulator(database, 'localhost', 9000);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		const functions = getFunctions(firebaseApp, "us-central1");
		connectFunctionsEmulator(functions, 'localhost', 5001);

		// // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		// connectAuthEmulator(auth, 'http://127.0.0.1:9099');

		// Create listener logic
		// The emulator auth logic is based on: https://stackoverflow.com/questions/73605307/firebase-auth-emulator-fails-intermittently-with-auth-emulator-config-failed
		window.emulatorsEvaluated = false;
		window.emulatorsEvaluatedListeners = [];
		window.onEmulatorsEvaluated = (listener: () => void) => {
			if (window.emulatorsEvaluated) {
				listener();
			} else {
				window.emulatorsEvaluatedListeners.push(listener);
			}
		};

		(async (auth: Auth) => {
			try {
				// Use 127.0.0.1 instead of localhost
				const authEmulatorUrl = "http://127.0.0.1:9099";
				await fetch(authEmulatorUrl);
				connectAuthEmulator(auth, authEmulatorUrl, {
					disableWarnings: true,
				});
				console.info("🎮 Firebase Auth: emulated");
			} catch (e) {
				console.info("🔥 Firebase Auth: not emulated");
			}

			// Indicate that the emulators have been evaluated
			window.emulatorsEvaluated = true;
			window.emulatorsEvaluatedListeners.forEach(
				(listener: () => void) => {
					listener();
				}
			);
		})(auth as Auth).catch((e) => console.log(e));
	}
}

export function getFirebase(firebaseApp: FirebaseApp) {
	const services = initializeServices(firebaseApp);
	if (!services.isConfigured) {
		connectToEmulators(services);
	}
	return services;
}