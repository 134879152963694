import {ChannelMessageType} from "../types/FirestoreCollections.type.ts";
import {ObservableStatus} from "reactfire";
import React, {useEffect} from "react";
import Message from "./Message.tsx";
import MessageText from "./MessageText.tsx";
import MessageDateDivider from "./MessageDateDivider.tsx";
import MessagesSkeleton from "./skeletons/MessagesSkeleton.tsx";

export default function Messages({
    children,
    setMessageForThread,
    messagesSnapshot,
    parentMessage,
    isThreadContext,
    shouldScrollToBottom,
    onScrollToBottom,
    onScrollToTop,

} : {
    children?: React.ReactNode
    setMessageForThread: null | ((message: ChannelMessageType | null) => void)
    messagesSnapshot: ObservableStatus<ChannelMessageType[]>
    parentMessage?: ChannelMessageType
    isThreadContext: boolean
    shouldScrollToBottom?: boolean
    onScrollToBottom: () => void
    onScrollToTop: () => void
}) {
    const messagesStartRef = React.useRef<HTMLDivElement>(null);
    const messagesEndRef = React.useRef<HTMLDivElement>(null);
    const isThreadMessage = !!parentMessage;
    const TOTAL_PAGES = 100;
    const [pageNum, setPageNum] = React.useState(0);
    const observerTarget = React.useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0] && entries[0].isIntersecting) {
                    setPageNum((num) => num + 1)
                }
            }, {
                threshold: 0.1,
            })

        if (observerTarget.current) {
            observer.observe(observerTarget.current)
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current)
            }
        }
    }, [observerTarget.current]);

    useEffect(() => {
        if (pageNum <= TOTAL_PAGES && pageNum > 0) {
            setTimeout(() => {
                onScrollToTop ? onScrollToTop() : null;
            }, 1);
        }
    }, [pageNum]);

    useEffect(() => {
        if (shouldScrollToBottom && messagesSnapshot.status === 'success') {
            if (isThreadContext) {
                messagesEndRef.current?.scrollIntoView();
            } else {
                messagesStartRef.current?.scrollIntoView();
            }
            onScrollToBottom();
        }
    }, [shouldScrollToBottom, messagesSnapshot, messagesEndRef, isThreadContext]);

    if (messagesSnapshot.status === 'loading') {
        return <MessagesSkeleton/>;
    }

    // The newest message are at the top
    const messages : ChannelMessageType[] = messagesSnapshot.data ?? [];

    interface DateDivider {
        DATE_DIVIDER: Date,
        id: string
    }

    type GroupedMessageType = ChannelMessageType[] | DateDivider;

    // Group messages together if they are from the same user and within 5 minutes of each other
    let groupedMessages: GroupedMessageType[] = [];
    let lastMessage: ChannelMessageType | null = null;
    let lastMessageCreatedAt: Date | null = null;
    for (const message of messages) {
        // If the message has a createdAt date (it has persisted to the server), use that.
        // Otherwise, use the localTimeStamp, which is the time the message was created locally.
        // The localTimeStamp is used to make adding messages faster instead of having to wait for the message
        // to persist to the server.
        const messageCreatedAt = message.createdAt?.toDate() ?? new Date(message.localTimeStamp);

        if (lastMessage === null) {
            lastMessage = message;
            lastMessageCreatedAt = messageCreatedAt;
            groupedMessages.push([message]);
            continue;
        }

        // This should never happen. It's to make typescript happy.
        if (lastMessageCreatedAt === null) {
            console.log('Error 2481751');
            continue;
        }

        const isNewDay = lastMessageCreatedAt.getDay() - messageCreatedAt.getDay() !== 0;

        // Thread context does not need date dividers
        if (isNewDay && !isThreadContext) {
            groupedMessages.push({DATE_DIVIDER: lastMessageCreatedAt, id: lastMessage.createdAt?.toMillis().toString() ?? lastMessage.localTimeStamp.toString() ?? 'date-divider'});
        }

        // Keep automation messages separate from other messages
        // @ts-expect-error It is correct that you can subtract firestore Timestamps
        if (!message.isAutomationMessage && !lastMessage.isAutomationMessage && message.createdBy === lastMessage.createdBy &&  ((lastMessageCreatedAt - messageCreatedAt) < 300000)) {
            // If it's a date divider, it won't be an array. Otherwise, it is an array.
            const lastGroupedMessage = groupedMessages[groupedMessages.length - 1];
            if (Array.isArray(lastGroupedMessage)) {
                lastGroupedMessage.unshift(message);
            }
        } else {
            groupedMessages.push([message]);
        }
        lastMessage = message;
        lastMessageCreatedAt = messageCreatedAt;
    }
    if (isThreadContext) {
        groupedMessages = groupedMessages.reverse();
    }

    // Add a MessageDateDivider to groupedMessages whenever there's a new day
    return (
        <div
            style={{
                display: "flex",
                height: "100%",
                overflow: "auto",
                overflowAnchor: "none",
                position: "sticky",
            }}
            className={`${isThreadContext ? 'flex-col' : 'flex-col-reverse'}`}
        >
            {isThreadMessage && (
                <div className={`w-full p-2 bg-primary-background-color`}>
                    {/*Spacer at top of threads*/}
                </div>
            )}
            {children}
            <div ref={messagesStartRef}/>
            {groupedMessages.map((messageGroup) => (
                <div key={Array.isArray(messageGroup) ? messageGroup?.[0]?.id : messageGroup.id}>
                    {/*If it's a date divider*/}
                    {!Array.isArray(messageGroup) && messageGroup.DATE_DIVIDER && (
                        <MessageDateDivider date={messageGroup.DATE_DIVIDER}/>
                    )}
                    {/*If it's not a date divider*/}
                    {Array.isArray(messageGroup) && (
                        <>
                            <Message firstMessageInGroup={messageGroup[0]} isThreadContext={isThreadContext}>
                                {messageGroup.map((message) => {
                                    return (
                                        <MessageText
                                            key={message.id} message={message}
                                            setMessageForThread={setMessageForThread}
                                            parentMessageId={parentMessage?.id}
                                            isThreadContext={isThreadContext}
                                        />
                                    );
                                })}
                            </Message>
                        </>
                    )}
                </div>
            ))}
            {!isThreadMessage && (
                <div className={`w-full p-2`}>
                    {/*Spacer at top of messages*/}
                </div>
            )}

            <div ref={messagesEndRef}/>
            <div ref={observerTarget}></div>
        </div>
    )
}

Messages.defaultProps = {
    setMessageForThread: null,
    isThreadContext: false,
    onScrollToTop: undefined,
}