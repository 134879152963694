import {Avatar, Icon} from "monday-ui-react-core";
import {Board, DropdownChevronDown, DropdownChevronUp} from "monday-ui-react-core/icons";
import {getMondayBoards, MondayApiBoard} from "../monday/MondayBoards.ts";
import {AuthContext} from "../context/AuthContext.ts";
import {useContext, useEffect, useState} from "react";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import {generateUserId} from "../firebase/userIdGenerator.ts";
import {ChannelType, ChannelTypeEnum, UserType} from "../types/FirestoreCollections.type.ts";
import {getAvatarBackgroundColor} from "../monday/MondayAvatar.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {useFirestore} from "reactfire";
import AvatarSkeleton from "./skeletons/AvatarSkeleton.tsx";
import ChannelNameSkeleton from "./skeletons/ChannelNameSkeleton.tsx";

interface SuggestedChannelType {
    id: string,
    name: string,
    type: ChannelTypeEnum,
    avatarType: typeof Avatar.types.ICON | typeof Avatar.types.IMG | typeof Avatar.types.TEXT,
    avatarText: string,
    avatarIcon: typeof Board | null,
    avatarPictureUrl: string | undefined
}

export default function SuggestedChannels({currentChannels, currentUser} : {currentChannels: ChannelType[], currentUser: UserType}) {
    const firestore = useFirestore();
    const authContext = useContext(AuthContext);
    const mondayUsers = useContext(MondayUsersContext);
    const [mondayBoards, setMondayBoards] = useState<Record<string, MondayApiBoard>>({});
    const [addingSuggestedChannel, setAddingSuggestedChannel] = useState(false);

    useEffect(() => {
        getMondayBoards(authContext.accountId).then((res) => (
            setMondayBoards(res)
        )).catch((e) => console.log(e));
    }, []);

    const addSuggestedChannel = (channel: SuggestedChannelType) => {
        // Prevent adding a duplicate suggested channel if we're already adding one
        if (addingSuggestedChannel) {
            return;
        }
        setAddingSuggestedChannel(true);
        firestoreRepo(firestore, authContext).addSuggestedChannel(channel.type, channel.id, channel.name).then(() => setAddingSuggestedChannel(false)).catch((e) => console.log(e));
    };

    const setHideSuggestedChannels = (shouldHideSuggestedChannels: boolean) => {
        firestoreRepo(firestore, authContext).setHideSuggestedChannels(shouldHideSuggestedChannels).catch((e) => console.log(e));
    }

    /**
     * Create an array of suggested channels based on the following:
     * - Users that are subscribers on boards the current user is also subscribed to.
     *   This is ordered by how many boards the other user is subscribed to.
     * - Boards that the current user is subscribed to.
     * - Custom channels my team members are subscribed to. (Optional)
     */
    const currentUserId = authContext.userId;

    // An object that contains the user IDs of other users that are subscribed to boards the current user is also subscribed to.
    // The value is the number of shared boards between the current user and the other user.
    // E.g. { 20364464&&&&&53398026: 3, 20364464&&&&&54138225: 1 }
    const otherUserIdsWithSharedBoardCount : Record<string, number> = {}; // {userId: sharedBoardCount}
    
    const boardsTheUserIsSubscribedTo : MondayApiBoard[] = [];

    for (const boardId of Object.keys(mondayBoards)) {
        const boardSubscriberIds = mondayBoards[boardId].subscribers.map((subscriber) => subscriber.id.toString());
        const doesCurrentUserSubscribeToBoard = boardSubscriberIds.includes(currentUserId);
        if (!doesCurrentUserSubscribeToBoard) {
            continue;
        }

        boardsTheUserIsSubscribedTo.push(mondayBoards[boardId]);

        // Set the shared board count for each other user that is subscribed to the board
        const otherSubscribers = boardSubscriberIds.filter((subscriberId) => subscriberId !== currentUserId);
        for (const otherSubscriber of otherSubscribers) {
            const firestoreUserId = generateUserId(authContext.accountId, otherSubscriber);
            if (otherUserIdsWithSharedBoardCount[firestoreUserId]) {
                otherUserIdsWithSharedBoardCount[firestoreUserId]++;
            } else {
                otherUserIdsWithSharedBoardCount[firestoreUserId] = 1;
            }
        }
    }

    // Order the otherUserIdsWithSharedBoardCount object by the number of shared boards
    const otherUserIdsOrderedBySharedBoardCount = Object.keys(otherUserIdsWithSharedBoardCount).sort((a, b) => otherUserIdsWithSharedBoardCount[b] - otherUserIdsWithSharedBoardCount[a]);

    const suggestedUserChannels : SuggestedChannelType[] = otherUserIdsOrderedBySharedBoardCount.map((userId) => {
        const user = mondayUsers[userId];
        return {
            id: userId,
            name: user?.name,
            type: ChannelTypeEnum.Person,
            avatarType: user?.photo_small ? Avatar.types.IMG : Avatar.types.TEXT,
            // Get initials from the user's name
            avatarText: user?.name?.split(' ').map((name) => name[0]).join('')?.toUpperCase() ?? '',
            avatarIcon: null,
            avatarPictureUrl: user?.photo_small
        };
    }).filter((user) => !currentChannels.find((channel) => channel.type === ChannelTypeEnum.Person && channel.personChannelUserIds.includes(user?.id)));

    const suggestedBoardChannels : SuggestedChannelType[] = boardsTheUserIsSubscribedTo.map((board) => {
        return {
            id: board.id,
            name: board.name,
            type: ChannelTypeEnum.Board,
            avatarType: Avatar.types.ICON,
            avatarText: '',
            avatarIcon: Board,
            avatarPictureUrl: undefined,
        };
    }).filter((board) => !currentChannels.find((channel) => channel.type === ChannelTypeEnum.Board && channel.mondayRecordId === board.id));

    // Alternate between user and board channels
    const allSuggestedChannels : SuggestedChannelType[]  = [];
    for (let i = 0; i < Math.max(suggestedUserChannels.length, suggestedBoardChannels.length); i++) {
        if (suggestedBoardChannels[i]) {
            allSuggestedChannels.push(suggestedBoardChannels[i]);
        }
        if (suggestedUserChannels[i]) {
            allSuggestedChannels.push(suggestedUserChannels[i]);
        }
    }

    if (allSuggestedChannels.length === 0) {
        return null;
    }

    return (
        <div className="flex flex-col w-full flex-none px-2 max-h-48">
            <div className="flex flex-row p-1">
                <div
                    className=" text-secondary-text-color font-medium">
                    Suggested Channels & Users
                </div>
                <div
                    className="ml-auto text-secondary-text-color font-light flex flex-row hover:font-medium cursor-pointer"
                    onClick={() => setHideSuggestedChannels(!currentUser.hideSuggestedChannels)}
                    data-testid="suggested-channels-show-hide-button"
                >
                    <Icon icon={currentUser.hideSuggestedChannels ? DropdownChevronUp : DropdownChevronDown} iconSize={16} className="self-center"/>
                    <div className="ml-1 self-center">
                        {currentUser.hideSuggestedChannels ? 'Expand' : 'Hide'}
                    </div>
                </div>

            </div>
            {!currentUser.hideSuggestedChannels && (
                <div className="mx-1 overflow-auto" data-testid={"suggested-channels"}>
                    {allSuggestedChannels.map((channel) => (
                        <div
                            key={channel.id}
                            className="flex flex-row p-0.5 content-center justify-normal cursor-pointer rounded-md hover:bg-primary-background-hover-color group"
                            onClick={() => addSuggestedChannel(channel)}
                            data-testid={"suggested-channel"}
                        >
                            {channel.name && (
                                <Avatar
                                    className="flex-none w-7 h-7"
                                    backgroundColor={getAvatarBackgroundColor(channel.name ?? channel.id)}
                                    ariaLabel={channel.name}
                                    size={Avatar.sizes.MEDIUM}
                                    square={channel.type === ChannelTypeEnum.Board}
                                    withoutBorder={true}
                                    src={channel.avatarPictureUrl}
                                    icon={channel.avatarIcon}
                                    text={channel.avatarText}
                                    type={channel.avatarType}
                                />
                            )}

                            {!channel.name && (
                                <AvatarSkeleton height={28} width={28}/>
                            )}

                            <div className="shrink self-center ml-2 text-primary-text-color truncate mr-2">
                                <ChannelNameSkeleton channelName={channel.name}/>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}