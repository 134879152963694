import {getDatabase, onDisconnect, onValue, ref, set} from "firebase/database";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {useFirestore} from "reactfire";
import {useContext, useEffect} from "react";
import {AuthContext} from "../context/AuthContext.ts";
import {MondayUserSettingsContext} from "../context/MondayUserSettingsContext.ts";
import {getBoardViewBoardId} from "../monday/MondayContext.ts";

/**
 * Sets if the user is online or not in the database based on the Firebase realtime database.
 * A firebase function will update the user's online status in firestore.
 * This is done because firestore doesn't have a way to set a user's online status in the database.
 * @constructor
 */
export default function UserPresence() {
	const firestore = useFirestore();
	const authContext = useContext(AuthContext);
	const database = getDatabase();
	const mondayUserSettingsContext = useContext(MondayUserSettingsContext);

	const userStatusDatabaseRef = ref(database, 'accounts/' + authContext.accountId + '/users/' + authContext.firebaseUserId + '/isOnline');

	useEffect(() => {
		onValue(userStatusDatabaseRef, function(snap) {
			// This enables us to handle multiple tabs/windows open at once
			// If the offline status is false, but we're still connected, set the status to true
			if (snap.val() === false) {
				set(userStatusDatabaseRef, true).catch((e) => console.log(e));

				const boardViewBoardId = getBoardViewBoardId(mondayUserSettingsContext);
				// If the user is viewing a custom object, update the currentlyViewingBoardViewBoardId flag in firestore
				// This way, if one window is closed, the other window will keep this flag on until all custom object windows are closed.
				firestoreRepo(firestore, authContext).updateCurrentlyViewingBoardViewBoardId(boardViewBoardId).catch((e) => console.log(e));
			}
		});

		const connectedRef = ref(database,'.info/connected');
		onValue(connectedRef, function(snap) {
			if (snap.val() === false) {
				// We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
				set(userStatusDatabaseRef, true).catch((e) => console.log(e));
				firestoreRepo(firestore, authContext).updateOnlineStatus(true).catch((e) => console.log(e));
			}

			onDisconnect(userStatusDatabaseRef).set(false).then(function() {
				set(userStatusDatabaseRef, true).catch((e) => console.log(e));
				firestoreRepo(firestore, authContext).updateOnlineStatus(true).catch((e) => console.log(e));
			}).catch((e) => console.log(e));
		});
	}, []);

	return <></>;
}