import {Skeleton} from "monday-ui-react-core";

export default function ChannelsSkeleton() {
	return (
		<div>
			<div className="flex flex-row">
				<Skeleton className="m-1 ml-3" height={30} width={30} type={Skeleton.types.CIRCLE}/>
				<Skeleton className="m-1 mr-16 self-center" fullWidth type={Skeleton.types.TEXT} size={Skeleton.sizes.TEXT.H1}/>
			</div>
			<div className="flex flex-row">
				<Skeleton className="m-1 ml-3" height={30} width={30} type={Skeleton.types.CIRCLE}/>
				<Skeleton className="m-1 mr-14 self-center" fullWidth type={Skeleton.types.TEXT} size={Skeleton.sizes.TEXT.H1}/>
			</div>
			<div className="flex flex-row">
				<Skeleton className="m-1 ml-3" height={30} width={30} type={Skeleton.types.CIRCLE}/>
				<Skeleton className="m-1 mr-16 self-center" fullWidth type={Skeleton.types.TEXT} size={Skeleton.sizes.TEXT.H1}/>
			</div>
			<div className="flex flex-row">
				<Skeleton className="m-1 ml-3" height={30} width={30} type={Skeleton.types.CIRCLE}/>
				<Skeleton className="m-1 mr-20 self-center" fullWidth type={Skeleton.types.TEXT} size={Skeleton.sizes.TEXT.H1}/>
			</div>
			<div className="flex flex-row">
				<Skeleton className="m-1 ml-3" height={30} width={30} type={Skeleton.types.CIRCLE}/>
				<Skeleton className="m-1 mr-14 self-center" fullWidth type={Skeleton.types.TEXT} size={Skeleton.sizes.TEXT.H1}/>
			</div>
		</div>
	)
}