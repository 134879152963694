import {Avatar} from "monday-ui-react-core";
import {getAvatarBackgroundColor} from "../monday/MondayAvatar.ts";
import AvatarSkeleton from "./skeletons/AvatarSkeleton.tsx";

export default function MondayUserAvatar({userPhoto, userName, className, size}: {userPhoto: string | null, userName: string, className?: string, size?: typeof Avatar.sizes.SMALL | typeof Avatar.sizes.MEDIUM | typeof Avatar.sizes.LARGE}) {
    const userInitials = userName ? userName.split(' ').map((word) => word[0]).join('').toUpperCase() : ''
    const avatarColor = getAvatarBackgroundColor(userInitials);

    // If the user's haven't loaded yet, show the skeleton loader
    if (!userName && !userPhoto) {
        return <AvatarSkeleton height={32} width={32} />;
    }

     if (userPhoto) {
         return (
            <Avatar
                backgroundColor={avatarColor}
                size={size ?? Avatar.sizes.MEDIUM}
                withoutBorder={true}
                src={userPhoto}
                type={Avatar.types.IMG}
                className={className}
            />
        );
     }

    return (
        <Avatar
            backgroundColor={avatarColor}
            size={size ?? Avatar.sizes.MEDIUM}
            withoutBorder={true}
            text={userInitials}
            type={Avatar.types.TEXT}
            className={className}
        />
    );
}