import mondaySdk from "monday-sdk-js";
import './index.css';
import {useEffect, useRef, useState} from "react";
import {ThemeProvider} from "monday-ui-react-core";
import App from "./App.tsx";
import isMatch from "lodash/isMatch";
import {getFirebase} from "./firebase/firebase.ts";
import { AuthProvider, useFirebaseApp, FirestoreProvider, DatabaseProvider } from 'reactfire';
import {AuthContext, AuthContextProps} from "./context/AuthContext.ts";
import {authenticate} from "./firebase/authentication.ts";
import firestoreRepo from "./firebase/firestoreRepo.ts";
import {setMondayUsers, getMondayUsers, MondayApiUser, getAccountSlug} from "./monday/MondayUsers.ts";
import {MondayUsersContext} from "./context/MondayUsersContext.ts";
import {MondayUserSettingsContextProps, MondayUserSettingsContext} from "./context/MondayUserSettingsContext.ts";
import UserPresence from "./components/UserPresence.tsx";
import AppSkeleton from "./components/skeletons/AppSkeleton.tsx";
import {AccountContext} from "./context/AccountContext.ts";
import {mondayLocalAppConfig} from "../tests/mondayLocalAppConfig.ts";
import {MondayViewerException} from "./exceptions/MondayViewerException.ts";
import HowToUse from "./components/HowToUse.tsx";
import {generateUserId} from "./firebase/userIdGenerator.ts";

const monday = mondaySdk();
monday.setApiVersion('2024-01');

const AppWrapper = () => {
	const [context, _setContext] = useState<MondayUserSettingsContextProps>({theme: "light", themeConfig: {}, user: null, appFeature: null, boardId: null, account: null });
	const contextRef = useRef(context);
	// This is needed to allow the monday context listener access to the latest context values
	// since without this, the react state is stale when the listener is called
	const setContext = (data) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		contextRef.current = data;
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		_setContext(data);
	};
	const [authDetails, setAuthDetails] = useState<AuthContextProps | null>(null);
	const [mondayUsersContext, setMondayUsersContext] = useState<Record<string, MondayApiUser>>({});
	const {firestore, auth, database} = getFirebase(useFirebaseApp());
	const [accountSlug, setAccountSlug] = useState<string>('');
	const [isViewer, setIsViewer] = useState<boolean | null>(null);
	// This is to prevent the app from creating duplicate channels when the user first logs in.
	// When the user is first created, channels are created for them. If we rendered the App component, it
	// would also try to create channels at the same time (e.g. if they're looking at a board view), which would
	// cause duplicate channels to be created.
	const [initialUserSetupComplete, setInitialUserSetupComplete] = useState<boolean>(false);
	const isHowToUsePage = window.location.pathname === '/how-to-use';

	useEffect(() => {
		monday.listen("context", (res : {data: MondayUserSettingsContextProps}) => {
			const theme = res.data?.theme ?? 'light';
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const themeConfig = res.data?.themeConfig ?? {};
			const user = res.data?.user ?? null;
			const appFeature = res.data?.appFeature ?? null;
			const boardId = res.data?.boardId ?? null;

			// Only set the context if it's different from the current context, otherwise it will cause too many
			// re-renders and reads from Firestore
			if (contextRef.current?.theme === theme &&
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				isMatch(contextRef.current?.themeConfig, themeConfig) &&
				contextRef.current?.user?.id === user?.id &&
				contextRef.current?.appFeature?.type === appFeature?.type &&
				contextRef.current?.boardId === boardId
			) {
				return;
			}
			setContext(res.data);

			const accountId = res?.data?.account?.id;
			if (accountId) {
				// Set monday users context
				// Get monday users from the monday API and cache them in local storage
				getMondayUsers(accountId).then((res) => {
					setMondayUsersContext(res);
				}).catch((e) => console.log(e));
				// Set the monday users in local storage again, so it has the latest data
				setMondayUsers(accountId).catch((e) => console.log(e));
			}
		});

		// Since the monday.listen won't work in the test environment, we need to set the context manually
		// @ts-expect-error isRunningAutomationTest only exists on the window object if running from a Playwright test
		if (window.isRunningAutomationTest) {
			// @ts-expect-error isAutomationTestBoardContext only exists on the window object if running from a Playwright test
			if (window.isAutomationTestBoardContext) {
				setContext(mondayLocalAppConfig.contextBoardView);
				// @ts-expect-error ignore this error
				if (window.automationTestBoardId) {
					// @ts-expect-error ignore this error
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					mondayLocalAppConfig.contextBoardView.boardId = window.automationTestBoardId;
				}
			} else {
				setContext(mondayLocalAppConfig.context);
			}
			const mondayUsers = mondayLocalAppConfig.mondayUsersContext;
			const usersObject = {};
			for (const user of mondayUsers) {
				usersObject[`${user.account.id}&&&&&${user.id}`] = {...user, userId: generateUserId(user.account.id, user.id)};
			}

			setMondayUsersContext(usersObject);
		}

		authenticate().then((res) => {
			setAuthDetails({...res});
			setIsViewer(false);
			firestoreRepo(firestore, res).initializeNewUserIfNeeded().finally(() => setInitialUserSetupComplete(true)).catch((e) => console.log(e));
		}).catch((e) => {
			if (e instanceof MondayViewerException) {
				setIsViewer(true);
			}
			console.log(e);
		});

		getAccountSlug().then((res) => setAccountSlug(res ?? '')).catch((e) => console.log(e));
	}, []);

	// If the user is a viewer, we don't want to render the app since they shouldn't have access to it
	// Authentication should have also thrown an error if the user is a viewer.
	if (isViewer) {
		return (
			<div className="text-center pt-16 text-secondary-text-color">
				<h2 className="font-medium text-3xl">Error</h2>
				<p className="text-xl mt-5">Viewers do not have access to this app.</p>
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				<p className="text-lg mt-5">To access this app, your user needs a role other than "Viewer".</p>
			</div>
		)
	}

	if (isHowToUsePage) {
		return <HowToUse/>;
	}

	// Need to set the theme on the body instead of the root div, since otherwise modals won't be themed properly
	document.body.className = context.theme + "-app-theme" + " main";
	const isBoardView = context?.appFeature?.type === "AppFeatureBoardView";
	const boardId = context?.boardId?.toString() ?? undefined;

	// Don't render the app unless the component state is set, otherwise it will cause multiple
	// re-renders of the app, causing numerous reads from Firestore.
	if (context.appFeature === null ||
		context.user === null ||
		accountSlug === '' ||
		authDetails === null ||
		isViewer === null ||
		!initialUserSetupComplete
	) {
		return <AppSkeleton/>;
	}

	return (
		// eslint-disable-next-line react/jsx-no-undef
		<div>
			{/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
			<ThemeProvider themeConfig={context.themeConfig}>
				{authDetails === null || !initialUserSetupComplete ? <AppSkeleton/> :
					<AuthContext.Provider value={authDetails}>
						<MondayUserSettingsContext.Provider value={context}>
							<MondayUsersContext.Provider value={mondayUsersContext}>
								<AccountContext.Provider value={{accountSlug: accountSlug}}>
									<AuthProvider sdk={auth}>
										<DatabaseProvider sdk={database}>
											<FirestoreProvider sdk={firestore}>
												<UserPresence/>
												<App boardViewBoardId={isBoardView ? boardId : undefined}/>
											</FirestoreProvider>
										</DatabaseProvider>
									</AuthProvider>
								</AccountContext.Provider>
							</MondayUsersContext.Provider>
						</MondayUserSettingsContext.Provider>
					</AuthContext.Provider>
				}
			</ThemeProvider>
		</div>
	);
};

export default AppWrapper;