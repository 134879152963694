import "./App.css";
import SuggestedChannels from "./components/SuggestedChannels.tsx";
import MessagesContainer from "./components/MessagesContainer.tsx";
import ChannelCreationButton from "./components/ChannelCreationButton.tsx";
import firestoreRepo from "./firebase/firestoreRepo.ts";
import {useFirestore, useFirestoreCollectionData, useFirestoreDocData} from "reactfire";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "./context/AuthContext.ts";
import {ChannelMessageType, ChannelType, ChannelTypeEnum} from "./types/FirestoreCollections.type.ts";
import ParentThreadMessagesContainer from "./components/ParentThreadMessagesContainer.tsx";
import ChannelSearchWrapper from "./components/ChannelSearchWrapper.tsx"
import ChannelsWrapper from "./components/ChannelsWrapper.tsx";
import AppSkeleton from "./components/skeletons/AppSkeleton.tsx";
import {getMondayBoard} from "./monday/MondayBoards.ts";
import {MondayUserSettingsContext} from "./context/MondayUserSettingsContext.ts";
import AdminMain from "./components/AdminMain.tsx";

function App({boardViewBoardId}: {boardViewBoardId: string | undefined}) {
	const defaultMessageQueryLimit = 1;
	const firestore = useFirestore();
	const authContext = useContext(AuthContext);
	const mondayUserSettingsContext = useContext(MondayUserSettingsContext);
	const userSnapshot = firestoreRepo(firestore, authContext).getUser(useFirestoreDocData);
	const channelsSnapshot = firestoreRepo(firestore, authContext).getChannels(useFirestoreCollectionData);
	const [messageForThread, setMessageForThread] = useState<ChannelMessageType | null>(null);
	const [messageQueryLimit, setMessageQueryLimit] = useState(defaultMessageQueryLimit);
	const [currentChannel, setCurrentChannel] = useState<ChannelType | undefined>(undefined);
	const isBoardView = boardViewBoardId !== undefined;
	const currentUser = userSnapshot.data;
	const [isAdminMode, setIsAdminMode] = useState<boolean>(false);

	useEffect(() => {
		firestoreRepo(firestore, authContext).updateCurrentlyViewingBoardViewBoardId(boardViewBoardId ?? null).catch((e) => console.log(e));
	}, [boardViewBoardId]);

	useEffect(() => {
		if (mondayUserSettingsContext?.appFeature?.type === "AppFeatureObject") {
			firestoreRepo(firestore, authContext).setCustomObjectBoardId(mondayUserSettingsContext.boardId).catch((e) => console.log(e));
		}
	}, []);

	useEffect(() => {
		if (userSnapshot.status === 'loading' || channelsSnapshot.status === 'loading' || !channelsSnapshot?.data?.length) {
			return;
		}

		(async () => {
			if (isBoardView) {
				const boardChannel = await firestoreRepo(firestore, authContext).getBoardChannel(boardViewBoardId);
				if (boardChannel) {
					setCurrentChannel(boardChannel);
					// Add the user to the board channel if they're not already a member
					await firestoreRepo(firestore, authContext).addMemberToChannel(boardChannel.id, authContext.firebaseUserId);
					return;
				}

				// If it's a board view and the channel has not been created yet, create it
				const mondayBoard = await getMondayBoard(boardViewBoardId);
				const boardName = mondayBoard?.name;
				if (boardName && boardViewBoardId) {
					const newChannel = await firestoreRepo(firestore, authContext).createChannel(boardName, ChannelTypeEnum.Board, boardViewBoardId.toString());
					// Set the currentlyViewingChannel to the newly created channel
					firestoreRepo(firestore, authContext).setCurrentlyViewingChannel(newChannel.id).catch((e) => console.log(e));
				}

				return;
			} else {
				if (channelsSnapshot.data.length === 0) {
					setCurrentChannel(undefined);
				}

				const currentlyViewingChannel = channelsSnapshot.data.find((channel) => channel.id === currentUser.currentlyViewingChannel);
				if (currentlyViewingChannel) {
					setCurrentChannel(currentlyViewingChannel);
				} else {
					// If the currentlyViewingChannel does not exist in the channelsSnapshot (maybe it was deleted), set the currentChannel to the first channel in the list
					setCurrentChannel(channelsSnapshot?.data?.[0] || undefined);
				}
			}
		})().catch((e) => console.log(e));
	}, [authContext, boardViewBoardId, channelsSnapshot?.data, currentUser?.currentlyViewingChannel, firestore, isBoardView]);

	if (isAdminMode) {
		return <AdminMain disableAdminMode={() => setIsAdminMode(false)}/>;
	}

	if (userSnapshot.status === 'loading' || channelsSnapshot.status === 'loading' || !userSnapshot.data ) {
		return <AppSkeleton isBoardView={isBoardView}/>
	}

	const onScrollToTop = () => {
		let pagingLimit = 20;
		// If we're past the second page, increase the paging limit by a lot more to cut down on the number of times we
		// fetch the entire snapshot, since we're not actually paging.
		// Instead of paging, we re-fetch all the records, which isn't optimal, but it's the best we can do for now.
		if (messageQueryLimit > 2 * pagingLimit) {
			pagingLimit = 100;
		}
		
		setMessageQueryLimit(messageQueryLimit + pagingLimit);
	}

	if (isBoardView) {
		// If the board view channel has been created, set the currentlyViewingChannel to that channel
		// If not, return the AppSkeleton while we wait for it to be created.
		if (currentChannel?.id) {
			currentUser.currentlyViewingChannel = currentChannel.id;
		} else {
			return <AppSkeleton isBoardView={isBoardView}/>
		}
	}

	if (!isBoardView) {
		// First, check to see if the channel to go to after app open is set in the user's data
		const channelToGoToAfterAppOpen = currentUser.channelToGoToAfterAppOpen;

		// If the channel to go to after app open is set, set the currentlyViewingChannel to that channel
		if (channelToGoToAfterAppOpen) {
			currentUser.currentlyViewingChannel = channelToGoToAfterAppOpen;
			firestoreRepo(firestore, authContext).setCurrentlyViewingChannel(channelToGoToAfterAppOpen).catch((e) => console.log(e));
			firestoreRepo(firestore, authContext).setChannelToGoToAfterAppOpen(currentUser.firebaseUserId, null).catch((e) => console.log(e));
		} else {
			// If the channel to go to after app open is not set, set the currentlyViewingChannel to the first channel in the list
			// if the currentlyViewingChannel is not set.
			currentUser.currentlyViewingChannel = userSnapshot?.data?.currentlyViewingChannel ?? channelsSnapshot?.data?.[0]?.id;
		}
	}

	const onChannelClick = (channelId: string) => {
		// If we're in a thread and the channel is clicked, close the thread
		setMessageForThread(null);

		// Reset the message query limit when switching channels
		setMessageQueryLimit(defaultMessageQueryLimit);

		// Clear out the thread notification count
		if (currentChannel) {
			firestoreRepo(firestore, authContext).clearTotalMissedThreadMessages(channelId, currentUser.firebaseUserId).catch((e) => console.log(e));
		}
	}

	return (
		<>
			{/*Container*/}
			<div className="flex flex-row w-full h-full">
				{/*Sidebar*/}
				{!isBoardView && (
						<div className="flex flex-col min-w-[21rem] max-w-[22rem] border-r-1 bg-primary-background-color border-r border-layout-border-color">
							{/*Search*/}
							<div className="m-3 flex">
								<ChannelSearchWrapper/>
								<ChannelCreationButton/>
							</div>
							{/*<Channels />*/}
							<ChannelsWrapper channels={channelsSnapshot.data} onChannelClick={onChannelClick}/>

							{/*Suggested Channels*/}
							<SuggestedChannels currentUser={currentUser} currentChannels={channelsSnapshot.data}/>
							{authContext.accountId === '20364464' && authContext.userId === '53398026' && (
								<div className="w-full cursor-pointer" onClick={() => setIsAdminMode(true)}>&nbsp;</div>
							)}
						</div>

				)}

				{/*Messages Container*/}
				{!messageForThread && currentChannel && currentUser.currentlyViewingChannel && (
					<MessagesContainer key={currentChannel.id} currentUser={currentUser} currentChannel={currentChannel} setMessageForThread={setMessageForThread} messageQueryLimit={messageQueryLimit} onScrollToTop={onScrollToTop}/>
				)}

				{messageForThread && currentChannel && (
					<ParentThreadMessagesContainer currentUser={currentUser} currentChannel={currentChannel} key={messageForThread.id} parentMessage={messageForThread} setMessageForThread={setMessageForThread}/>
				)}
			</div>
		</>
	);
}

export default App;
