import {ChannelType, ChannelTypeEnum} from "../types/FirestoreCollections.type.ts";
import {Icon} from "monday-ui-react-core";
import {MoveArrowDown} from "monday-ui-react-core/icons";
import {useContext} from "react";
import {MondayUserSettingsContext} from "../context/MondayUserSettingsContext.ts";

export default function EmptyChannelPlaceholder({channel, numberOfLoadedMessages} : {
	channel: ChannelType,
	numberOfLoadedMessages: number
}) {
	const mondayUserSettingsContext = useContext(MondayUserSettingsContext);
	const isBoardView = mondayUserSettingsContext?.appFeature?.type === "AppFeatureBoardView";
	// This component is a placeholder for when a channel is empty
	// It lets the user know to add members to the channel to start a conversation
	if (numberOfLoadedMessages > 0 || numberOfLoadedMessages === null) return null;
	if (channel.type === ChannelTypeEnum.Person) return (
		<div className="flex-grow flex flex-col items-center justify-center text-primary-text-color mt-10">
			<div className="text-center mt-5">
				Add a message below to start a conversation with <span className="font-semibold">{channel.name}</span>.
			</div>
			<Icon icon={MoveArrowDown} iconSize={56} className="mt-5"/>
		</div>
	)
	if (channel.memberIds.length > 1 && numberOfLoadedMessages === 0) {
		return (
			<div className="flex-grow flex flex-col items-center justify-center text-primary-text-color mt-10">
				<div className="text-3xl">
					Welcome! 👋
				</div>
				<div className="text-center mt-5">
					Add a message below to start your conversation.
				</div>

				<Icon icon={MoveArrowDown} iconSize={56} className="mt-5"/>
			</div>
		)
	}
	return (
		<div className="flex-grow flex flex-col items-center justify-center text-primary-text-color mt-10">
			<div className="text-3xl">
				Welcome! 👋
			</div>

			{channel.type === ChannelTypeEnum.Board && (
				<div className="text-center mt-5">
					This channel can be used for your discussions about the <span className="font-semibold">{channel.name}</span> board.
				</div>
			)}

			<div className="text-center mt-5 max-w-96">
				Click the <span className="font-semibold text-color-grass_green">Add Members</span> button above to add your co-workers to this
				channel.
			</div>

			<div className="text-center mt-5">
				Add a message below to start your conversation.
			</div>
			{channel.type === ChannelTypeEnum.Board && !isBoardView && (
				<div className="text-center mt-5 max-w-96 text-color-bright-blue">
					<span className="font-semibold text-color-navy">Pro tip:</span> You can add this channel directly to your board as a board view.
				</div>
			)}
		</div>
	);
}