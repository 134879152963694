import {getBoard, getBoards} from "./MondayApi.ts";

export interface MondayApiBoard {
    name: string;
    id: string;
    type: string;
    workspace: {
        name: string;
    };
    subscribers: {
        id: string;
    }[];
}

export async function setMondayBoards(accountId: string) : Promise<Record<string, MondayApiBoard>> {
    const boards = await fetchBoardsFromMondayApi();
    if (boards.length === 0) {
        return {};
    }

    const boardsObject = {};
    for (const board of boards) {
        boardsObject[`${accountId}&&&&&${board.id}`] = board;
    }

    // Add monday users to monday storage
    localStorage.setItem('monday_boards_' + accountId, JSON.stringify(boardsObject));
    return boardsObject;
}

export async function getMondayBoards(accountId: string) {
    const mondayBoards = localStorage.getItem('monday_boards_' + accountId);
    if (mondayBoards === null) {
        return await setMondayBoards(accountId);
    }

    return JSON.parse(mondayBoards) as Record<string, MondayApiBoard>;
}
async function fetchBoardsFromMondayApi(): Promise<MondayApiBoard[]> {
    let boards : MondayApiBoard[] = [];
    let boardResponse: {data: {boards: MondayApiBoard[]}} | null = null;
    let currentPage = 1;
    // Fetch monday users from monday api using pagination
    do {
        boardResponse = await getBoards(currentPage);
        if (boardResponse) {
            boards = boards.concat(boardResponse.data.boards);
        }
        currentPage++;
    } while (boardResponse?.data?.boards?.length && currentPage < 100);

    // Only return boards of type 'board'
    boards = boards.filter(board => board.type === 'board');
    return boards;
}

export async function getMondayBoard(boardId: string) : Promise<MondayApiBoard | null> {
    return await getBoard(boardId);
}