import {createContext} from "react";
// @ts-expect-error I think this is correct
import {Theme} from "monday-ui-react-core/dist/esm/components/ThemeProvider/ThemeProviderConstants";

export interface MondayUserSettingsContextProps {
    account: {
        id: string;
    } | null;
    user: {
        id: string | null;
        timeFormat: string | null;
    } | null;
    theme: string;
    themeConfig: Theme;
    appFeature: {
        type: string;
    } | null;
    boardId: string | null;
}
export const MondayUserSettingsContext = createContext<MondayUserSettingsContextProps>({user: null, theme: "light", themeConfig: {}, appFeature: null, boardId: null, account: null});