import {useContext} from "react";
import {
	Grid, // our UI Component to display the results
	SearchBar, // the search bar the user will type into
	SearchContext, // the context that wraps and connects our components
	SearchContextManager, // the context manager, includes the Context.Provider
} from '@giphy/react-components'

export interface IGif {
	embed_url: string
}

function Components({width, onGifClick} : {width: number, onGifClick: (gif: never, e: React.SyntheticEvent<HTMLElement, Event>) => void}) {
	const { fetchGifs, searchKey } = useContext(SearchContext)
	return (
		<>
			<SearchBar className="border border-ui-border-color my-2" autoFocus={true}/>
			{/**
                key will recreate the component,
                this is important for when you change fetchGifs
                e.g. changing from search term dogs to cats or type gifs to stickers
                you want to restart the gifs from the beginning and changing a component's key does that
			 **/}
			<Grid className="max-h-40 overflow-auto" key={searchKey} columns={3} width={width} hideAttribution={true} fetchGifs={fetchGifs} onGifClick={onGifClick}/>
		</>
	)
}

// the search experience consists of the manager and its child components that use SearchContext
export default function GiphySearch({width, className = '', onGifClick}: {width: number, className: string, onGifClick: (gif: IGif, e: React.SyntheticEvent<HTMLElement, Event>) => void}) {
	return (
		<div className={className}>
			<SearchContextManager apiKey={'uUX5M4nxNCKzHCimkNxJh5UTPWmPsmss'} options={{type: 'gifs', limit: 10, sort: 'relevant'}}>
				<Components width={width} onGifClick={onGifClick}/>
			</SearchContextManager>
		</div>
	);
}

GiphySearch.defaultProps = {
	className: '',
}
