import moment from "moment";
import {MondayUserSettingsContext} from "../context/MondayUserSettingsContext";
import React, {useContext} from "react";
import MondayUserAvatar from "./MondayUserAvatar.tsx";
import {Avatar} from "monday-ui-react-core";
import {Robot} from "monday-ui-react-core/icons";
import {ChannelMessageType} from "../types/FirestoreCollections.type.ts";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import AutomationMondayNavigation from "./AutomationMondayNavigation.tsx";
import AutomationMondayItemCardOpen from "./AutomationMondayItemCardOpen.tsx";
import ChannelNameSkeleton from "./skeletons/ChannelNameSkeleton.tsx";

export default function Message({children, firstMessageInGroup, isThreadContext}: {
    children: React.ReactNode,
    firstMessageInGroup: ChannelMessageType,
    isThreadContext: boolean,
}) {
    const mondayUsersContext = useContext(MondayUsersContext);
    const userPhoto = mondayUsersContext[firstMessageInGroup.createdBy]?.photo_small;
    const userName = mondayUsersContext[firstMessageInGroup.createdBy]?.name;
    const messageTime = firstMessageInGroup.createdAt?.toDate() ?? new Date(firstMessageInGroup.localTimeStamp);
    const isAutomationMessage = firstMessageInGroup.isAutomationMessage;
    const automationBoardName = firstMessageInGroup.automationMessageBoardName;
    const mondayUserSettingsContext = useContext(MondayUserSettingsContext);

    let messageSentTimeFormat = 'h:mm A';
    if (mondayUserSettingsContext.user?.timeFormat === '24H') {
        messageSentTimeFormat = 'HH:mm';
    }

    // Time formatting is a bit different for threads vs messages
    // For threads it is relative time, for messages it's absolute time
    const messageSentTime = isThreadContext ? moment(messageTime).fromNow() : moment(messageTime).format(messageSentTimeFormat);
    const getMessageName = () => {
        // For automated messages, we show the board name instead of the user name and link to the board/item.
        if (isAutomationMessage) {
            return (
                <AutomationMondayNavigation boardName={automationBoardName} boardId={firstMessageInGroup.automationMessageBoardId} itemId={firstMessageInGroup.automationMessageItemId}/>
            )
        }

        return <ChannelNameSkeleton channelName={userName}/>
    }

    return (
        <div
            className="flex flex-row w-full px-3">
            <div className="shrink">
                {isAutomationMessage && (
                    <Avatar
                        backgroundColor={Avatar.colors.STUCK_RED}
                        ariaLabel="Automation"
                        size={Avatar.sizes.LARGE}
                        withoutBorder={true}
                        icon={Robot}
                        type={Avatar.types.ICON}
                        className="flex-none w-9 h-9"
                    />
                )}
                {!isAutomationMessage && (
                    <MondayUserAvatar userPhoto={userPhoto} userName={userName}/>
                )}
            </div>
            <div className="flex flex-col ml-2 w-full">
                <div className="flex flex-row items-center shrink pl-1">
                    <div data-testid="message-name" className="text-primary-text-color font-medium">
                        {getMessageName()}
                    </div>
                    <div data-testid="message-sent-time" className="text-secondary-text-color font-light ml-2 text-xs">{messageSentTime}</div>
                </div>
                {firstMessageInGroup.automationMessageItemId && (
                    <AutomationMondayItemCardOpen itemId={firstMessageInGroup.automationMessageItemId} />
                )}
                {children}
            </div>
        </div>
    )
}