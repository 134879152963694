import {Skeleton} from "monday-ui-react-core";

export default function ThreadMessagesSkeleton() {
	return (
		<div className="flex flex-col w-full bg-secondary-background-color">
			<div className="flex flex-row">
				<Skeleton className="" height={120} fullWidth type={Skeleton.types.RECTANGLE}/>
			</div>
			<div className="flex flex-col flex-1 overflow-auto">
				<div className="flex mt-2">
					<Skeleton className="ml-3 mt-3" height={38} width={38} type={Skeleton.types.CIRCLE}/>
					<div className="w-full">
						<Skeleton className="m-2 ml-2.5" height={100} fullWidth type={Skeleton.types.RECTANGLE}/>
					</div>
				</div>
				<div className="flex">
					<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
					<div className="w-full">
						<Skeleton className="m-2 ml-2.5" height={100} fullWidth type={Skeleton.types.RECTANGLE}/>
					</div>
				</div>
				<div className="flex">
					<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
					<div className="w-full">
						<Skeleton className="m-2 ml-2.5" height={100} fullWidth type={Skeleton.types.RECTANGLE}/>
					</div>
				</div>
				<div className="flex">
					<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
					<div className="w-full">
						<Skeleton className="m-2 ml-2.5" height={100} fullWidth type={Skeleton.types.RECTANGLE}/>
					</div>
				</div>
				<div className="flex">
					<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
					<div className="w-full mb-3">
						<Skeleton className="m-2 ml-2.5" height={100} fullWidth type={Skeleton.types.RECTANGLE}/>
					</div>
				</div>
			</div>
			<div className="flex flex-col w-full">
				<Skeleton className="" height={100} fullWidth type={Skeleton.types.RECTANGLE}/>
			</div>
		</div>
	)
}