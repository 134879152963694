import {useFirestore, useFirestoreDocData} from "reactfire";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {ChannelType, ChannelTypeEnum} from "../types/FirestoreCollections.type.ts";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import Channels from "./Channels.tsx";
import ChannelsSkeleton from "./skeletons/ChannelsSkeleton.tsx";

export default function ChannelsWrapper({channels, onChannelClick}: {channels: ChannelType[],  onChannelClick: (channelId: string) => void}) {
	const firestore = useFirestore();
	const authContext = useContext(AuthContext);
	const mondayUsersContext = useContext(MondayUsersContext);
	const userSnapshot = firestoreRepo(firestore, authContext).getUser(useFirestoreDocData);

	if (userSnapshot.status === 'loading' || !userSnapshot.data) {
		return <ChannelsSkeleton/>;
	}

	const setCurrentlyViewingChannel = (channelId: string) => {
		firestoreRepo(firestore, authContext).setCurrentlyViewingChannel(channelId).catch((e) => console.log(e));
		onChannelClick(channelId);
	}

	const currentlyActiveChannel = (() => {
		if (userSnapshot.data.currentlyViewingChannel) {
			return userSnapshot.data.currentlyViewingChannel
		}

		// If the user has no currentlyViewingChannel, set it to the first team channel in the list, if it exists
		const teamChannel = channels.find((channel) => channel.type === ChannelTypeEnum.Team);
		if (teamChannel) {
			return teamChannel.id;
		}

		if (channels.length === 0) {
			return null;
		}

		// If no team channel exists, set it to the first channel in the list
		return channels[0].id;
	})();

	// Set person channel names to the name of the other person
	// Also, set the photo to the photo of the other user
	channels.forEach((channel) => {
		if (channel.type === ChannelTypeEnum.Person) {
			const otherUser = channel.personChannelUserIds.find((user) => user !== authContext.firebaseUserId);
			if (otherUser) {
				channel.name = mondayUsersContext[otherUser]?.name ?? null;
				channel.pictureUrl = mondayUsersContext[otherUser]?.photo_small ?? null;
			}
		}
	});

	// Sort channels by name
	channels.sort((a, b) => {
		if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
			return -1;
		}

		if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
			return 1;
		}

		return 0;
	});

	return (
		<Channels currentlyActiveChannel={currentlyActiveChannel} channels={channels} currentUser={userSnapshot.data} setCurrentlyViewingChannel={setCurrentlyViewingChannel}/>
	);
}