import {Tipseen, TipseenContent} from "monday-ui-react-core";
import React, {useContext} from "react";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {useFirestore} from "reactfire";
import {AuthContext} from "../context/AuthContext.ts";
import {UserType} from "../types/FirestoreCollections.type.ts";

export default function NotificationPreferencesTipseen({currentUser, children}: {currentUser: UserType, children: React.ReactNode}) {
	const firestore = useFirestore();
	const authContext = useContext(AuthContext);
	const myRef = React.useRef<HTMLDivElement>(null);
	const onCloseNotificationPreferencesTooltip = () => {
		firestoreRepo(firestore, authContext).setDisableShowNotificationPreferencesTooltip(currentUser.firebaseUserId, true).catch((e) => console.log(e));
	}

	if (!currentUser.shouldShowNotificationPreferencesTooltip || currentUser.disableShowNotificationPreferencesTooltip) {
		return (
			<div>
				{children}
			</div>
		)
	}

	return (
		<div className={`z-50`}>
			<Tipseen
				className={"z-50"}
				position={Tipseen.positions.BOTTOM}
				onClose={onCloseNotificationPreferencesTooltip}
				ref={myRef}
				content={
					<TipseenContent title={"Receiving too many notifications?"} submitButtonText={"Okay"} onSubmit={onCloseNotificationPreferencesTooltip}>
						<div>Click the notification preferences button to adjust your channel notification settings.</div>
					</TipseenContent>

				}>
				<div>
					{children}
				</div>
			</Tipseen>
		</div>
	)
}