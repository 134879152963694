import firestoreRepo from "../firebase/firestoreRepo.ts";
import {useFirestore, useFirestoreDocData} from "reactfire";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext.ts";
import {ChannelMessageType, ChannelType, UserType} from "../types/FirestoreCollections.type.ts";
import ThreadMessagesContainer from "./ThreadMessageContainer.tsx";
import ThreadMessagesSkeleton from "./skeletons/ThreadMessagesSkeleton.tsx";

/**
 * This container is used to display the parent message of a thread.
 * A separate container was needed because we need to fetch the parent message from firestore and listen for updates.
 * Before, it was passed via useState, which doesn't allow for listening for updates.
 *
 * @param parentMessage
 * @param currentChannel
 * @param currentUser
 * @param setMessageForThread
 * @constructor
 */
export default function ParentThreadMessagesContainer({parentMessage, currentChannel, currentUser, setMessageForThread} : {parentMessage: ChannelMessageType, currentChannel: ChannelType, currentUser: UserType, setMessageForThread: ((message: ChannelMessageType | null) => void)}) {
    const firestore = useFirestore();
    const authContext = useContext(AuthContext);

    const messageSnapshot = firestoreRepo(firestore, authContext).getMessage(useFirestoreDocData, parentMessage.channelId, parentMessage.id);
    if (messageSnapshot.status === 'loading') {
        return (
            <ThreadMessagesSkeleton/>
        );
    }
    return (
        <ThreadMessagesContainer currentUser={currentUser} currentChannel={currentChannel} parentMessage={messageSnapshot.data} setMessageForThread={setMessageForThread} />
    )
}