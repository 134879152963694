import {generateUserId} from "../firebase/userIdGenerator.ts";
import {getMondayAccountSlug, getUsers} from "./MondayApi.ts";

export interface MondayApiUser {
    name: string;
    id: string;
    userId: string;
    photo_small: string;
    account: {
        id: string;
    };
}

export async function setMondayUsers(accountId: string) : Promise<Record<string, MondayApiUser>> {
    const users = await fetchUsersFromMondayApi();
    if (users.length === 0) {
        return {};
    }

    const usersObject = {};
    for (const user of users) {
        usersObject[`${user.account.id}&&&&&${user.id}`] = {...user, userId: generateUserId(user.account.id, user.id)};
    }

    // Add monday users to monday storage
    localStorage.setItem('monday_users_' + accountId, JSON.stringify(usersObject));
    return usersObject;
}

export async function getAccountSlug() {
    const account = await getMondayAccountSlug() as {data: {me: {account: {slug: string}}}} | null;
    return account?.data?.me?.account?.slug;
}

export async function getMondayUsers(accountId: string) {
    const mondayUsers = localStorage.getItem('monday_users_' + accountId);
    // const mondayUsers = await monday.storage.getItem('monday_users');
    if (mondayUsers === null) {
        return await setMondayUsers(accountId);
    }

    return JSON.parse(mondayUsers) as Record<string, MondayApiUser>;
}
async function fetchUsersFromMondayApi(): Promise<MondayApiUser[]> {
    let users : MondayApiUser[] = [];
    let userResponse: {data: {users: MondayApiUser[]}} | null = null;
    let currentPage = 1;
    // Fetch monday users from monday api using pagination
    do {
        userResponse = await getUsers(currentPage);
        if (userResponse) {
            users = users.concat(userResponse.data.users);
        }
        currentPage++;
    } while (userResponse?.data?.users?.length && currentPage < 100);

    return users;
}

