// import channelSearch from '../assets/channel-search.png';
export default function HowToUse() {
	// @ts-expect-error - This is a hack to change the background color of the page, which is needed for monday.com app review process
	document.body.style = "background-color: #ffffff;";
	return (
		<div className="p-16 mx-auto max-w-[1200px]">
			<div>
				<div className="text-color-blackish">
					<img src="/img/ChatCentralIcon.png" className="ml-auto mr-auto rounded-md" width={80}/>
					<h1 className="text-center text-4xl mt-3 text-[#0098b3]">Chat Central</h1>
					<h1 className="text-center text-3xl mb-10 mt-2 text-[#68ce69]">Getting Started Guide</h1>
					<h2 className="text-4xl">Features in monday.com</h2>
					<div>
						<p className="mb-1 mt-2 text-secondary-text-color">
							To jump directly to the help guide, click here: <a href="#help-guide" className="underline">Help Guide</a>.
						</p>
						<p className="mb-1 mt-2">
							Chat Central can be used in three different places in monday.com:
						</p>
						<ul>
							<li>- <strong>Workspaces:</strong> full-featured real-time chat for your entire organization
							</li>
							<li>- <strong>Board View:</strong> chat that is focused on a specific board</li>
							<li>- <strong>Automations:</strong> add chat messages automatically to channels via
								monday.com automations
							</li>
						</ul>

						<h2 className="text-2xl mt-4">Workspaces</h2>
						<p className="mt-2">
							Adding Chat Central to a workspace is a great way to keep your organization connected through
							real-time chat. You can create custom channels for teams, projects, initiatives, and a
							variety of topics. You can also direct message your co-workers.
							By default, if you're part of a team, a team channel will automatically be created for
							you. It will also automatically populate all the members of the team.
						</p>
						<img className="my-4" src="/img/how-to-use/custom-object-example.png"/>

						<h2 className="text-2xl mt-4">Boards</h2>
						<p className="mt-2">
							Adding Chat Central to a board is a great way to add real-time chat to a specific board.
							This chat view is focused on the board and doesn't include
							all the other channels.
						</p>
						<img className="my-4" src="/img/how-to-use/board-view-example.png"/>

						<h2 className="text-2xl mt-4">Automations</h2>
						<p className="mt-2">
							Add an automation to a board to automatically post messages to a Chat Central channel when
							an event occurs in monday.com.
						</p>
						<img className="my-4" src="/img/how-to-use/automation-example.png"/>
						<img className="my-4" src="/img/how-to-use/automations7.png"/>
					</div>
					<h2 className="text-4xl mt-8 mb-3" id="help-guide">Help Guide</h2>

					<p className="text-lg mt-4 mb-2 link">Click the links below to jump to a specific section:</p>
					<ol>
						<li>
							<a href="#add-to-workspace" className="mt-4 link text-color-dark-blue">- Add Chat Central to
								a
								workspace</a>
						</li>
						<li>
							<a href="#add-to-board" className="mt-4 link text-color-dark-blue">- Add Chat Central to a
								board</a>
						</li>
						<li>
							<a href="#add-automations" className="mt-4 link text-color-dark-blue">- Add a Chat Central automation</a>
						</li>
						<li>
							<a href="#create-custom-channel" className="mt-4 link text-color-dark-blue">- Create a
								custom channel</a>
						</li>
						<li>
							<a href="#add-existing-channel" className="mt-4 link text-color-dark-blue">- Add an existing
								channel</a>
						</li>
						<li>
							<a href="#channel-notifications" className="mt-4 link text-color-dark-blue">- Disable or
								change channel notifications</a>
						</li>
						<li>
							<a href="#thread-notifications" className="mt-4 link text-color-dark-blue">- Mute thread
								notifications</a>
						</li>
						<li>
							<a href="#leave-channel" className="mt-4 link text-color-dark-blue">- Leave a channel</a>
						</li>
						<li>
							<a href="#add-members" className="mt-4 link text-color-dark-blue">- Adding members to a
								channel</a>
						</li>
						<li>
							<a href="#reactions" className="mt-4 link text-color-dark-blue">- Reacting to a message</a>
						</li>
						<li>
							<a href="#threads" className="mt-4 link text-color-dark-blue">- Replying to a message by
								using threads</a>
						</li>
						<li>
							<a href="#support" className="mt-4 link text-color-dark-blue">- Additional support</a>
						</li>
					</ol>


					<div>
						<div className="mt-8" id="add-to-workspace">
							<h2 className="text-2xl ">Add Chat Central to a workspace</h2>
							<p className="mt-2">
								1. To add the Chat Central app to a workspace, go to your workspace, then click the plus
								button.
								Hover over "Apps" and click "Chat Central".
							</p>
							<img className="my-4" src="/img/how-to-use/add-custom-object.png"/>
							<p className="mt-2">
								2. This will add the Chat Central app to your workspace.
							</p>
							<img className="my-4" src="/img/how-to-use/custom-object-example.png"/>
						</div>
						<div className="mt-8" id="add-to-board">
							<h2 className="text-2xl ">Add Chat Central to a board</h2>
							<p className="mt-2">
								1. To add the Chat Central app to a board, go to your board, then click the plus
								button to add a board view.
								Hover over "Apps" and click "Chat Central".
							</p>
							<img className="my-4" src="/img/how-to-use/add-board-view.png"/>
							<p className="mt-2">
								2. This will add the Chat Central app to your board.
							</p>
							<img className="my-4" src="/img/how-to-use/board-view.png"/>
						</div>
						<div className="mt-8" id="add-automations">
							<h2 className="text-2xl ">Add a Chat Central automation</h2>
							<p className="mt-2">
								1. To automatically add a message to a channel when an event occurs in monday.com, start
								by clicking the "Automate" button on the top of your board.
							</p>
							<img className="my-4" src="/img/how-to-use/automations1.png"/>
							<p className="mt-2">
								2. Under the "Board Automations" tab, click the "Add automation" button.
							</p>
							<img className="my-4" src="/img/how-to-use/automations2.png"/>
							<p className="mt-2">
								3. Select an event to trigger the automation. For example, when a status changes, when
								an item is created, or when a column changes.
							</p>
							<img className="my-4" src="/img/how-to-use/automations3.png"/>
							<p className="mt-2">
								4. Click the "Then do this" field and search for "Chat Central". If the trigger you
								selected is related to an item, select the "Send Item Chat Message" action. If not,
								select the "Send Chat Message" action.
							</p>
							<img className="my-4" src="/img/how-to-use/automations4.png"/>
							<p className="mt-2">
								5. Click the "Message" field and add your message. You can use the fields from the board
								item if your message is related to a specific item. Finally, click Done.
							</p>
							<img className="my-4" src="/img/how-to-use/automations5.png"/>
							<p className="mt-2">
								6. Click the "Channel" field and search for your Chat Central channel and select it.
							</p>
							<img className="my-4" src="/img/how-to-use/automations6.png"/>
							<p className="mt-2">
								7. Finally, click "Create automation" to save your automation.
							</p>
							<p className="mt-2">
								8. Now when the event in monday.com is triggered, it will create a message in your Chat
								Central channel. To easily go to the board that generated the message, click the board
								link in
								the message title.
							</p>
							<img className="my-4" src="/img/how-to-use/automations7.png"/>
							<p className="mt-2">
								9. To view the item that generated the message, click the "View Item" button in the
								message. This will open an item editor directly in Chat Central, where you can make updates or view the item information.
							</p>
							<img className="my-4" src="/img/how-to-use/automations8.png"/>

						</div>
						<div className="mt-8" id="create-custom-channel">
							<h2 className="text-2xl ">Create a new custom channel</h2>
							<p className="mt-2">
								1. To add a new channel, just click the plus button next to the channel search bar.
							</p>
							<img className="my-4" src="/img/how-to-use/create-channel.png"/>
							<p className="mt-2">
								2. Once you click the plus button to create a channel, add your channel name, then click
								"Create".
							</p>
							<img className="my-4" src="/img/how-to-use/create-channel2.png"/>
							<p className="mt-2">
								3. You will then see the channel added to your list of channels. Don't forget to add
								members to your new channel. Once you create a channel, anyone in your organization can
								search for the channel and join it.
							</p>
						</div>
						<div className="mt-8" id="add-existing-channel">
							<h2 className="text-2xl">Adding an existing channel</h2>
							<p className="mt-2">
								To join an existing channel, search for the channel in the top left of the Chat Central
								app.
							</p>
							<p className="mt-2">
								You can search for and add a custom channel, team channel, or a board channel to your
								list of channels.
								You can also search for a person to direct message them. By default, all users in your organization are included in the channel search results. In addition, all
								boards visible to the user are included in the channel search.
							</p>
							<img className="my-4" src="/img/how-to-use/channel-search.png"/>
						</div>
						<div className="mt-8" id="channel-notifications">
							<h2 className="text-2xl">Disable or change channel notifications</h2>
							<p className="mt-2">
								Notifications can be disabled or changed for each channel. The default for new channels
								can also be set.
							</p>
							<p className="mt-2">
								To change the notification preference for a channel, click on the channel and in the top
								right, select the bell icon.
								Then, select your notification preference.
							</p>
							<img className="my-4" src="/img/how-to-use/notification-preferences.png"/>

							<p className="mt-2">
								To change the default notification preferences, select the "Change default for new
								channels" option. Then select your default notification preference and click the "Save"
								button.
								Now, every new channel you join will use this default notification preference.
							</p>
							<img className="my-4" src="/img/how-to-use/default-notificaiton-preferences.png"/>
						</div>
						<div className="mt-8" id="thread-notifications">
							<h2 className="text-2xl">Mute thread notifications</h2>
							<p className="mt-2">
								Notifications for a thread can be muted by clicking the bell icon in the top right,
								while in a message thread. Click it once to mute the thread, and click it again to
								unmute the thread.
							</p>
							<img className="my-4" src="/img/how-to-use/thread-notification.png"/>
						</div>
						<div className="mt-8" id="leave-channel">
							<h2 className="text-2xl">Leave a channel</h2>
							<p className="mt-2">
								To leave a channel, right click on the channel, then click "Leave Channel".
							</p>
							<img className="my-4" src="/img/how-to-use/leave-channel.png"/>
						</div>
						<div className="mt-8" id="add-members">
							<h2 className="text-2xl">Adding members to a channel</h2>
							<p className="mt-2">
								1. To add members to a channel, click the "Add Members" button on the channel.
							</p>
							<img className="my-4" src="/img/how-to-use/add-members1.png"/>
							<p className="mt-2">
								2. Then, search and select the users to add to the channel. Finally, click the "Add"
								button.
							</p>
							<img className="my-4" src="/img/how-to-use/add-members2.png"/>
						</div>
						<div className="mt-8" id="reactions">
							<h2 className="text-2xl">Reacting to a message</h2>
							<p className="mt-2">
								To react to a message with an emoji, hover over the message and click the smiley face button.
							</p>
							<img className="my-4" src="/img/how-to-use/reaction.png"/>
							<p className="mt-2">
								This will bring up an emoticon selector. Select an emoticon.
							</p>
							<img className="my-4" src="/img/how-to-use/reaction2.png"/>
							<p className="mt-2">
								Once you've selected an emoticon, it will be displayed as a reaction to the message.
							</p>
							<img className="my-4" src="/img/how-to-use/reaction3.png"/>
							<p className="mt-2">
								To remove a reaction, just click on the reaction you've added. You can also add your
								reaction to a message that already has reactions by clicking those reactions. This will increment the number of reactions.
							</p>
						</div>
						<div className="mt-8" id="threads">
							<h2 className="text-2xl">Replying to a message by using threads</h2>
							<p className="mt-2">
								To reply to a message, hover over the message and click the reply button.
							</p>
							<img className="my-4" src="/img/how-to-use/reply1.png"/>
							<p className="mt-2">
								This will open the thread view.
							</p>
							<img className="my-4" src="/img/how-to-use/reply2.png"/>
							<p className="mt-2">
								Type your reply in the text box and click the enter button or the send button to reply
								to the message.
							</p>
							<img className="my-4" src="/img/how-to-use/reply3.png"/>
							<p className="mt-2">
								To return back to the main chat view, click the back button in the top left of the
								thread.
							</p>
							<img className="my-4" src="/img/how-to-use/thread-back-button.png"/>
							<p className="mt-2">
								Threads have their own notification preferences. To mute the thread, just click the bell
								icon in the top right to no longer receive notifications for the thread.
							</p>
						</div>
						<div className="mt-8 pb-10" id="support">
							<h2 className="text-2xl">Additional support</h2>
							<p className="mt-2">
								If you need additional support, please reach out to our support team at
								support@bonfireapps.com.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}