import {Dropdown} from "monday-ui-react-core";
import {Board, Forum} from "monday-ui-react-core/icons";
import * as React from "react";
import {useContext, useState} from "react";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import {useFirestore, useFirestoreCollectionData} from "reactfire";
import {AuthContext} from "../context/AuthContext.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {ChannelTypeEnum} from "../types/FirestoreCollections.type.ts";
import {generateUserId} from '../firebase/userIdGenerator.ts';

interface ChannelDropdownOption {
    label: string
    id: string
    type: ChannelTypeEnum
    boardWorkspaceName: string
    doesChannelAlreadyExist: boolean
    channelId?: string
    mondayRecordId?: string
    isUserCurrentlyAMember?: boolean
    leftAvatar?: string
    leftIcon?: React.SVGAttributes<SVGElement>
    channelMemberCount?: number
}

export default function ChannelSearch() {
    const firestore = useFirestore();
    const authContext = useContext(AuthContext);
    const channelsSnapshot = firestoreRepo(firestore, authContext).getAllChannels(useFirestoreCollectionData);

    const isChannelsSnapshotLoading = channelsSnapshot.status === 'loading';
    const usersContext = useContext(MondayUsersContext);
    const [selectedChannel, setSelectedChannel] = useState<ChannelDropdownOption | null>(null);
    const [isCreatingChannel, setIsCreatingChannel] = useState(false);

    /**
     * Adds a selected channel.
     * If the channel does not exist, it is created.
     * If the channel does exist, the user is added to it as a member
     *
     * @param channel
     */
    const addChannel = (channel: ChannelDropdownOption | null) => {
        if (!channel) {
            return;
        }
        setSelectedChannel(channel);
        setIsCreatingChannel(true);

        // If the user is selecting a channel that already exists, add that user to the channel
        if (channel?.doesChannelAlreadyExist && channel.channelId && authContext.firebaseUserId) {
            firestoreRepo(firestore, authContext).addMemberToChannel(channel.channelId, authContext.firebaseUserId).then(() => {
                setIsCreatingChannel(false);
                setSelectedChannel(null);
                if (channel.channelId) {
                    firestoreRepo(firestore, authContext).setCurrentlyViewingChannel(channel.channelId).catch((e) => console.log(e));
                }
            }).catch((e) => console.log(e));
        }

        // If the user is selecting a channel that doesn't exist, create that channel and add that user to the channel
        if (channel?.label && !channel.doesChannelAlreadyExist) {
            firestoreRepo(firestore, authContext).createChannel(channel.label, channel.type, channel.mondayRecordId).then((res) => {
                // Set the current channel to the newly created one
                firestoreRepo(firestore, authContext).setCurrentlyViewingChannel(res.id).then(() => {
                        setIsCreatingChannel(false);
                        setSelectedChannel(null);
                    }
                ).catch((e) => console.log(e));
            }).catch((e) => console.log(e));
        }
    }

    const channelOptionsObject = {};
    // Used to create a unique ID across all channel types and IDs to make sure we don't duplicate a channel
    const channelOptionSeparator = '&&&&&';

    // Add channels from the database
    if (!isChannelsSnapshotLoading && usersContext) {
        const channels = channelsSnapshot.data;
        for (const channel of channels) {
            // For person channels, use the ID of the other user instead of the mondayRecordId
            if (channel.type === ChannelTypeEnum.Person) {
                // Don't add Person channels where the current user is not a part of the personChannelUsersIds
                if (!channel.personChannelUserIds.includes(authContext.firebaseUserId)) {
                    continue;
                }
                
                const otherPersonUserId = channel.personChannelUserIds.find((userId) => userId !== authContext.firebaseUserId);
                if (otherPersonUserId) {
                    channel.mondayRecordId = otherPersonUserId;
                    channel.pictureUrl = usersContext[otherPersonUserId]?.photo_small ?? null;
                }
            }

            // For custom channels, use the ID of the channel instead of the mondayRecordId
            // This is just that the ID key is unique
            if (channel.type === ChannelTypeEnum.Custom) {
                channel.mondayRecordId = channel.id;
            }

            const id = channel.type + channelOptionSeparator + channel.mondayRecordId; // boardId, teamId, or null if not a monday record but a custom channel or it's a person channel

            let channelName = channel.name;

            // If it's a person channel, make sure to set the channel name to the name of the other person
            if (channel.type === ChannelTypeEnum.Person) {
                const otherPersonUserId = channel.personChannelUserIds.find((userId) => userId !== authContext.firebaseUserId);
                if (otherPersonUserId) {
                    const user = usersContext[otherPersonUserId];
                    channelName = user?.name;
                }
            }

            channelOptionsObject[id] = {
                label: channelName,
                // Used for deduplication to filter out non-persisted channels
                id: id,
                type: channel.type,
                doesChannelAlreadyExist: true,
                mondayRecordId: channel.mondayRecordId,
                channelMemberCount: channel.memberIds.length,
                channelId: channel.id,
                isUserCurrentlyAMember: channel.memberIds.includes(authContext.firebaseUserId),
                leftAvatar: channel.type === ChannelTypeEnum.Custom ? null : channel.pictureUrl,
                leftIcon: [ChannelTypeEnum.Custom, ChannelTypeEnum.General].includes(channel.type) ? Forum : channel.type === ChannelTypeEnum.Board ? Board : null,
            }
        }
    }

    // Add users as direct message channel options
    for (const mondayUserId in usersContext) {
        // Don't add the current user as a channel option
        if (mondayUserId === authContext.firebaseUserId) {
            continue;
        }

        const user = usersContext[mondayUserId];
        const id = ChannelTypeEnum.Person + channelOptionSeparator + generateUserId(authContext.accountId, user.id);

        // Don't overwrite persisted channels
        if (channelOptionsObject[id]) {
            continue;
        }

        channelOptionsObject[id] = {
            label: user.name,
            id: id,
            type: ChannelTypeEnum.Person,
            mondayRecordId: user.id,
            doesChannelAlreadyExist: false,
            leftAvatar: user.photo_small,
        }
    }

    const channelOptions = Object.values(channelOptionsObject)
        // Remove channels from search that the user is already a part of
        .filter((channelOption: ChannelDropdownOption) => channelOption.isUserCurrentlyAMember !== true)
        .map((channelOption: ChannelDropdownOption) => {
            let channelMembers = '';
            if (channelOption.channelMemberCount) {
                channelMembers = channelOption.channelMemberCount > 1 ? `(${channelOption.channelMemberCount} members)` : `(1 member)`;
            }
            return {
                label: channelOption.label,
                id: channelOption.id,
                value: channelOption.id,
                mondayRecordId: channelOption.mondayRecordId,
                doesChannelAlreadyExist: channelOption.doesChannelAlreadyExist,
                type: channelOption.type,
                boardWorkspaceName: channelOption.boardWorkspaceName,
                channelId: channelOption.channelId,
                leftAvatar: channelOption.leftAvatar,
                leftIcon: channelOption.leftIcon,
                tooltipProps: {
                    content: `${channelOption.label} ${channelMembers}`
                }
            };
    });

    // Sort channel options alphabetically by label
    channelOptions.sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        }

        if (a.label > b.label) {
            return 1;
        }

        return 0;
    });

    const optionRenderer = (option: ChannelDropdownOption) => {
        return (
            <div className="flex flex-row items-center p-0">
                {option.leftAvatar && (
                    <img className="w-5 h-5 rounded-full" src={option.leftAvatar} />
                )}
                {option.leftIcon && (
                    <div className="w-5 h-5">
                        {/*@ts-expect-error This is fine*/}
                        <option.leftIcon />
                    </div>
                )}
                <div className={"ml-2 flex flex-col"}>
                    <div className="text-ellipsis font-medium overflow-hidden">{option.label}</div>
                    {option.type === ChannelTypeEnum.Board && (
                        <div className="text-ellipsis font-light text-xs">{option.boardWorkspaceName}</div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div data-testid="channel-search-dropdown">
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
            <Dropdown size={Dropdown.sizes.SMALL}
                placeholder="Find channels & users to add"
                options={channelOptions}
                isVirtualized={true}
                className="z-20" // Need to use a z-index of 20 to make sure the dropdown is above the person channel status indicator
                value={selectedChannel}
                tooltipContent="Search for a channel, person, board, or team to add it as a channel."
                optionRenderer={optionRenderer}
                disabled={isChannelsSnapshotLoading || isCreatingChannel}
                isLoading={isChannelsSnapshotLoading || isCreatingChannel}
                onChange={(channel: ChannelDropdownOption) => addChannel(channel)}
            />
        </div>
    )
}