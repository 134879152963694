import {Skeleton} from "monday-ui-react-core";

export default function MessagesSkeleton() {
	return (
		<div className="overflow-auto">
			<div className="flex">
				<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
				<div className="w-full mb-3">
					<Skeleton className="m-2 ml-2.5" height={50} fullWidth type={Skeleton.types.RECTANGLE}/>
				</div>
			</div>
			<div className="flex">
				<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
				<div className="w-full mb-3">
					<Skeleton className="m-2 ml-2.5" height={50} fullWidth type={Skeleton.types.RECTANGLE}/>
					<Skeleton className="ml-2.5" height={40} fullWidth type={Skeleton.types.RECTANGLE}/>
				</div>
			</div>
			<div className="flex">
				<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
				<div className="w-full mb-3">
					<Skeleton className="m-2 ml-2.5" height={50} fullWidth type={Skeleton.types.RECTANGLE}/>
				</div>
			</div>
			<div className="flex">
				<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
				<div className="w-full mb-3">
					<Skeleton className="m-2 ml-2.5" height={50} fullWidth type={Skeleton.types.RECTANGLE}/>
					<Skeleton className="ml-2.5" height={40} fullWidth type={Skeleton.types.RECTANGLE}/>
				</div>
			</div>
			<div className="flex">
				<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
				<div className="w-full mb-3">
					<Skeleton className="m-2 ml-2.5" height={70} fullWidth type={Skeleton.types.RECTANGLE}/>
				</div>
			</div>
			<div className="flex">
				<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
				<div className="w-full mb-3">
					<Skeleton className="m-2 ml-2.5" height={50} fullWidth type={Skeleton.types.RECTANGLE}/>
				</div>
			</div>
			<div className="flex">
				<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
				<div className="w-full mb-3">
					<Skeleton className="m-2 ml-2.5" height={50} fullWidth type={Skeleton.types.RECTANGLE}/>
					<Skeleton className="ml-2.5" height={80} fullWidth type={Skeleton.types.RECTANGLE}/>
				</div>
			</div>
			<div className="flex">
				<Skeleton className="ml-3 mt-2" height={38} width={38} type={Skeleton.types.CIRCLE}/>
				<div className="w-full mb-3">
					<Skeleton className="m-2 ml-2.5" height={50} fullWidth type={Skeleton.types.RECTANGLE}/>
				</div>
			</div>
		</div>
	);
}