import {getAuth, signInWithCustomToken} from "firebase/auth";
// import {getFunctions, httpsCallable} from "firebase/functions";
import {getSessionToken} from "../monday/MondayApi.ts";
import {MondayViewerException} from "../exceptions/MondayViewerException.ts";

export const authenticate = async () => {
	const token = await getSessionToken();
	const mondayJwtToken = token.data;

	try {
		const config = {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': mondayJwtToken
			},
			body: JSON.stringify({}),
		}
		let authDomain = 'https://chatapp.bonfireapps.com/functions/authenticate';
		if (document.location.hostname === 'plugingenie.ngrok.io' || document.location.hostname === 'localhost'){
			authDomain = 'http://127.0.0.1:5001/chat-f3d6e/us-central1/authenticate';
		}
		const response = await fetch(authDomain, config)
		const authenticationResult = await response.json() as { firebaseToken: string, accountId: string, userId: string, error: string};

		const isViewer = authenticationResult.error === "Viewers can't authenticate";
		if (isViewer) {
			console.log('Viewers can\'t authenticate');
			throw new MondayViewerException("Viewers can't authenticate");
		}

		const {firebaseToken, accountId, userId} = authenticationResult;
		const auth = getAuth();
		const userCredential = await signInWithCustomToken(auth, firebaseToken);

		// Return the signed in user
		return {firebaseUserId: userCredential.user.uid, accountId, userId};

	} catch (error) {
		if (error instanceof MondayViewerException) {
			throw error;
		}
		console.error('Error:', error);
	}

	throw new Error('Authentication failed');
}
