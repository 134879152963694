import {ChannelMessageType} from "../types/FirestoreCollections.type.ts";
import MessageActions from "./MessageActions.tsx";
import {linkify} from "../helpers/linkHelper.ts";
import SanitizeHTML from "./SanitizeHTML.tsx";

interface MessageTextProps {
    message: ChannelMessageType;
    setMessageForThread: ((message: ChannelMessageType | null) => void) | null;
    parentMessageId?: string;
    isThreadContext?: boolean;
}

export default function MessageText({message, setMessageForThread, parentMessageId, isThreadContext} : MessageTextProps) {
    // Parse out the @ mentions from the message text
    const messageText = message.messageText;
    const messageTextParts = messageText.split(/(@\[[^\]]+\]\([^)]+\))/g);
    const messageTextPartsParsed = messageTextParts.map((messageTextPart) => {
        if (messageTextPart.startsWith('@[')) {
            const mentionText = messageTextPart.substring(2, messageTextPart.indexOf(']('));
            const mentionId = messageTextPart.substring(messageTextPart.indexOf('](') + 2, messageTextPart.indexOf(')'));
            return {
                mentionText,
                mentionId
            }
        }
        return messageTextPart;
    });

    // If the message contains the URL https://giphy.com/embed/ then it's a gif
    if (message.messageText.includes('https://giphy.com/embed/')) {
        return (
            <div
                className="text-primary-text-color font-light p-1 hover:bg-primary-background-hover-color group rounded whitespace-pre-wrap">
                <div key={message.id} className="w-full p-1">
                    <iframe src={message.messageText}/>
                </div>
                <MessageActions message={message} setMessageForThread={setMessageForThread} parentMessageId={parentMessageId} isThreadContext={isThreadContext}/>
            </div>
        );
    }

    return (
        <div data-testid="message-text" className="text-primary-text-color font-light p-1 hover:bg-primary-background-hover-color group rounded whitespace-pre-wrap">
            {messageTextPartsParsed.map((messageTextPart, index) => {
                if (typeof messageTextPart === 'string') {
                    return (
                        <span key={index}>
                            <SanitizeHTML html={linkify(messageTextPart)}/>
                        </span>
                    )
                } else {
                    return (
                        <span key={index} className="rounded bg-primary-selected-hover-color px-1 text-primary-text-color">@{messageTextPart.mentionText}</span>
                    )
                }
            })}
            {/*{message.messageText}*/}
            <MessageActions message={message} setMessageForThread={setMessageForThread} parentMessageId={parentMessageId} isThreadContext={isThreadContext}/>
        </div>
    );
}

MessageText.defaultProps = {
    setMessageForThread: null,
    isThreadContext: false
}