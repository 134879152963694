import {
    ChannelMessageType,
    ChannelType, ChannelTypeEnum,
    NotificationPreferenceEnum,
    UserType
} from "../types/FirestoreCollections.type.ts";
import {IconButton} from "monday-ui-react-core";
import {Notifications, NotificationsMuted, NavigationChevronLeft} from "monday-ui-react-core/icons";
import MessagesInputContainer from "./MessagesInputContainer.tsx";
import MessageText from "./MessageText.tsx";
import Message from "./Message.tsx";
import Messages from "./Messages.tsx";
import {useContext, useEffect, useState} from "react";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import {useFirestore, useFirestoreCollectionData, useFirestoreDocData} from "reactfire";
import {AuthContext} from "../context/AuthContext.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {sendMondayNotificationsToThreadUsers} from "../monday/MondayNotifications.ts";
import ChannelTypingIndicator from "./ChannelTypingIndicator.tsx";

export default function ThreadMessageContainer({parentMessage, currentChannel, currentUser, setMessageForThread} : {
    parentMessage: ChannelMessageType,
    currentChannel: ChannelType,
    currentUser: UserType,
    setMessageForThread: ((message: ChannelMessageType | null) => void);
}) {
    const mondayUsersContext = useContext(MondayUsersContext);
    const firestore = useFirestore();
    const authContext = useContext(AuthContext);
    const threadMessagesSnapshot = firestoreRepo(firestore, authContext).getThreadMessages(useFirestoreCollectionData, parentMessage.channelId, parentMessage.id);
    const parentMessageCreatorUser = firestoreRepo(firestore, authContext).getUserById(useFirestoreDocData, parentMessage.createdBy);
    const usersToNotifyViewingBoardViewsSnapshot = firestoreRepo(firestore, authContext).getUsersToNotifyUsingBoardView(useFirestoreCollectionData, (currentChannel.type === ChannelTypeEnum.Board ? currentChannel.mondayRecordId : null), currentChannel.id);
    const usersToNotifySnapshot = firestoreRepo(firestore, authContext).getUsersToNotify(useFirestoreCollectionData, currentChannel.id);

    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
    const [lastLoadedMessageCreatedAt, setLastLoadedMessageCreatedAt] = useState<number | null>(null);

    const lastCurrentMessageCreatedAt = threadMessagesSnapshot.data?.[0]?.createdAt?.toMillis() ?? threadMessagesSnapshot.data?.[0]?.localTimeStamp ?? null;

    const onScrollToBottom = () => {
        setShouldScrollToBottom(false);
    }

    // If the last message in the thread changes, scroll to the bottom
    useEffect(() => {
        if (lastLoadedMessageCreatedAt !== null && lastLoadedMessageCreatedAt !== lastCurrentMessageCreatedAt) {
            setShouldScrollToBottom(true);
        }
        setLastLoadedMessageCreatedAt(lastCurrentMessageCreatedAt ?? null);
    }, [lastCurrentMessageCreatedAt, threadMessagesSnapshot, lastLoadedMessageCreatedAt]);

    const onCreateMessage = (message: string) => {
        firestoreRepo(firestore, authContext).createThreadMessage(parentMessage, message).then((updatedParentMessage) => {
            const usersInThread = parentMessage.threadReplies ? Object.keys(parentMessage.threadReplies) : [];

            // Add the parent message user to the list of users in the thread if they're not already in the array
            if (!usersInThread.includes(parentMessage.createdBy)) {
                usersInThread.push(parentMessage.createdBy);
            }

            firestoreRepo(firestore, authContext).incrementTotalMissedThreadMessages(currentChannel.id, usersInThread).catch((e) => console.log(e));
            if (usersToNotifySnapshot.status === 'success' && parentMessageCreatorUser.status === 'success' && parentMessageCreatorUser.data && usersToNotifyViewingBoardViewsSnapshot.status === 'success') {
                const combinedUsersToNotify = usersToNotifySnapshot.data?.concat(usersToNotifyViewingBoardViewsSnapshot.data);
                // Send a notification to the people in the thread, along with the original person who created the parent message
                if (combinedUsersToNotify.length && updatedParentMessage?.notificationPreferences) {
                    sendMondayNotificationsToThreadUsers(firestore, authContext, updatedParentMessage.notificationPreferences, message, mondayUsersContext, combinedUsersToNotify, parentMessage.channelId).catch((e) => console.log(e));
                }
            }
        }).catch((e) => console.log(e));
    }

    const toggleNotificationPreference = () => {
        // const newNotificationPreference = parentMessage?.notificationPreferences?.[currentUser?.firebaseUserId] === NotificationPreferenceEnum.None ? NotificationPreferenceEnum.All : NotificationPreferenceEnum.None;
        firestoreRepo(firestore, authContext).toggleThreadNotificationPreference(parentMessage, currentUser.firebaseUserId).catch((e) => console.log(e));
    }

    // Notifications are only shown as enabled if the user has the notification preference set to All.
    // Thread notifications have a bit of a different behavior than regular channel notifications.
    // By default, when a thread is muted, the notification preference is actually set to "mentions".
    const notificationIcon = parentMessage?.notificationPreferences?.[currentUser?.firebaseUserId] === NotificationPreferenceEnum.Mentions ? NotificationsMuted : Notifications;
    return (
        <div className="flex flex-col w-full bg-secondary-background-color h-full">
            {/*Messages Top Menu*/}
            <div className="bg-primary-background-color flex flex-row justify-between items-center p-1">
                <IconButton className="" size={IconButton.sizes.SMALL} kind={IconButton.kinds.TERTIARY} icon={NavigationChevronLeft} onClick={() => setMessageForThread(null)}/>
                <div className="text-primary-text-color font-medium">Thread</div>
                <IconButton data-testid={"thread-notification-preference-button"} className="mr-1.5" size={IconButton.sizes.SMALL} kind={IconButton.kinds.SECONDARY} icon={notificationIcon} onClick={toggleNotificationPreference}/>
            </div>
            {/*Messages*/}
            <Messages messagesSnapshot={threadMessagesSnapshot} parentMessage={parentMessage} isThreadContext={true} shouldScrollToBottom={shouldScrollToBottom} onScrollToBottom={onScrollToBottom}>
                <div className="border-b border-ui-border-color bg-primary-background-color pb-3 mb-3">
                    {parentMessage.createdAt && (
                        <Message firstMessageInGroup={parentMessage} isThreadContext={true}>
                            <MessageText key={parentMessage.id} message={parentMessage} isThreadContext={true}/>
                        </Message>
                    )}
                </div>
            </Messages>
            {/*Typing Indicator*/}
            <ChannelTypingIndicator currentChannel={currentChannel} />

            {/*Message Input Container*/}
            <MessagesInputContainer currentUser={currentUser} currentChannel={currentChannel} onCreateMessage={onCreateMessage}/>
        </div>
    )
}