import {
	ChannelType,
	NotificationPreferenceEnum,
	UserType
} from "../types/FirestoreCollections.type.ts";
import {useContext} from "react";
import {AuthContext} from "../context/AuthContext.ts";
import {useFirestore, useFirestoreDocData} from "reactfire";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import Channel from "./Channel.tsx";

export default function PersonChannel({channel, currentUser, currentlyActiveChannel, notificationPreference, setCurrentlyViewingChannel}: {
	channel: ChannelType,
	currentUser: UserType,
	currentlyActiveChannel: string | null,
	notificationPreference: NotificationPreferenceEnum | null,
	setCurrentlyViewingChannel: (channelId: string) => void,
}) {
	const authContext = useContext(AuthContext);
	const firestore = useFirestore();

	let isPersonChannelActive = false;
	// I know this isn't a valid user ID, but it's just a placeholder. We can't conditionally render the hook,
	// so we need to pass in a "valid" user ID.
	const otherPersonId = channel.personChannelUserIds.filter((id) => id !== authContext.firebaseUserId)[0];

	try {
		const personChannelUserSnapshot = firestoreRepo(firestore, authContext).getUserById(useFirestoreDocData, otherPersonId);

		if (personChannelUserSnapshot.status === 'loading') {
			return <div></div>;
		}
		if (personChannelUserSnapshot.data) {
			isPersonChannelActive = personChannelUserSnapshot.data?.isOnline ?? false;
		}

		return (
			<Channel channel={channel} currentUser={currentUser} currentlyActiveChannel={currentlyActiveChannel} notificationPreference={notificationPreference} isPersonChannelActive={isPersonChannelActive} setCurrentlyViewingChannel={setCurrentlyViewingChannel}/>
		);
	} catch (e) {
		console.log(e);
		return <div></div>;
	}
}