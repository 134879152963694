import {useContext} from "react";
import {AccountContext} from "../context/AccountContext.ts";
export default function AutomationMondayNavigation({boardName, boardId, itemId = null} : {boardName: string, boardId: string, itemId?: string | null}) {
	const accountContext = useContext(AccountContext);
	const slug = accountContext.accountSlug;
	if (!slug) {
		return;
	}

	const boardUrl = boardId ? `https://${slug}.monday.com/boards/${boardId}` : null;
	const itemUrl = itemId ? `https://${slug}.monday.com/boards/${boardId}/pulses/${itemId}` : null;
	const url = itemUrl ?? boardUrl;

	return (
		<>
			<span>Board Automation </span>
			{url && (
				<a href={url} target="_blank" rel="noreferrer" className="text-link-color text-sm">{boardName}</a>
			)}

			{!url && (
				<span className="text-secondary-text-color text-sm">{boardName}</span>
			)}
		</>
	)
}