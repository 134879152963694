import DOMPurify from 'dompurify';

const defaultOptions = {
	ALLOWED_TAGS: ['a'],
	ALLOWED_ATTR: ['href', 'target', 'rel', 'class']
};

const sanitize = (dirty : string) => ({
	__html: DOMPurify.sanitize(
		dirty,
		{ ...defaultOptions}
	)
});
export default function SanitizeHTML({html} : {html: string}) {
	return (
		<span dangerouslySetInnerHTML={sanitize(html)} />
	);
}