import firestoreRepo from "../firebase/firestoreRepo.ts";
import {Firestore} from "firebase/firestore";
import {AuthContextProps} from "../context/AuthContext.ts";
import {ChannelType, NotificationPreferenceEnum, UserType} from "../types/FirestoreCollections.type.ts";
import {MondayApiUser} from "./MondayUsers.ts";
import {getIdsFromMessageMentions} from "../managers/mentionManager.ts";
import {createNotification, getContext} from "./MondayApi.ts";

export async function sendMondayNotificationsToUsers(firestore: Firestore, authContext: AuthContextProps, mondayUsersContext: Record<string, MondayApiUser>, currentChannel: ChannelType, users: UserType[], message: string) {
	const mentionedUserIds = getIdsFromMessageMentions(message);
	const channelMention = mentionedUserIds.includes('channel');

	for (const user of users) {
		// Don't notify the user who sent the message.
		if (user.firebaseUserId === authContext.firebaseUserId) {
			continue;
		}

		const notificationPreference = user.notificationPreferences?.[currentChannel.id];
		// If the user doesn't want to get push notifications for this channel, skip them.
		if (notificationPreference === NotificationPreferenceEnum.None) {
			continue;
		}

		const doesMessageMentionUser = (() => {
			if (message.includes(`@[${mondayUsersContext[user.firebaseUserId]?.name}](${user.firebaseUserId})`)) {
				return true;
			}

			return channelMention;
		})();

		// If the user only wants to get push notifications for mentions and the message doesn't mention them, skip them.
		if (notificationPreference === NotificationPreferenceEnum.Mentions && !doesMessageMentionUser) {
			continue;
		}

		// If a notification has already been sent, and they only want one notification, skip them.
		if (notificationPreference === NotificationPreferenceEnum.Single && user?.channelsWhereNotificationWasSent?.[currentChannel.id]) {
			continue;
		}

		await sendMondayNotificationToUser(user.userId, notificationPreference, message, currentChannel.name).then(() => {
			// Show the notification preferences tooltip if the user hasn't seen it yet, and they just received a message.
			// Don't show it if they haven't completed the onboarding.
			if (!user.disableShowNotificationPreferencesTooltip &&
				!user.shouldShowNotificationPreferencesTooltip &&
				user.currentOnboardingStep !== undefined
			) {
				// Check to see if the user has ever set their notification preferences to something other than the default.
				// If so, then don't show the tooltip, since that means they already know where to disable notifications.
				const hasEverSetNotificationPreferences = Object.values(user.notificationPreferences).some((pref) => pref !== (user.defaultNotificationPreference ?? NotificationPreferenceEnum.All));
				if (!hasEverSetNotificationPreferences) {
					firestoreRepo(firestore, authContext).setShouldShowNotificationPreferencesTooltip(user.firebaseUserId, true).catch((e) => console.log(e));
				}
			}

			// Only set this if it isn't already set to prevent too many update queries.
			if (!user?.channelsWhereNotificationWasSent?.[currentChannel.id]) {
				firestoreRepo(firestore, authContext).setHasNotificationBeenSent(user, currentChannel.id, true).catch((e) => console.log(e));
			}
			
			// Update the last channel the user got notified for, so we can send them back to the right channel when they open the app.
			if (user.channelToGoToAfterAppOpen !== currentChannel.id) {
				firestoreRepo(firestore, authContext).setChannelToGoToAfterAppOpen(user.firebaseUserId, currentChannel.id).catch((e) => console.log(e));
			}
		}).catch((e) => console.log(e));
	}
}

export async function sendMondayNotificationsToThreadUsers(firestore: Firestore, authContext: AuthContextProps, threadNotificationPreferences: Record<string, NotificationPreferenceEnum>, message: string, mondayUsersContext: Record<string, MondayApiUser>, usersInChannelToNotify: UserType[], channelId: string) {
	const mentionedUserIds = getIdsFromMessageMentions(message);
	const channelMention = mentionedUserIds.includes('channel');

	for (const user of usersInChannelToNotify) {
		const notificationPreference = threadNotificationPreferences[user.firebaseUserId];
		if (notificationPreference === undefined) {
			continue;
		}

		// If the user doesn't want to get push notifications for this channel, skip them.
		if (notificationPreference === NotificationPreferenceEnum.None) {
			continue;
		}

		const doesMessageMentionUser = (() => {
			if (message.includes(`@[${mondayUsersContext[user.firebaseUserId].name}](${user.firebaseUserId})`)) {
				return true;
			}

			return channelMention;
		})();

		// If the user only wants to get push notifications for mentions and the message doesn't mention them, skip them.
		if (notificationPreference === NotificationPreferenceEnum.Mentions && !doesMessageMentionUser) {
			continue;
		}

		// Prepend the message with "Thread reply: " so the user knows it's a thread reply.
		message = "Thread reply: " + message;

		await sendMondayNotificationToUser(user.userId, notificationPreference, message).catch((e) => console.log(e));

		// Update the last channel the user got notified for, so we can send them back to the right channel when they open the app.
		if (user.channelToGoToAfterAppOpen !== channelId) {
			firestoreRepo(firestore, authContext).setChannelToGoToAfterAppOpen(user.firebaseUserId, channelId).catch((e) => console.log(e));
		}
	}
}

async function sendMondayNotificationToUser(userId: string, notificationPreference: NotificationPreferenceEnum, message: string, channelName?: string) {
	const mondayContext = await getContext() as { data: { boardId: string } }

	if (mondayContext === null) {
		return;
	}

	const boardId = mondayContext?.data?.boardId;
	if (!boardId) {
		return;
	}

	let channelNameText = "";
	if (channelName) {
		channelNameText = `${channelName}: `;
	}

	let text = channelNameText + "You have one or more new chat messages.";


	// If the user wants to get push notifications for all messages, send the actual message.
	// If the user wants to get push notifications for mentions, send the actual message.
	if ([NotificationPreferenceEnum.All, NotificationPreferenceEnum.Mentions].includes(notificationPreference)) {
		// Filter out the mentions from the message, but keep the name of the user. Mentions look like this: @[John Doe](1234&&&&&56789).
		// What should be left is the "John Doe" part.
		message = message.replace(/@\[([^\]]+)\]\(\d+&&&&&\d+\)/g, "@$1");

		// Also replace channel mentions.
		message = message.replace(/@\[channel\]\(channel\)/g, "@channel");

		text = message;
	}

	await createNotification(userId, boardId, text);
}