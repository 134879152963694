import {
    Avatar,
    Button,
    Dialog,
    DialogContentContainer,
    Dropdown,
    Icon,
    IconButton,
    Modal,
    ModalContent, ModalFooterButtons, ModalHeader
} from "monday-ui-react-core";
import {AddSmall, Notifications, NotificationsMuted, Settings} from "monday-ui-react-core/icons";
import {
    ChannelType,
    ChannelTypeEnum,
    NotificationPreferenceEnum,
    UserType
} from "../types/FirestoreCollections.type.ts";
import React, {SetStateAction, useCallback, useContext, useState} from "react";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {useFirestore} from "reactfire";
import {AuthContext} from "../context/AuthContext.ts";
import MondayChannelAvatar from "./MondayChannelAvatar.tsx";
import MondayUserAvatar from "./MondayUserAvatar.tsx";
import Onboarding from "./Onboarding.tsx";
import ChannelNameSkeleton from "./skeletons/ChannelNameSkeleton.tsx";
import NotificationPreferencesTipseen from "./NotificationPreferencesTipseen.tsx";

export default function MessagesTopMenu({currentChannel, currentUser}: { currentChannel: ChannelType, currentUser: UserType}) {
    const mondayUsersContext = useContext(MondayUsersContext);
    const firestore = useFirestore();
    const authContext = useContext(AuthContext);
    const [showAddMembersModal, setShowAddMembersModal] = useState(false);
    const [showMembersModal, setShowMembersModal] = useState(false);
    const [showDefaultNotificationPreferenceModal, setShowDefaultNotificationPreferenceModal] = useState(false);
    const [searchValue, setSearchValue] = useState<{label: string, leftAvatar: string, value: string}[]>([]);
    const [currentNotificationPreference, setCurrentNotificationPreference] = useState<NotificationPreferenceEnum>(NotificationPreferenceEnum.All);
    const [defaultNotificationPreference, setDefaultNotificationPreference] = useState<NotificationPreferenceEnum>(currentUser.defaultNotificationPreference ?? NotificationPreferenceEnum.All);
    const [showMinimizedNotificationPreferences, setShowMinimizedNotificationPreferences] = useState(true);

    firestoreRepo(firestore, authContext).getNotificationPreference(currentChannel.id).then((notificationPreference) => {
       if (notificationPreference) {
           setCurrentNotificationPreference(notificationPreference as NotificationPreferenceEnum);
       }
    }).catch((e) => console.log(e));


    const totalMembers = currentChannel.memberIds.length;

    const updateChannelNotificationPreference = (notificationPreference: NotificationPreferenceEnum) => {
        firestoreRepo(firestore, authContext).setChannelNotificationPreference(currentChannel.id, notificationPreference).catch((e) => console.log(e));
    }

    const closeAddMembersModal = useCallback(() => {
        setShowAddMembersModal(false);
        setSearchValue([]);
    }, [setShowAddMembersModal]);

    const addMembers = (e:  React.SyntheticEvent | null) => {
        if (e) e.preventDefault();

        if (searchValue.length === 0) {
            return;
        }

        // Deduplicate members from current channel members and the search value
        const userIdsInSearchBox = searchValue.map((option) => option.value);
        const addMembersToChannel = async () => {
            for (const userId of userIdsInSearchBox) {
                await firestoreRepo(firestore, authContext).addMemberToChannel(currentChannel.id, userId).catch((e) => console.log(e));
            }
        }
        addMembersToChannel().catch((e) => console.log(e));

        // Clear the search box
        setSearchValue([]);
    }

    const members = currentChannel.memberIds.map((memberId) => {
        return mondayUsersContext[memberId];
    }).filter((member) => member !== undefined);

    const userSearchOptions = Object.values(mondayUsersContext).map((user) => {
        return {
            value: user.userId,
            label: user.name,
            leftAvatar: user.photo_small
        }
    });

    const saveDefaultNotificationPreference = () => {
        firestoreRepo(firestore, authContext).setUserDefaultNotificationPreference(defaultNotificationPreference).catch((e) => console.log(e));
        setShowDefaultNotificationPreferenceModal(false);
    }

    const notificationDefaultOptions = [
        {label: 'All messages - Get a notification for every missed message', value: NotificationPreferenceEnum.All},
        {label: 'Single notification - Get only one notification for one or more missed messages', value: NotificationPreferenceEnum.Single},
        {label: 'Only @ mentions - Get a notification only when people mention you', value: NotificationPreferenceEnum.Mentions},
        {label: 'None - No channel notifications', value: NotificationPreferenceEnum.None}
    ];

    const notificationDefaultOptionsMinimized = [
        {label: 'All messages', value: NotificationPreferenceEnum.All},
        {label: 'Single notification', value: NotificationPreferenceEnum.Single},
        {label: 'Only @ mentions', value: NotificationPreferenceEnum.Mentions},
        {label: 'None', value: NotificationPreferenceEnum.None}
    ];

    const getNotificationOption = (notificationPreference: NotificationPreferenceEnum) => {
        return notificationDefaultOptionsMinimized.find((option) => option.value === notificationPreference);
    }

    // If it's a person channel, show the "Direct Message" text, otherwise show the member count
    const memberText = currentChannel.type === ChannelTypeEnum.Person ? 'Direct Message' : `${totalMembers} member${totalMembers > 1 ? 's' : ''}`;

    return (
        <div
            className="flex flex-row justify-between items-center bg-primary-background-color p-2 border-b border-ui-border-color">
            <div className="flex flex-row items-center">
                <MondayChannelAvatar channel={currentChannel} authContext={authContext}
                                     mondayUsersContext={mondayUsersContext} className="ml-1"/>
                <div className="text-primary-text-color font-medium ml-4 mt-1"><ChannelNameSkeleton
                    channelName={currentChannel.name}/></div>
                <>
                    <button
                        className="text-secondary-text-color font-light ml-2 mt-1 text-xs cursor-pointer hover:text-primary-text-color hover:font-semibold"
                        onClick={() => setShowMembersModal(true)}
                    >{memberText}</button>
                </>
            </div>
            <div className="flex flex-row items-center">
                <Onboarding step={3} skipStep={currentChannel.type === ChannelTypeEnum.Person}>
                    {currentChannel.type !== ChannelTypeEnum.Person &&
                        <Button className="mx-px" size={Button.sizes.SMALL} kind={Button.kinds.SECONDARY} onClick={() => setShowAddMembersModal(true)}>
                            <Icon icon={AddSmall} iconSize={20}/> Add Members
                        </Button>
                    }
                </Onboarding>
                <NotificationPreferencesTipseen currentUser={currentUser}>
                    <Onboarding step={2}>
                        <Dialog
                            position={Dialog.positions.BOTTOM}
                            hideTrigger={[Dialog.hideShowTriggers.ESCAPE_KEY, Dialog.hideShowTriggers.CLICK_OUTSIDE, Dialog.hideShowTriggers.CONTENT_CLICK]}
                            showTrigger={[Dialog.hideShowTriggers.CLICK]}
                            content={() => (
                            <DialogContentContainer>
                                <div>
                                    <div
                                        className="text-primary-text-color text-l p-2">Channel
                                        Notification Preferences
                                    </div>
                                    <hr className="text-layout-border-color my-1"/>
                                    <div
                                        onClick={() => {
                                            updateChannelNotificationPreference(NotificationPreferenceEnum.All);
                                        }}
                                        data-testid={'notification-preference-all-messages'}
                                        className={`flex flex-row text-secondary-text-color text-sm p-2 hover:cursor-pointer rounded hover:bg-primary-background-hover-color ${currentNotificationPreference === NotificationPreferenceEnum.All ? 'bg-primary-selected-color active' : ''}`}
                                    >
                                        <span className="text-primary-text-color pr-1">All messages</span> - Get a
                                        notification for every
                                        missed message.
                                    </div>
                                    <div
                                        onClick={() => {
                                            updateChannelNotificationPreference(NotificationPreferenceEnum.Single);
                                        }}
                                        data-testid={'notification-preference-single'}
                                        className={`flex flex-row text-secondary-text-color text-sm p-2 hover:cursor-pointer rounded hover:bg-primary-background-hover-color ${currentNotificationPreference === NotificationPreferenceEnum.Single ? 'bg-primary-selected-color active' : ''}`}>
                                        <span className="text-primary-text-color pr-1">Single notification</span> - Get only
                                        one notification
                                        for one or more missed messages.
                                    </div>
                                    <div
                                        onClick={() => {
                                            updateChannelNotificationPreference(NotificationPreferenceEnum.Mentions);
                                        }}
                                        data-testid={'notification-preference-mentions'}
                                        className={`flex flex-row text-secondary-text-color text-sm p-2 hover:cursor-pointer rounded hover:bg-primary-background-hover-color ${currentNotificationPreference === NotificationPreferenceEnum.Mentions ? 'bg-primary-selected-color active' : ''}`}>
                                        <span className="text-primary-text-color pr-1">Only @ mentions</span> - Get a
                                        notification only when
                                        people mention you.
                                    </div>
                                    <div
                                        onClick={() => {
                                            updateChannelNotificationPreference(NotificationPreferenceEnum.None);
                                        }}
                                        data-testid={'notification-preference-none'}
                                        className={`flex flex-row text-secondary-text-color text-sm p-2 hover:cursor-pointer rounded hover:bg-primary-background-hover-color ${currentNotificationPreference === NotificationPreferenceEnum.None ? 'bg-primary-selected-color active' : ''}`}>
                                        <span className="text-primary-text-color pr-1">None</span> - No channel
                                        notifications
                                    </div>
                                    <hr className="text-layout-border-color my-1"/>
                                    <div
                                        onClick={() => {
                                            setShowDefaultNotificationPreferenceModal(true);
                                        }}
                                        data-testid={'notification-preference-default'}
                                        className={`rounded p-2 text-sm flex flex-row text-secondary-text-color hover:cursor-pointer hover:bg-primary-background-hover-color`}>
                                        <span className="flex flex-row"><Icon icon={Settings}
                                                                              className="mr-0.5 self-center"/> Change default for new channels</span>
                                    </div>
                                </div>
                            </DialogContentContainer>
                            )}>

                            <IconButton data-testid={'notification-button'} className="mx-px ml-1" size={Button.sizes.SMALL} kind={Button.kinds.SECONDARY}
                                        icon={currentNotificationPreference === NotificationPreferenceEnum.None ? NotificationsMuted : Notifications}/>
                        </Dialog>
                    </Onboarding>
                </NotificationPreferencesTipseen>
            </div>
            {/*Add Members Modal*/}
            <Modal
                data-testid="add-member-button"
                contentSpacing={false}
                id="story-book-modal"
                show={showAddMembersModal}
                onClose={closeAddMembersModal}
                title="Add Channel Members"
            >
                <ModalContent>
                    <div className="mt-3">
                        <div className="flex flex-row mb-3">
                            <Dropdown
                                className="w-full"
                                placeholder="Search for user(s) to add"
                                options={userSearchOptions}
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                menuPosition={Dropdown.menuPositions.FIXED as string}
                                multi={true}
                                multiline={true}
                                value={searchValue}
                                onChange={(selectedOptions) => setSearchValue(selectedOptions as SetStateAction<{ label: string; leftAvatar: string; value: string; }[]>)}
                            />
                            <div className="ml-2">
                                <Button kind={Button.kinds.PRIMARY} onClick={addMembers}>Add</Button>
                            </div>
                        </div>
                        <div className="text-secondary-text-color font-medium">Channel Members</div>
                        <div className="overflow-auto max-h-52">
                            {members.map((member) => (
                                <div key={member.id} className="flex flex-row items-center mt-2">
                                    <MondayUserAvatar userPhoto={member.photo_small} userName={member.name} size={Avatar.sizes.SMALL} />
                                    <div className="text-primary-text-color font-medium ml-3">{member.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </ModalContent>
            </Modal>

            {/*Show Members Modal*/}
            <Modal
                data-testid="add-member-button"
                contentSpacing={false}
                id="show-members-modal"
                show={showMembersModal}
                onClose={() => setShowMembersModal(false)}
                title={currentChannel.name || ' '}
            >
                <ModalContent>
                    <div className="mt-3">
                        <div className="text-secondary-text-color font-medium">Channel Members</div>
                        <div className="overflow-auto max-h-52">
                            {members.map((member) => (
                                <div key={member.id} className="flex flex-row items-center mt-2">
                                    <MondayUserAvatar userPhoto={member.photo_small} userName={member.name} size={Avatar.sizes.SMALL}/>
                                    <div className="text-primary-text-color font-medium ml-3">{member.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </ModalContent>
            </Modal>

            {/*Default Notification Preference Modal */}
            <Modal
                data-testid="default-notifications-modal"
                contentSpacing={false}
                id="show-default-notifications-modal"
                show={showDefaultNotificationPreferenceModal}
                onClose={() => setShowDefaultNotificationPreferenceModal(false)}
            >
                <ModalHeader title={''} description={"Your default notification preference for newly joined channels"}>
                    <h2 className="text-primary-text-color font-medium text-xl">Default Notification Preference</h2>
                </ModalHeader>
                <ModalContent>
                    <div className="mt-3">
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */}
                        <Dropdown size={Dropdown.sizes.LARGE} menuPosition={Dropdown.menuPositions.FIXED}
                            // className="max-w-64"
                            options={showMinimizedNotificationPreferences ? notificationDefaultOptionsMinimized : notificationDefaultOptions}
                            value={getNotificationOption(defaultNotificationPreference)}
                            onChange={(selectedOption: { label: string, value: NotificationPreferenceEnum }) => {
                                setDefaultNotificationPreference(selectedOption?.value);
                            }}
                            onMenuOpen={() => setShowMinimizedNotificationPreferences(false)}
                            onMenuClose={() => setShowMinimizedNotificationPreferences(true)}
                        />
                    </div>
                </ModalContent>
                <ModalFooterButtons primaryButtonText="Save" secondaryButtonText={'Cancel'} onPrimaryButtonClick={() => saveDefaultNotificationPreference()} onSecondaryButtonClick={() => setShowDefaultNotificationPreferenceModal(false)}/>
            </Modal>

        </div>
    );
}