import {Avatar} from "monday-ui-react-core";
import {ChannelType, ChannelTypeEnum} from "../types/FirestoreCollections.type.ts";
import {AuthContextProps} from "../context/AuthContext.ts";
import {MondayApiUser} from "../monday/MondayUsers.ts";
import {getAvatarBackgroundColor, getChannelAvatarInfo} from "../monday/MondayAvatar.ts";
import AvatarSkeleton from "./skeletons/AvatarSkeleton.tsx";

export default function MondayChannelAvatar({ channel, authContext, mondayUsersContext, showPersonChannelActiveStatus = false, isPersonChannelActive = null, className} : {channel: ChannelType, authContext: AuthContextProps, mondayUsersContext: Record<string, MondayApiUser>, showPersonChannelActiveStatus: boolean, isPersonChannelActive: boolean | null, className: string}) {
    const avatarInfo = getChannelAvatarInfo(authContext, mondayUsersContext, channel);
    const avatarColor = getAvatarBackgroundColor(channel.name ?? channel.id);

    // If the user's haven't loaded yet, set their avatar color to explosive (grey)
    if (channel.type === ChannelTypeEnum.Person && channel.name === null) {
        return <AvatarSkeleton height={28} width={28} />
    }

    const personChannelStatusColor = isPersonChannelActive ? 'bg-color-bright-green' : 'bg-color-blackish';

    return (
        <div className="relative">
            {showPersonChannelActiveStatus && channel.type === ChannelTypeEnum.Person &&
                <div className={`${personChannelStatusColor} w-2.5 h-2.5 rounded absolute top-0 -right-0.5 z-10 border border-text-color-on-inverted`}></div>
            }
                <Avatar
                    backgroundColor={avatarColor}
                    ariaLabel={channel.name}
                    size={Avatar.sizes.MEDIUM}
                    square={avatarInfo.channelType === ChannelTypeEnum.Board}
                    className={className}
                    withoutBorder={true}
                    src={avatarInfo.pictureUrl}
                    icon={avatarInfo.icon}
                    text={avatarInfo.initials}
                    type={avatarInfo.type}
                />
        </div>
    );
}

MondayChannelAvatar.defaultProps = {
    showPersonChannelActiveStatus: false,
    isPersonChannelActive: null,
    className: ''
}