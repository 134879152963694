import moment from "moment";

export default function MessageDateDivider({date}: {date: Date}) {
    return (
        <div className="mt-8">
            <div className="w-full border-b border-secondary-text-color -mb-4"></div>
            <div className="flex flex-row justify-center mb-1">
                <div
                    className="bg-secondary-background-color text-primary-text-color p-1.5 text-xs rounded-full border">
                    {moment(date).format("dddd, MMMM Do")}
                </div>
            </div>
        </div>
    );
}