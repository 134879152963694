import {TextField, IconButton, Modal, ModalContent, ModalFooterButtons} from "monday-ui-react-core";
import React, {useCallback, useContext, useState} from "react";
import {Add} from "monday-ui-react-core/icons";
import {useFirestore} from "reactfire";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {AuthContext} from "../context/AuthContext.ts";
import Onboarding from "./Onboarding.tsx";
export default function ChannelCreationButton() {
    const firestore = useFirestore();
    const [showChannelCreation, setShowChannelCreation] = useState(false);
    const [newChannelName, setNewChannelName] = useState('');

    const closeModal = useCallback(() => {
        setShowChannelCreation(false);
    }, [setShowChannelCreation]);

    const authContext = useContext(AuthContext);

    const createChannel = (e:  React.SyntheticEvent | null) => {
        if (e) e.preventDefault();

        if (!newChannelName) return;

        firestoreRepo(firestore, authContext).createChannel(newChannelName).then((res) => {
            // Set the current channel to the newly created one
            firestoreRepo(firestore, authContext).setCurrentlyViewingChannel(res.id).catch((e) => console.log(e));
        }).catch((e) => console.log(e));
        setShowChannelCreation(false);
        setNewChannelName('');
    }

    return (
        <>
            <div className="mr-1" data-testid="channel-create-button">
                <Onboarding step={1}>
                    <IconButton icon={Add} size={IconButton.sizes.SMALL} kind={IconButton.kinds.PRIMARY} onClick={() => setShowChannelCreation(true)} ariaLabel="Create channel" />
                </Onboarding>
            </div>
            <Modal
                data-testid="channel-create-modal"
                contentSpacing={false}
                id="channel-creation-modal"
                show={showChannelCreation}
                onClose={closeModal}
                title="Create Channel"
            >
                <ModalContent>
                    <form onSubmit={createChannel}>
                        <div className="mt-5">
                            <TextField
                                autoFocus
                                placeholder="New Channel Name"
                                title="Channel Name"
                                value={newChannelName}
                                onChange={(e) => setNewChannelName(e)}
                                wrapperClassName="monday-storybook-text-field_size"
                                data-testid="channel-create-name-input"
                            />
                        </div>
                    </form>
                </ModalContent>
                <ModalFooterButtons
                    onPrimaryButtonClick={() => createChannel(null)}
                    onSecondaryButtonClick={closeModal}
                    primaryButtonText="Create"
                    secondaryButtonText="Cancel"
                    data-testid={"channel-create-submit-button"}
                />
            </Modal>
        </>
    )
}