import ReactDOM from 'react-dom/client'
import "monday-ui-react-core/tokens";
import AppWrappper from "./AppWrapper.tsx";
import {firebaseConfig} from "./firebase/firebaseConfig.ts";
import {FirebaseAppProvider} from 'reactfire';
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://6b7508303758b743b0f44a95cc705ce3@o4507505473421312.ingest.us.sentry.io/4507505477091328",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/chat\.bonfireapps\.com/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
	// <React.StrictMode> Comment out for now, since it causes a double-render, creating two users in firestore on load
	<div id="app">
		<FirebaseAppProvider firebaseConfig={firebaseConfig}>
			<AppWrappper/>
		</FirebaseAppProvider>
	</div>
	// </React.StrictMode>,
)
