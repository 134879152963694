export function generateUserId(accountId, mondayUserId) {
    return `${accountId}&&&&&${mondayUserId}`;
}

export function generateCachedMondayBoardKey(accountId, boardId) {
    return `${accountId}&&&&&${boardId}`;
}

export function parseFirestoreUserId(firestoreUserId : string) {
    const [accountId, mondayUserId] = firestoreUserId.split('&&&&&');
    return {
        accountId,
        mondayUserId
    };
}