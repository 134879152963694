import {Dropdown} from "monday-ui-react-core";
import {useState} from "react";
import ChannelSearch from "./ChannelSearch.tsx";

export default function ChannelSearchWrapper() {
    const [showRealChannelSearch, setShowRealChannelSearch] = useState(false);

    // By default, it shows a fake dropdown with no options. On hover of this component, it sets a state to show the real component.
    // Then in the real component, it queries all the channels.
    // This way, we only query the channels when the user actually wants to see them to save on database queries.
    return (
        <div className="mr-3 grow max-h-20" onMouseEnter={() => setShowRealChannelSearch(true)}>
            {showRealChannelSearch && (
                <ChannelSearch/>
            )}

            {!showRealChannelSearch && (
                <Dropdown
                    size={Dropdown.sizes.SMALL}
                    placeholder="Find channels & users to add"
                    options={[]}
                />
            )}
        </div>
    )
}