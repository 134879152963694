import {useFirestore} from "reactfire";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {AccountType, ChannelMessageType, ChannelType} from "../types/FirestoreCollections.type.ts";
import moment from "moment";
import {Avatar} from "monday-ui-react-core";

export default function AdminMain({disableAdminMode} : {disableAdminMode: () => void}){
	const firestore = useFirestore();
	const authContext = useContext(AuthContext);
	const [adminAccounts, setAdminAccounts] = useState<AccountType[]>([]);
	const [channels, setChannels] = useState<ChannelType[]>([]);
	const [messages, setMessages] = useState<ChannelMessageType[]>([]);

	useEffect(() => {
		(async () => {
			const adminAccounts = await firestoreRepo(firestore, authContext).adminGetAccounts();
			setAdminAccounts(adminAccounts);
		})().catch((e) => console.log(e));

	}, []);

	const loadChannels = (accountId?: string | null ) => {
		if (!accountId) return;
		firestoreRepo(firestore, authContext).adminGetChannels(accountId).then((channels) => {
			// Order the channels by the number of lastMessageIds
			channels.sort((a, b) => {
				if (a.lastMessageIds.length < b.lastMessageIds.length) {
					return 1;
				}

				if (a.lastMessageIds.length > b.lastMessageIds.length) {
					return -1;
				}

				return 0;
			});

			setChannels(channels);
		}).catch((e) => console.log(e));
	}

	const loadMessages = ( channelId: string) => {
		firestoreRepo(firestore, authContext).adminGetChannelMessages(channelId).then((messages) => {
			setMessages(messages);
		}).catch((e) => console.log(e));
	}

	const getChannelColor = (channelType: string) => {
		switch(channelType) {
			case 'board':
				return 'bg-color-dark-red';
			case 'team':
				return 'bg-color-dark-blue';
			case 'person':
				return 'bg-color-grass_green';
			default:
				return 'bg-color-dark-red';
		}

	}

	function getChatMessageBackgroundColor(name: string) : string {
		// List of colors to choose from.
		// These colors are from the Monday UI color palette.
		const colors = [
			"#1776d3",   // Dodger Blue
			"#4d1605",   // Orange Red
			"#1b771b",   // Lime Green
			"#800080",   // Purple
			"#851553",   // Deep Pink
			"#2F4F4F",   // Dark Slate Gray
			"#9a1c1c",   // Dark Red
			"#00008B",   // Dark Blue
			"#9d641b",   // Dark Orange
			"#20B2AA",   // Light Sea Green
			"#9932CC",   // Dark Orchid
			"#FF6347",   // Tomato
			"#4682B4",   // Steel Blue
			"#A52A2A",   // Brown
			"#5F9EA0"    // Cadet Blue
		]

		// Generate a number between 0 and 14 based on the user's id.
		// This number will be used to select a color from the list of colors.
		const colorIndex = name.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) % 14;

		console.log(Object.values(Avatar.colors)[colorIndex]);
		return colors[colorIndex];
	}

	return (
		<div className="flex flex-col">
			<div className="absolute top-0 right-0 bg-color-dark-red border-2 border-color-stuck-red w-48 rounded ml-auto text-center text-text-color-on-primary m-2 cursor-pointer" onClick={() => disableAdminMode()}>Disable admin mode</div>
			<div className="flex flex-row text-text-color-on-primary">
				<div className="flex flex-col h-screen overflow-scroll">
					{adminAccounts.map((account) => {
						return (
							<div
								key={account.id}
								className={`bg-color-bright-blue rounded m-2 max-w-36 cursor-pointer`}
								onClick={() => loadChannels(account.accountId)}
							>
								<div className="font-bold bg-color-dark-blue px-2 py-1 text-center rounded-t">{account.name}</div>
								<div className="p-2">
									<div className="text-sm">{account.createdAt.toDate().toLocaleDateString()}</div>
									<div className="text-sm">{moment(account.createdAt.toDate()).format('h:mm A')}</div>
									<div className="text-xs">{account.slug}</div>
									<div className="text-xs">{account.accountId}</div>
								</div>
								<div
									className={`text-sm font-bold bg-color-dark-blue px-2 text-center rounded-b ${account.lastAppEventType === 'uninstall' ? 'bg-color-stuck-red' : ''}`}>{account.lastAppEventType}</div>
							</div>
						)
					})}
				</div>
				<div className="flex flex-col h-screen overflow-scroll">
					{channels.map((channel) => {
						return (
							<div
								key={channel.id}
								className={`rounded m-2 max-w-36 ${getChannelColor(channel.type)} cursor-pointer`}
								onClick={() => loadMessages(channel.id)}
							>
								<div className="p-2">
									<div className="font-bold">{channel.name}</div>
									<div className="text-xs">{channel.createdAt.toDate().toLocaleDateString()}</div>
									<div>Messages: <strong>{channel.lastMessageIds.length}</strong></div>
								</div>
								<div
									className="text-sm font-bold bg-color-blackish px-2 text-center rounded-b">{channel.type}</div>
							</div>
						)
					})}
				</div>
				<div className="flex-grow h-screen overflow-scroll">
					{messages.map((message) => {
						return (
							<div key={message.id} style={{backgroundColor: getChatMessageBackgroundColor(message.createdBy.toString())}} className='flex flex-row m-2 rounded'>
								<div className="m-2 pt-4">{moment(message.createdAt.toDate()).format('M/D h:MM:ss A')}</div>
								<div className="m-2 fullWidth">
									<div className="text-xs">{message.createdBy}</div>
									<div>{message.messageText}</div>
								</div>

							</div>
						)
					})}
				</div>

			</div>
		</div>
	)
}