import {Button, Checkbox, IconButton, Modal, ModalContent, ModalFooter, Tooltip} from "monday-ui-react-core";
import {Emoji, Gif, Mention as MentionIcon, Send} from "monday-ui-react-core/icons";
import React, {useContext, useEffect, useState} from "react";
import EmojiPicker from "./EmojiPicker.tsx";
import {EmojiClickData} from "emoji-picker-react";
import {autoUpdate, flip, useFloating} from "@floating-ui/react";
import {Mention, MentionsInput} from "react-mentions";
import {AuthContext} from "../context/AuthContext.ts";
import * as emojis from "../assets/emojis.json";
import {MondayUsersContext} from "../context/MondayUsersContext.ts";
import {BroadcastMentionEnum, ChannelType, ChannelTypeEnum, UserType} from "../types/FirestoreCollections.type.ts";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {useFirestore} from "reactfire";
import {useDebounce} from "use-debounce";
import GiphySearch, {IGif} from "./GiphySearch.tsx";
import Onboarding from "./Onboarding.tsx";
import {setUserIdCurrentlyTyping} from "../firebase/realtimeDatabaseRepo.ts";

export default function MessagesInputContainer({onCreateMessage, currentUser, currentChannel}: {onCreateMessage: (message: string) => void, currentUser: UserType, currentChannel: ChannelType }) {
    const firestore = useFirestore();
    const authContext = useContext(AuthContext);
    const mondayUsersContext = useContext(MondayUsersContext);
    const [message, setMessage] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [isCurrentlyTyping, setIsCurrentlyTyping] = useState(false);
    const [debouncedMessage] = useDebounce(message, 5000);
    const [showAddGifModal, setShowAddGifModal] = useState(false);

    const {refs, floatingStyles} = useFloating({
        placement: 'top-start',
        whileElementsMounted: autoUpdate,
        middleware: [flip()]
    });

    const updatePressEnterToSendMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
        firestoreRepo(firestore, authContext).updatePressEnterToSendMessage(e.target.checked).catch((e) => console.log(e));
    }
    const onChangeMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    }

    const onMessageCreateForComponent = (message: string) => {
        onCreateMessage(message);

        if (isCurrentlyTyping) {
            setIsCurrentlyTyping(false);
            // Update the database to indicate the user is not currently typing
            setUserIdCurrentlyTyping(authContext.accountId, currentChannel.id, authContext.firebaseUserId, false);
        }
    }

    // Handle the user typing indicator
    useEffect(() => {
        // If the user is currently typing
        if (message !== debouncedMessage && !isCurrentlyTyping) {
            setIsCurrentlyTyping(true);

            // Update the database to indicate the user is currently typing
            setUserIdCurrentlyTyping(authContext.accountId, currentChannel.id, authContext.firebaseUserId, true);
        }

        if (isCurrentlyTyping && message === debouncedMessage) {
            setIsCurrentlyTyping(false);

            // Update the database to indicate the user is not currently typing
            setUserIdCurrentlyTyping(authContext.accountId, currentChannel.id, authContext.firebaseUserId, false);
        }
    }, [message, debouncedMessage]);

    const messageInput: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);

    /**
     * This function is needed because the cursor position is not accurate when there are mentions in the text
     * Example text in message input, where "First item" is an @ mention: "hi First item b❤️lah"
     * Here, we inserted the heart
     * What the actual text looks like: "hi @[First item](first) blah"
     * Heart start cursor position, not including the @ mention text: 15
     * True cursor position, including the @ mention text: 25
     *
     * @param text
     * @param cursor
     */
    const getTrueCursorPosition = (text: string, cursor: number | null | undefined) => {
        if (!cursor) {
            return 0;
        }

        let charactersLeftToIterate = cursor;

        // debugger;
        let isInLabelPartOfMention = false;
        let isInValuePartOfMention = false;
        for (let characterIndex = 0; characterIndex < text.length; characterIndex++) {
            const char = text[characterIndex];

            if (charactersLeftToIterate === 0) {
                if (isInLabelPartOfMention || isInValuePartOfMention) {
                    return null;
                }
                return characterIndex;
            }

            if (char === '@' && text[characterIndex + 1] === '[') {
                continue;
            }

            if (char === '[' && text[characterIndex - 1] === '@') {
                isInLabelPartOfMention = true;
                continue;
            }

            if (char === ']' && text[characterIndex + 1] === '(') {
                isInLabelPartOfMention = false;
                continue;
            }

            // We count the label part of the mention as part of the cursor position
            if (isInLabelPartOfMention) {
                charactersLeftToIterate--;
                continue;
            }

            if (char === '(' && text[characterIndex - 1] === ']') {
                isInValuePartOfMention = true;
                continue;
            }

            if (isInValuePartOfMention && char === ')') {
                isInValuePartOfMention = false;
                continue;
            }

            // We don't count the value part of the mention as part of the cursor position
            if (isInValuePartOfMention) {
                continue;
            }

            charactersLeftToIterate--;
        }
    }

    const onEmojiClick = (emoji: EmojiClickData) => {
        // Replace the selected text with the emoji
        // This also ensures that the emoji is inserted at the cursor position
        let start = messageInput?.current?.selectionStart;
        let end = messageInput?.current?.selectionEnd;

        // Get the true cursor position, taking into account mentions
        start = getTrueCursorPosition(message, start);
        end = getTrueCursorPosition(message, end);

        const emojiText = emoji.emoji;
        if (!start || !end) {
            setMessage(message + emojiText);
        } else {
            setMessage(message.substring(0, start) + emojiText + message.substring(end));
        }

        setShowEmojiPicker(false);
        messageInput?.current?.focus();
    }

    const onGifClick = (gif: IGif, e: React.SyntheticEvent<HTMLElement, Event>) => {
        e.preventDefault();
        setShowAddGifModal(false);
        onMessageCreateForComponent(gif.embed_url);
    };

    const onMessageInputKeyDown = (e:  React.KeyboardEvent<HTMLTextAreaElement>) => {
        // If the user has pressEnterToSendMessage set to true, if the user presses the following, we want to add a new line:
        // ----- SHIFT + ENTER
        // ----- CMD + ENTER
        // ----- CTRL + ENTER
        if (currentUser.pressEnterToSendMessage && e.key === 'Enter' && (e.shiftKey || e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            setMessage(message + '\n');
        } else {
            if ((currentUser.pressEnterToSendMessage && e.key === 'Enter') || e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                if (message.length === 0) {
                    return;
                }
                onMessageCreateForComponent(message);
                setMessage('');
            }
        }
    }

    const createMessageSendIconClick = () => {
        if (message.length === 0) {
            return;
        }
        onMessageCreateForComponent(message);
        setMessage('');
    }

    const inputStyle = {
        control: {
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 400,
            fontFamily: 'Figtree,Roboto,Noto Sans Hebrew,Noto Kufi Arabic,Noto Sans JP,sans-serif',
            color: "var(--primary-text-color)",
        },

        '&multiLine': {
            control: {
                // fontFamily: 'monospace',
                // minHeight: 63,
            },
            highlighter: {
                padding: 10,
                border: '1px solid transparent',
            },
            input: {
                padding: 10,
                border: "1px solid var(--ui-border-color)", //'1px solid silver',
                borderRadius: 4,
                outline: 0
            },
        },

        suggestions: {
            list: {
                backgroundColor: 'var(--primary-background-color)',
                color: 'var(--primary-text-color)',
                border: '1px solid var(--primary-background-color)',
                fontSize: 14,
            },
            item: {
                padding: '5px 15px',
                borderBottom: '1px solid var(--ui-border-color)',
                '&focused': {
                    backgroundColor: 'var(--primary-selected-hover-color)'
                },
            },
        },
    };

    const queryEmojis = (query: string) => {
        if (query.length === 0) return

        const matches = emojis.emojis
            .filter((emoji) => {
                return emoji.name.indexOf(query.toLowerCase()) > -1
            })
            .slice(0, 10)
        return matches.map(({ emoji }) => ({ id: emoji }))
    }
    let users : {id: string, display: string}[] = [];
    // Don't show @ mention in person channels
    if (mondayUsersContext && currentChannel?.type !== ChannelTypeEnum.Person) {
        const allUserIds = Object.keys(mondayUsersContext) ?? [];
        const returnedUsers = allUserIds.map((memberId) => {
            return {
                id: memberId,
                display: mondayUsersContext[memberId]?.name
            }
        }) ?? [];

        users = returnedUsers.filter((user) => user.id !== authContext.firebaseUserId);

        // Also add @here and @channel mentions
        users.push({id: BroadcastMentionEnum.Channel, display: 'channel'});
    }

    return (
        <div className="flex flex-col w-full p-3 pt-1 pb-7">
            <div ref={refs.setReference}></div>
            <div className="flex flex-row w-full">
                <div className="grow">
                    <MentionsInput
                        // className="rounded-md border border-ui-border-color p-6"
                        style={inputStyle}
                        value={message}
                        inputRef={messageInput}
                        data-testid="message-input"
                        onChange={onChangeMessage}
                        placeholder="Your message here..."
                        allowSuggestionsAboveCursor={true}
                        onKeyDown={onMessageInputKeyDown}
                        autoFocus={true}
                    >
                        <Mention
                            trigger="@"
                            data={users}
                            style={{backgroundColor: 'var(--primary-selected-hover-color)', borderRadius: 4}}
                            // renderSuggestion={this.renderUserSuggestion}
                        />
                        <Mention
                            trigger=":"
                            markup="__id__"
                            regex={/($a)/}
                            data={queryEmojis}
                        />
                    </MentionsInput>
                </div>

                <IconButton data-testid={"message-send-button"} className="ml-1 self-center flex-none" size={Button.sizes.MEDIUM} kind={Button.kinds.PRIMARY} icon={Send} onClick={createMessageSendIconClick}/>
            </div>
            <div className="text-primary-text-color pt-1 flex flex-row">
                <IconButton data-testid="message-emoji-button" className="mx-px" size={Button.sizes.SMALL} kind={Button.kinds.SECONDARY}
                            icon={Emoji} onClick={() => setShowEmojiPicker(true)}/>

                <IconButton
                    data-testid="message-add-gif-button"
                    onClick={() => setShowAddGifModal(true)}
                    className="mx-px" size={Button.sizes.SMALL} kind={Button.kinds.SECONDARY}
                            icon={Gif}/>

                <Onboarding step={4} skipStep={currentChannel.type === ChannelTypeEnum.Person}>
                    {currentChannel.type !== ChannelTypeEnum.Person && (
                        <Tooltip content={"Mention a user or the entire channel"} position={Tooltip.positions.RIGHT}>
                            <IconButton data-testid="message-mention-button" className="mx-px" size={Button.sizes.SMALL} kind={Button.kinds.SECONDARY}
                                        icon={MentionIcon} onClick={() => {setMessage(message + '@'); messageInput?.current?.focus()}}/>
                        </Tooltip>
                    )}
                </Onboarding>

                <Checkbox data-testid="message-press-enter-to-send" className="ml-5 self-center" label="Press enter to send" checked={currentUser.pressEnterToSendMessage} onChange={updatePressEnterToSendMessage}/>
            </div>
            <div ref={refs.setFloating} style={floatingStyles} className="z-20">
                <EmojiPicker onEmojiClick={onEmojiClick} showEmojiPicker={showEmojiPicker}
                             onClose={() => setShowEmojiPicker(false)}/>
            </div>
            <Modal
                data-testid="add-member-button"
                contentSpacing={false}
                id="story-book-modal"
                show={showAddGifModal}
                onClose={() => setShowAddGifModal(false)}
                title=" "
            >
                <ModalContent>
                    <GiphySearch width={400} onGifClick={onGifClick}/>
                    <ModalFooter>
                        <div>
                            <img width="125" className="ml-auto mr-auto" alt="Giphy logo"
                                 src="/img/PoweredBy_200px-White_HorizText.png"/>
                        </div>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
}