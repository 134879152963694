import {ChannelType, ChannelTypeEnum} from "../types/FirestoreCollections.type.ts";
import {AuthContextProps} from "../context/AuthContext.ts";
import {MondayApiUser} from "./MondayUsers.ts";
import {Board} from "monday-ui-react-core/icons";
import {Avatar} from "monday-ui-react-core";

interface MondayAvatarInfoProps {
    type: typeof Avatar.types.TEXT | typeof Avatar.types.IMG | typeof Avatar.types.ICON;
    channelType?: ChannelTypeEnum;
    pictureUrl?: string;
    initials?: string;
    icon?: typeof Board;
}

export function getChannelAvatarInfo(authContext: AuthContextProps, mondayUsersContext: Record<string, MondayApiUser>, channel: ChannelType) : MondayAvatarInfoProps {
    const mondayAvatarInfo: MondayAvatarInfoProps = {
        type: Avatar.types.TEXT,
        channelType: channel.type
    };

    // Boards just have a board icon.
    if (channel.type === ChannelTypeEnum.Board) {
        mondayAvatarInfo.icon = Board;
        mondayAvatarInfo.type = Avatar.types.ICON;
        mondayAvatarInfo.channelType = ChannelTypeEnum.Board;
        return mondayAvatarInfo;
    }

    // Person channels have the other person's avatar.
    // If the other person doesn't have a picture, we just show their initials.
    if (channel.type === ChannelTypeEnum.Person) {
        const otherUser = channel.personChannelUserIds.find((user) => user !== authContext.firebaseUserId);
        if (otherUser) {
            if (mondayUsersContext[otherUser]) {
                const photo = mondayUsersContext[otherUser].photo_small;
                if (photo) {
                    mondayAvatarInfo.pictureUrl = photo;
                    mondayAvatarInfo.type = Avatar.types.IMG;
                    return mondayAvatarInfo;
                }

                // Get the initials from the other user's name
                const name = mondayUsersContext[otherUser].name;
                mondayAvatarInfo.initials = name.split(' ').map((word) => word[0]).join('').toUpperCase();
                mondayAvatarInfo.type = Avatar.types.TEXT;
                return mondayAvatarInfo;
            } else {
                return mondayAvatarInfo;
            }
        }
    }

    if (channel.pictureUrl) {
        mondayAvatarInfo.pictureUrl = channel.pictureUrl;
        mondayAvatarInfo.type = Avatar.types.IMG;
        return mondayAvatarInfo;
    }

    if (channel.name) {
        mondayAvatarInfo.initials = channel.name[0].toUpperCase();
        mondayAvatarInfo.type = Avatar.types.TEXT;
        return mondayAvatarInfo;
    }

    return mondayAvatarInfo;
}

/**
 * Returns a color from the list of colors in the Avatar component.
 * The color is based on the user's id to make it consistent but randomly assigned.
 *
 * @param name
 */
export function getAvatarBackgroundColor(name: string) : string {
    // Generate a number between 0 and 40 based on the user's id.
    // This number will be used to select a color from the list of colors.
    const colorIndex = name.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) % 40;
    return Object.values(Avatar.colors)[colorIndex];
}