import {useFirestore} from "reactfire";
import {
    ChannelType, ChannelTypeEnum,
    NONE_LAST_MESSAGE_ID,
    UserType
} from "../types/FirestoreCollections.type.ts";
import Channel from "./Channel.tsx"
import {useContext, useEffect} from "react";
import firestoreRepo from "../firebase/firestoreRepo.ts";
import {AuthContext} from "../context/AuthContext.ts";
import PersonChannel from "./PersonChannel.tsx";
import Onboarding from "./Onboarding.tsx";

export default function Channels({channels, currentlyActiveChannel, currentUser, setCurrentlyViewingChannel}: {channels: ChannelType[], currentlyActiveChannel: string | null, currentUser: UserType, setCurrentlyViewingChannel: (channelId: string) => void}) {
    const authContext = useContext(AuthContext);
    const firestore = useFirestore();

    useEffect(() => {
        for (const channel of channels) {
            const lastSeenMessageId = currentUser?.lastSeenMessageIds?.[channel.id];
            if (!lastSeenMessageId) {
                let lastMessageId= NONE_LAST_MESSAGE_ID;
                if (channel?.lastMessageIds?.length > 0 && channel.type !== ChannelTypeEnum.Person) {
                    lastMessageId = channel?.lastMessageIds?.[0];
                }
                
                // Only update the last seen message id if it's different from the last message id
                if (lastSeenMessageId !== lastMessageId) {
                    firestoreRepo(firestore, authContext).setLastSeenMessageId(currentUser, channel.id, lastMessageId).catch((e) => console.log(e));
                }
            }
        }
    }, [currentUser, channels]);

    const personChannels = channels.filter((channel) => channel.type === ChannelTypeEnum.Person);
    const nonPersonChannels = channels.filter((channel) => channel.type !== ChannelTypeEnum.Person);

    return (
        <div className="min-h-40 flex flex-col w-full grow px-2 overflow-auto">
            <div
                className="p-1 text-secondary-text-color font-medium">
                Channels
            </div>
            <Onboarding step={0}>
                {/*Don't add anything here to prevent the channel search from being below this element */}
                <div></div>
            </Onboarding>
            {/*Channels*/}
            <div className="mx-1">
                {channels.length === 0 && (
                    <div className="text-secondary-text-color font-light">
                        <div>There are no channels.</div>
                        <div>Please add an existing channel or create a new one above.</div>
                    </div>
                )}

                {channels.length !== 0 && nonPersonChannels.length === 0 && (
                    <div className="text-secondary-text-color font-light">
                        None
                    </div>
                )}

                {/*Channel*/}
                {nonPersonChannels.map((channel) => {
                    const notificationPreference = currentUser.notificationPreferences?.[channel.id] ?? null;
                    return (
                        <Channel key={channel.id} channel={channel} currentUser={currentUser} isPersonChannelActive={null}
                                 notificationPreference={notificationPreference}
                                 currentlyActiveChannel={currentlyActiveChannel}
                                 setCurrentlyViewingChannel={setCurrentlyViewingChannel}/>
                    );
                })}
            </div>
            {/*Person Channels*/}
            {personChannels.length !== 0 && (
                <>
                    <div
                        className="p-1 text-secondary-text-color font-medium">
                        Direct Messages
                    </div>
                    <div className="mx-1">
                        {personChannels.map((channel) => {
                            const notificationPreference = currentUser.notificationPreferences?.[channel.id] ?? null;
                            return (
                                <PersonChannel key={channel.id} channel={channel} currentUser={currentUser}
                                         notificationPreference={notificationPreference}
                                         currentlyActiveChannel={currentlyActiveChannel}
                                         setCurrentlyViewingChannel={setCurrentlyViewingChannel}/>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
}