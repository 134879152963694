import {default as EmojiPickerReact, EmojiClickData, EmojiStyle, Theme} from "emoji-picker-react";

export default function EmojiPicker({onEmojiClick, onClose, showEmojiPicker, className} : {
    onEmojiClick: (emoji: EmojiClickData) => void,
    onClose: () => void,
    showEmojiPicker: boolean,
    className?: string
}) {
    return (
        <div>
            {showEmojiPicker && (
                // See the following post for an explanation of this code:
                // https://stackoverflow.com/a/71530515/1801800
                <div
                    tabIndex={100}
                    // onFocus={() => setIsEmojiPickerFocused(true)}
                    onBlur={(e) => {
                        const currentTarget = e.currentTarget;

                        // Give browser time to focus the next element
                        requestAnimationFrame(() => {
                            // Check if the new focused element is a child of the original container
                            if (!currentTarget.contains(document.activeElement)) {
                                onClose();
                            }
                        });
                    }}
                    className={`w-[0px] h-[360px] ${className}`}>
                    {<EmojiPickerReact theme={Theme.LIGHT} emojiStyle={EmojiStyle.NATIVE} className="emoji-picker-react" width="380px" height="350px" onEmojiClick={onEmojiClick} previewConfig={{showPreview: false, defaultEmoji: "1f44d"}} skinTonesDisabled={true}/>}
                </div>
            )}
        </div>
    )
}